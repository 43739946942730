/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from "react";
import { Handle, useStoreState, getConnectedEdges } from "react-flow-renderer";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./dataSourceNode.scss";
import IPopover from "../../popover";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const DataSourceNode = ({
  data,
  deleteNodePopupFunction,
  setSelectedElements,
  configureLocalFile,
}) => {
  const [state, setstate] = useState(null);
  const navigate = useNavigate();
  const selected = useStoreState((state) => state.selectedElements);
  const edges = useStoreState((state) => state.edges);

  window.localStorage.setItem("node_id", data.data.node_id);

  useEffect(() => {
    if (selected) {
      const connectedEdges = getConnectedEdges(selected, edges);
      setSelectedElements([...selected, ...connectedEdges]);
    }
  }, [selected]);

  const handleClose = () => {
    setstate(null);
  };
  return (
    <>
      <Handle type="target" position="left" id="b" className="Custom" />
      <Handle type="source" position="right" id="a" />
      <div>
        <div className="node_option_icon">
          <MoreVertIcon
            onClick={(e, x) => {
              setstate(e.currentTarget);
            }}
          />
        </div>
        <div className="node_image_container">
          <img
            src={data.data.image_url}
            alt={data.data.node_type}
            draggable={false}
            className="node_image"
          />
        </div>
        {data ? <div className="node_title">{data.data.node_type}</div> : null}
      </div>
      {
        <IPopover
          data={[
            {
              name: "Explore",
              function: (e) => {
                navigate(
                  `/app/workspace/${data.data.canvas_id}/node/${data.data.node_id}/explore`
                );
                window.localStorage.setItem(
                  "node_group_type",
                  data.data.node_group_type
                );
              },
            },
            {
              name: "Visualize",
              function: (e) =>
                navigate(
                  `/app/workspace/${data.data.canvas_id}/node/${data.data.node_id}/visualization`
                ),
            },
            {
              name: "Statstics",
              function: (e) =>
                navigate(
                  `/app/workspace/${data.data.canvas_id}/node/${data.data.node_id}/statistics`
                ),
            },
            { name: "Configure", function: configureLocalFile },
            { name: "Delete", function: deleteNodePopupFunction },
          ]}
          state={state}
          handleClose={handleClose}
          option_classname={"custom"}
          nodeType={data && data.data && data.data.node_type}
        />
      }
    </>
  );
};
export default memo(DataSourceNode);
