import { combineReducers } from "redux";
import {
  getAllCanvasReducer,
  createNewCanvasReducer,
  updateCanvasReducer,
  deleteCanvasReducer,
  getAllCanvasNodesReducer,
} from "./canvasReducer";
import {
  getAllNodeGroupTypesReducer,
  getAllNodeOfGroupReducer,
  getSpecificNodeReducer,
} from "../reducers/nodeGroupReducer";
import {
  getColumnDatabynodeIdReducer,
  getDatabynodeIdReducer,
  addNodeReducer,
  deleteNodeReducer,
  updateNodeReducer,
  fetchNodeDetailsReducer,
  analyzeColumnTypeReducer,
  getSamplingSettingForNodeReducer,
  getFileURLReducer,
  getCofigureDatasetReducer,
} from "../reducers/nodeReducer";

import {
  getColumnNameandTypeReducer,
  getVisualizationGroupNameReducer,
  getVisualizationGroupsReducer,
  createEmptyVisualizationGraphReducer,
  fetchConfigurationConstraintsReducer,
} from "./visualizationReducer";
//import {getSamplingSettingForNodeReducer} from "../reducers/configureSampleReducer"
import snackBarReducer from "../reducers/globalSnackbarReducer";
import loaderReducer from "../reducers/globalLoaderReducer";

const rootReducer = combineReducers({
  getAllCanvasReducer,
  createNewCanvasReducer,
  updateCanvasReducer,
  deleteCanvasReducer,
  getAllCanvasNodesReducer,
  getAllNodeOfGroupReducer,
  getAllNodeGroupTypesReducer,
  getSpecificNodeReducer,
  getColumnDatabynodeIdReducer,
  getDatabynodeIdReducer,
  snackBarReducer,
  addNodeReducer,
  deleteNodeReducer,
  updateNodeReducer,
  fetchNodeDetailsReducer,
  loaderReducer,
  analyzeColumnTypeReducer,
  getSamplingSettingForNodeReducer,
  getFileURLReducer,
  getCofigureDatasetReducer,
  getColumnNameandTypeReducer,
  getVisualizationGroupsReducer,
  getVisualizationGroupNameReducer,
  createEmptyVisualizationGraphReducer,
  fetchConfigurationConstraintsReducer,
});

export default rootReducer;
