import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Popover from "@mui/material/Popover";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { ArrowDropDown } from "@mui/icons-material";
import AnalyzeColumnData from "../../popups/analyzePopup";
import BasicMenuBtn from "../../menu";
import SortDialog from "../../popups/exploreSortPopup";
import "./table.scss";
import IProgressBar from "../../progressBar";
import { editColumnTypeAction } from "../../../../redux/actions/nodeAction";
import { useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";

let nodeS; //slelcted target
let nodeM; //dropped target
// let a = 0;
export default function CustomTable({
  columnsData,
  columnsList,
  count,
  onTableScroll,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectCol, setSelectCol] = useState({});
  // const cnt = useRef(0);
  const openPopUp = Boolean(anchorEl);
  const columnTypeOptions = [
    {
      name: "Text",
    },
    {
      name: "Decimal",
    },
    {
      name: "Integer",
    },
    {
      name: "Boolean",
    },
    {
      name: "Date",
    },
    {
      name: "Object",
    },
    {
      name: "Array",
    },
  ];
  const params = useParams();
  const dispatch = useDispatch();

  function drag(ev) {
    ev.dataTransfer.setData("text", ev.target.id);
    nodeS = document.getElementById(ev.target.id);
    if (nodeS && nodeS.childNodes[0]) {
      nodeS.childNodes[0].classList.add("hoverItem");
    }
    if (
      nodeS &&
      nodeS.parentNode &&
      nodeS.parentNode.nextElementSibling !== null
    ) {
      nodeS.parentNode.nextElementSibling.style.marginLeft = "8px";
    }
  }

  function dragOver(ev) {
    ev.preventDefault();
    let n = ev.target;
    const nodes = [];
    while (n) {
      nodes.unshift(n);
      n = n.parentNode;
    }
    const nodeI = nodes.filter(
      (item, idx) => String(item.id).includes("P") && item
    );
    nodeM = nodeI && nodeI[0];
    drop(ev);
    if (nodeS && nodeS.childNodes[0]) {
      nodeS.childNodes[0].classList.add("hoverItem");
    }

    if (
      nodeS &&
      nodeS.parentNode &&
      nodeS.parentNode.nextElementSibling !== null
    ) {
      nodeS.parentNode.nextElementSibling.style.marginLeft = "8px";
    }
  }

  const dragLeave = (ev) => {
    ev.preventDefault();
    if (nodeM && nodeM.style) {
      nodeM.style.transition = "0.3s ease-in-out";
    }
    if (
      nodeS &&
      nodeS.parentNode &&
      nodeS.parentNode.nextElementSibling !== null
    ) {
      nodeS.parentNode.nextElementSibling.style.marginLeft = "-6px";
    }
  };

  const dragEnd = (ev) => {
    ev.preventDefault();
    if (nodeS && nodeS.childNodes[0]) {
      nodeS.childNodes[0].classList.remove("hoverItem");
    }
    if (
      nodeS &&
      nodeS.parentNode &&
      nodeS.parentNode.nextElementSibling !== null
    ) {
      nodeS.parentNode.nextElementSibling.style.marginLeft = "-6px";
    }
  };

  function drop(ev) {
    ev.preventDefault();
    var rect = nodeM && nodeM.getBoundingClientRect();
    var rect1 = nodeS && nodeS.getBoundingClientRect();
    if (rect1.x < rect.x) {
      //move left
      const startIdx = nodeS.parentNode.id.slice(1);
      const endIdx = nodeM.id.slice(1);
      document
        .getElementById(ev.currentTarget.parentNode.id)
        .appendChild(document.getElementById(nodeS.id));
      for (let i = startIdx; i < endIdx; i++) {
        document
          .getElementById(`P${Number(i)}`)
          .appendChild(
            document.getElementById(`P${Number(i) + 1}`).childNodes[0]
          );
      }
    } else if (rect1.x > rect.x) {
      // move right
      const startIdx = nodeS.parentNode.id.slice(1);
      const endIdx = nodeM.id.slice(1);
      document
        .getElementById(ev.currentTarget.parentNode.id)
        .appendChild(document.getElementById(nodeS.id));

      for (let i = startIdx; i > endIdx; i--) {
        document
          .getElementById(`P${Number(i)}`)
          .appendChild(
            document.getElementById(`P${Number(i) - 1}`).childNodes[0]
          );
      }
    }
  }

  const changeEditColumnTypeOptions = (e, item) => {
    let nodeId = params.nodeId;
    let columnName = e.name;
    let editTypeObj = {
      storage_type: item.storage_type,
      inferred_type: e.name,
    };
    dispatch(editColumnTypeAction(nodeId, columnName, editTypeObj));
  };

  // useEffect(() => {
  //   let cls = columnsList && columnsList.columns;
  //   let clsData = columnsData && columnsData.data;
  //   if (cls && clsData) {
  //     for (let i = 0; i < cls.length - 1; i++) {
  //       let c = 0;
  //       for (let j = 0; j < clsData.length - 1; j++) {
  //         if (clsData[j][cls[i].name] !== null) {
  //           // console.log(clsData[j][cls[i].name] !== null);
  //           c++;
  //         }
  //         a = c;
  //       }

  //       cnt.current = a;
  //     }
  //   }
  // }, [columnsData, columnsList]);

  const getValues = (item) => {
    return (
      <>
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title={item.name}>
              <div className={"headItem"}>{item.name}</div>
            </Tooltip>
            <ArrowDropDown
              onClick={(e) => openMenu(e, item)}
              className="d-svg"
            />
          </div>
          <div className={"storageItem"}>{item.storage_type}</div>
          <div className={"inferredItem"}>
            {item.inferred_type}
            <BasicMenuBtn
              menuItemIcon={
                <ArrowDropDown
                  style={{
                    color: "#3C3996",
                    fontSize: "16px",
                  }}
                />
              }
              title={""}
              menuData={columnTypeOptions}
              av={"bottom"}
              ah={"left"}
              tv={"top"}
              th={"left"}
              onChangeMenuItem={(e) => changeEditColumnTypeOptions(e, item)}
              menuHeadStyle={"sub-menu-column-type"}
            />
          </div>
        </>
      </>
    );
  };

  const openMenu = (ev, item) => {
    setAnchorEl(ev.currentTarget);
    setSelectCol(item);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const normalise = (value, MIN, MAX) => ((value - MIN) * 100) / (MAX - MIN);

  return (
    <>
      <Popover
        id="mouse-over-popover"
        open={openPopUp}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={closeMenu}
        disableRestoreFocus
        PaperProps={{
          sx: {
            boxShadow: "0 2px 7px 0 rgba(36, 36, 36, 0.2)",
            borderRadius: "8px",
          },
        }}
      >
        <>
          <div className="menu-options">
            <div className="option-container">
              <div className="menu-icon">{<FilterAltOutlinedIcon />}</div>
              <div
                className="menu-title"
                onClick={(e) => {
                  console.log("e");
                }}
              >
                Filter
              </div>
            </div>
            <SortDialog
              optionContainerStyle={"option-container"}
              iconStyle={"menu-icon"}
              titleStyle={"menu-title"}
              columnsList={columnsList}
              columnsData={columnsData}
              closeMenu={closeMenu}
            />
            <AnalyzeColumnData
              colName={selectCol}
              optionContainerStyle={"option-container"}
              iconStyle={"menu-icon"}
              titleStyle={"menu-title"}
              closeMenu={closeMenu}
            />
          </div>
        </>
      </Popover>
      <div
        className="tables-container"
        id="explore-table"
        onScroll={(e) => onTableScroll(e)}
      >
        {columnsList &&
          columnsList.columns &&
          columnsList.columns.map((item, index) => {
            return (
              <div
                key={`P${index}`}
                id={`P${index}`}
                className="boxes-container"
              >
                <div
                  className="box"
                  draggable="true"
                  onDragStart={drag}
                  //touch for mobile
                  onDragOver={dragOver}
                  id={index}
                  onDragLeave={dragLeave}
                  onDragEnd={dragEnd}
                >
                  <table className="d-table">
                    <thead className="d-thead">
                      <tr className="d-h-tr">
                        <th>{getValues(item)} </th>
                        <th>
                          <IProgressBar
                            value={normalise(count, 0, count)}
                            height={9.63}
                            width={"98%"}
                            bgColor={"#F0F5F6"}
                            barBgColor={"#32D094"}
                            barBorderRadius={"0px 2px 2px 0px"}
                            borderRadius={"0px"}
                            className="d-h-progressBar"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody className="d-body">
                      {columnsData &&
                        columnsData.data &&
                        columnsData.data.map((data, idx) => {
                          return (
                            <React.Fragment key={idx}>
                              <tr className="d-tr">
                                <td className="d-td">{data[item.name]}</td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}
