import {
  getAllNpdeGroupTypes,
  getAllNodesOfGroup,
  getSpecificNodeType,
} from "../../api/nodeGroupApi";
import { openGlobalSnackbar } from "../actions/globalSnackbarAction";
import {
  GET_ALL_GROUPTYPE_NODES_ERROR,
  GET_ALL_GROUPTYPE_NODES_PENDING,
  GET_ALL_GROUPTYPE_NODES_SUCCESS,
  GET_ALL_NODEGROUPTYPE_ERROR,
  GET_ALL_NODEGROUPTYPE_PENDING,
  GET_ALL_NODEGROUPTYPE_SUCCESS,
  GET_SPECIFIC_NODE_ERROR,
  GET_SPECIFIC_NODE_PENDING,
  GET_SPECIFIC_NODE_SUCCESS,
} from "../type";

var Alert;

//get all nodeGroupTypes/libraries
export const getAllNodeGroupTypeAction = () => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_NODEGROUPTYPE_PENDING, loading: true });
    try {
      const Result = await getAllNpdeGroupTypes();
      return dispatch({
        type: GET_ALL_NODEGROUPTYPE_SUCCESS,
        payload: Result,
      });
    } catch (err) {
      if (!!err && !!err.response && !!err.response.data) {
        Alert = {
          type: "error",
          isOpen: true,
          message: err.response.data.message,
        };
        dispatch({
          type: GET_ALL_NODEGROUPTYPE_ERROR,
          payload: Alert,
        });
        return dispatch(openGlobalSnackbar(err.message, true, "error"));
      } else {
        Alert = { type: "error", isOpen: true, message: err.statusText };
        dispatch({ type: GET_ALL_NODEGROUPTYPE_ERROR, payload: Alert });
        return dispatch(openGlobalSnackbar(err.statusText, true, "error"));
      }
    }
  };
};

//GET specific node type of a node.
export const getSpecificNodeTypeAction = () => {
  return async (dispatch) => {
    dispatch({ type: GET_SPECIFIC_NODE_PENDING, loading: true });
    try {
      const Result = await getSpecificNodeType();
      return dispatch({
        type: GET_SPECIFIC_NODE_SUCCESS,
        payload: Result,
      });
    } catch (err) {
      if (!!err && !!err.response && !!err.response.data) {
        dispatch({
          type: GET_SPECIFIC_NODE_ERROR,
          payload: err.response.data,
        });
        return dispatch(openGlobalSnackbar(err.message, true, "error"));
      } else {
        dispatch({ type: GET_SPECIFIC_NODE_ERROR, payload: err });
        return dispatch(openGlobalSnackbar(err.statusText, true, "error"));
      }
    }
  };
};

//get all nodes of a nodeGroupType
export const getAllNodesOfGroupAction = (nodeName) => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_GROUPTYPE_NODES_PENDING, loading: true });
    try {
      const Result = await getAllNodesOfGroup(nodeName);
      return dispatch({
        type: GET_ALL_GROUPTYPE_NODES_SUCCESS,
        payload: Result,
      });
    } catch (err) {
      if (!!err && !!err.response && !!err.response.data) {
        dispatch({
          type: GET_ALL_GROUPTYPE_NODES_ERROR,
          payload: err.response.data,
        });
        return dispatch(openGlobalSnackbar(err.message, true, "error"));
      } else {
        dispatch({ type: GET_ALL_GROUPTYPE_NODES_ERROR, payload: err });
        return dispatch(openGlobalSnackbar(err.statusText, true, "error"));
      }
    }
  };
};
