import { Popover } from "@mui/material";
import React from "react";
import Close from "@mui/icons-material/Close";
import { Cached } from "@mui/icons-material";
import ICheckBox from "../../checkBox";
import { isArray } from "lodash";
import "./visualizationPopover.scss";
const VisualizationPopover = (props) => {
  const {
    openPopUp,
    anchorEl,
    handlePopoverClose,
    ConfigurationConstraints,
    handleChangeYConstrain,
    handleChangeXConstrain,
    handleChangeCConstrain,
    setAdjustBin,
    setGroupExtraValue,
    setMaxDisplayValue,
    setNoOfBins,
    groupExtraValue,
    NoOfBins,
    adjustBin,
    MaxDisplayValue,
  } = props;

  return (
    <Popover
      id="mouse-over-popover"
      open={openPopUp}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
      PaperProps={{
        sx: {
          boxSizing: "border-box",
          boxShadow: "0 2px 7px 0 rgba(36, 36, 36, 0.2)",
          width: "218px",
          marginTop: "10px",
          border: "1px solid #E6E6E6",
          borderRadius: "4px",
          padding: "16px",
        },
      }}
    >
      <div style={{ width: "218px", position: "relative" }}>
        <div className="visual-closeIcon-div" onClick={handlePopoverClose}>
          <Close className="visual-closeIcon" />
        </div>
        {anchorEl &&
        anchorEl.parentNode &&
        anchorEl.parentNode.id &&
        anchorEl.parentNode.id.includes("Y") ? (
          <>
            <div className="y-popover-container">
              {ConfigurationConstraints &&
                Object.values(ConfigurationConstraints)[0].y &&
                Object.entries(
                  Object.values(ConfigurationConstraints)[0].y
                    .configuration_parameters
                ).map((item, idx) => {
                  return (
                    <div key={idx} className="y-popover-container-item">
                      <div className="y-popover-select-title">{item[0]}</div>
                      <select
                        name={item[0]}
                        onChange={handleChangeYConstrain}
                        className="y-popover-select"
                        required
                      >
                        <option disabled selected value="">
                          Choose an option
                        </option>
                        {item[1].map((thing, idx) => {
                          return (
                            <option
                              key={idx}
                              id={idx}
                              className="y-popover-select-option"
                            >
                              {thing}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  );
                })}
            </div>
          </>
        ) : anchorEl &&
          anchorEl.parentNode &&
          anchorEl.parentNode.id &&
          anchorEl.parentNode.id.includes("X") ? (
          <>
            <div>
              {ConfigurationConstraints &&
                Object.values(ConfigurationConstraints)[0].x &&
                Object.entries(
                  Object.values(ConfigurationConstraints)[0].x
                    .configuration_parameters
                ).map(([key, value], index) => {
                  return (
                    <div className="x-popover-container" key={index}>
                      {/* {key} */}
                      {Object.entries(value).map(([k, v], idx) => {
                        return (
                          <div
                            className={
                              typeof v === "boolean"
                                ? "x-popover-title-container-bool"
                                : "x-popover-title-container"
                            }
                            key={idx}
                          >
                            <div className="x-popover-title">
                              {k.split("_").join(" ")}
                            </div>
                            <div className="x-popover-input-checkbox">
                              {typeof v === "number" ? (
                                <div className="x-popover-input-container">
                                  <input
                                    onChange={(e) =>
                                      setMaxDisplayValue(e.target.value)
                                    }
                                    type="number"
                                    className="x-popover-input"
                                    disabled
                                    value={MaxDisplayValue}
                                  />
                                  <div className="x-popover-input-icon-container">
                                    <Cached />
                                  </div>
                                </div>
                              ) : typeof v === "boolean" ? (
                                <ICheckBox
                                  onChangeCheckbox={(e) => {
                                    setGroupExtraValue(e.target.checked);
                                  }}
                                  checked={groupExtraValue}
                                  disabled={true}
                                />
                              ) : isArray(v) ? (
                                <>
                                  <select
                                    onChange={handleChangeXConstrain}
                                    className="x-popover-select"
                                    disabled
                                  >
                                    <option disabled selected value="">
                                      Choose an option
                                    </option>
                                    {v.map((item, keys) => {
                                      return <option key={keys}>{item}</option>;
                                    })}{" "}
                                  </select>
                                </>
                              ) : typeof v === "object" && !Array.isArray(v) ? (
                                <div>
                                  {Object.entries(v).map(([i, j], idx) => {
                                    return (
                                      <div key={idx} className="x-popover-item">
                                        <div className="x-popover-item-title">
                                          {i.split("_").join(" ")}
                                        </div>
                                        {typeof j === "object" &&
                                        !Array.isArray(j) ? (
                                          <>
                                            {Object.entries(j).map(
                                              ([m, n], o) => {
                                                return (
                                                  <div
                                                    className={
                                                      typeof n === "boolean"
                                                        ? "x-popover-sub-item-bool"
                                                        : "x-popover-sub-item"
                                                    }
                                                    key={o}
                                                  >
                                                    <div className="x-popover-sub-item-title">
                                                      {m.split("_").join(" ")}{" "}
                                                    </div>
                                                    {typeof n === "number" ? (
                                                      <div className="x-popover-sub-item-input">
                                                        <input
                                                          onChange={(e) =>
                                                            setNoOfBins(
                                                              e.target.value
                                                            )
                                                          }
                                                          value={NoOfBins}
                                                          type="number"
                                                          className="x-popover-input"
                                                        />
                                                        <div className="x-popover-input-icon-container">
                                                          <Cached />
                                                        </div>
                                                      </div>
                                                    ) : typeof n ===
                                                      "boolean" ? (
                                                      <ICheckBox
                                                        onChangeCheckbox={(e) =>
                                                          setAdjustBin(
                                                            e.target.checked
                                                          )
                                                        }
                                                        checked={adjustBin}
                                                      />
                                                    ) : null}
                                                  </div>
                                                );
                                              }
                                            )}
                                          </>
                                        ) : null}
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </div>
          </>
        ) : anchorEl &&
          anchorEl.parentNode &&
          anchorEl.parentNode.id &&
          anchorEl.parentNode.id.includes("C") ? (
          <>
            <div>
              {ConfigurationConstraints &&
                Object.values(ConfigurationConstraints)[0].colour &&
                Object.entries(
                  Object.values(ConfigurationConstraints)[0].colour
                    .configuration_parameters
                ).map(([key, value], index) => {
                  return (
                    <div className="x-popover-container" key={index}>
                      {/* {key} */}
                      {Object.entries(value).map(([k, v], idx) => {
                        return (
                          <div
                            className={
                              typeof v === "boolean"
                                ? "x-popover-title-container-bool"
                                : "x-popover-title-container"
                            }
                            key={idx}
                          >
                            <div className="x-popover-title">
                              {k.split("_").join(" ")}
                            </div>
                            <div className="x-popover-input-checkbox">
                              {typeof v === "number" ? (
                                <div className="x-popover-input-container">
                                  <input
                                    onChange={(e) =>
                                      setMaxDisplayValue(e.target.value)
                                    }
                                    type="number"
                                    className="x-popover-input"
                                    value={MaxDisplayValue}
                                  />
                                  <div className="x-popover-input-icon-container">
                                    <Cached />
                                  </div>
                                </div>
                              ) : typeof v === "boolean" ? (
                                <ICheckBox
                                  onChangeCheckbox={(e) => {
                                    setGroupExtraValue(e.target.checked);
                                  }}
                                  checked={groupExtraValue}
                                />
                              ) : isArray(v) ? (
                                <>
                                  <select
                                    onChange={handleChangeCConstrain}
                                    className="x-popover-select"
                                  >
                                    <option disabled selected value="">
                                      Choose an option
                                    </option>
                                    {v.map((item, keys) => {
                                      return <option key={keys}>{item}</option>;
                                    })}{" "}
                                  </select>
                                </>
                              ) : typeof v === "object" && !Array.isArray(v) ? (
                                <div>
                                  {Object.entries(v).map(([i, j], idx) => {
                                    return (
                                      <div key={idx} className="x-popover-item">
                                        <div className="x-popover-item-title">
                                          {i.split("_").join(" ")}
                                        </div>
                                        {typeof j === "object" &&
                                        !Array.isArray(j) ? (
                                          <>
                                            {Object.entries(j).map(
                                              ([m, n], o) => {
                                                return (
                                                  <div
                                                    className={
                                                      typeof n === "boolean"
                                                        ? "x-popover-sub-item-bool"
                                                        : "x-popover-sub-item"
                                                    }
                                                    key={o}
                                                  >
                                                    <div className="x-popover-sub-item-title">
                                                      {m.split("_").join(" ")}{" "}
                                                    </div>
                                                    {typeof n === "number" ? (
                                                      <div className="x-popover-sub-item-input">
                                                        <input
                                                          onChange={(e) =>
                                                            setNoOfBins(
                                                              e.target.value
                                                            )
                                                          }
                                                          value={NoOfBins}
                                                          type="number"
                                                          className="x-popover-input"
                                                          disabled
                                                        />
                                                        <div className="x-popover-input-icon-container">
                                                          <Cached />
                                                        </div>
                                                      </div>
                                                    ) : typeof n ===
                                                      "boolean" ? (
                                                      <ICheckBox
                                                        onChangeCheckbox={(e) =>
                                                          setAdjustBin(
                                                            e.target.checked
                                                          )
                                                        }
                                                        checked={adjustBin}
                                                        disabled={true}
                                                      />
                                                    ) : null}
                                                  </div>
                                                );
                                              }
                                            )}
                                          </>
                                        ) : null}
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </div>
          </>
        ) : null}
      </div>
    </Popover>
  );
};

export default VisualizationPopover;
