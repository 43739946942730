import {
  createEmptyVisualizationGraph,
  fetchConfigurationConstraints,
  getChartData,
  getColumnNameandType,
  getVisualizationGroupName,
  getVisualizationGroups,
  updateGraph,
} from "../../api/visualizationApi";
import {
  CREATE_EMPTY_VISUALIZATION_GRAPH_ERROR,
  CREATE_EMPTY_VISUALIZATION_GRAPH_PENDING,
  CREATE_EMPTY_VISUALIZATION_GRAPH_SUCCESS,
  FETCH_CONFIGURATION_CONSTRAINTS_ERROR,
  FETCH_CONFIGURATION_CONSTRAINTS_PENDING,
  FETCH_CONFIGURATION_CONSTRAINTS_SUCCESS,
  GET_CHART_DATA_ERROR,
  GET_CHART_DATA_PENDING,
  GET_CHART_DATA_SUCCESS,
  GET_COLUMN_NAME_AND_TYPE_ERROR,
  GET_COLUMN_NAME_AND_TYPE_PENDING,
  GET_COLUMN_NAME_AND_TYPE_SUCCESS,
  GET_VISUALIZATION_GROUPS_ERROR,
  GET_VISUALIZATION_GROUPS_PENDING,
  GET_VISUALIZATION_GROUPS_SUCCESS,
  GET_VISUALIZATION_GROUP_NAME_ERROR,
  GET_VISUALIZATION_GROUP_NAME_PENDING,
  GET_VISUALIZATION_GROUP_NAME_SUCCESS,
  UPDATE_CANVAS_ERROR,
  UPDATE_GRAPH_PENDING,
  UPDATE_GRAPH_SUCCESS,
} from "../type";
import { openGlobalSnackbar } from "./globalSnackbarAction";
import { openLoader, closeLoader } from "./globalLoaderAction";

export const getColumnNameandTypeAction = (node_id) => {
  return async (dispatch) => {
    dispatch({ type: GET_COLUMN_NAME_AND_TYPE_PENDING, loading: true });
    dispatch(openLoader({ isLoading: true }));
    try {
      const Result = await getColumnNameandType(node_id);
      dispatch({
        type: GET_COLUMN_NAME_AND_TYPE_SUCCESS,
        payload: Result,
      });
      return dispatch(closeLoader());
    } catch (err) {
      if (!!err && !!err.response && !!err.response.data) {
        dispatch(closeLoader());
        dispatch({
          type: GET_COLUMN_NAME_AND_TYPE_ERROR,
        });
        return dispatch(openGlobalSnackbar(err.message, true, "error"));
      } else {
        // Alert = { type: "error", isOpen: true, message: err.statusText };
        dispatch(closeLoader());
        dispatch({ type: GET_COLUMN_NAME_AND_TYPE_ERROR });
        return dispatch(openGlobalSnackbar(err.statusText, true, "error"));
      }
    }
  };
};

export const getVisualizationGroupsAction = () => {
  return async (dispatch) => {
    dispatch({ type: GET_VISUALIZATION_GROUPS_PENDING, loading: true });
    dispatch(openLoader({ isLoading: true }));
    try {
      const Result = await getVisualizationGroups();
      dispatch({
        type: GET_VISUALIZATION_GROUPS_SUCCESS,
        payload: Result,
      });
      return dispatch(closeLoader());
    } catch (err) {
      if (!!err && !!err.response && !!err.response.data) {
        dispatch(closeLoader());
        dispatch({
          type: GET_VISUALIZATION_GROUPS_ERROR,
        });
        return dispatch(openGlobalSnackbar(err.message, true, "error"));
      } else {
        dispatch(closeLoader());
        // Alert = { type: "error", isOpen: true, message: err.statusText };
        dispatch({ type: GET_VISUALIZATION_GROUPS_ERROR });
        return dispatch(openGlobalSnackbar(err.statusText, true, "error"));
      }
    }
  };
};

export const getVisualizationGroupNameAction = (visualization_group_name) => {
  return async (dispatch) => {
    dispatch({ type: GET_VISUALIZATION_GROUP_NAME_PENDING, loading: true });
    dispatch(openLoader({ isLoading: true }));
    try {
      const Result = await getVisualizationGroupName(visualization_group_name);
      dispatch({
        type: GET_VISUALIZATION_GROUP_NAME_SUCCESS,
        payload: Result,
      });
      return dispatch(closeLoader());
    } catch (err) {
      if (!!err && !!err.response && !!err.response.data) {
        dispatch({
          type: GET_VISUALIZATION_GROUP_NAME_ERROR,
        });
        dispatch(closeLoader());
        return dispatch(openGlobalSnackbar(err.message, true, "error"));
      } else {
        // Alert = { type: "error", isOpen: true, message: err.statusText };
        dispatch({ type: GET_VISUALIZATION_GROUP_NAME_ERROR });
        dispatch(closeLoader());
        return dispatch(openGlobalSnackbar(err.statusText, true, "error"));
      }
    }
  };
};

export const createEmptyVisualizationGraphAction = (node_id, data) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_EMPTY_VISUALIZATION_GRAPH_PENDING, loading: true });
    dispatch(openLoader({ isLoading: true }));
    try {
      const Result = await createEmptyVisualizationGraph(node_id, data);
      dispatch({
        type: CREATE_EMPTY_VISUALIZATION_GRAPH_SUCCESS,
        payload: Result,
      });
      return dispatch(closeLoader());
    } catch (err) {
      if (!!err && !!err.response && !!err.response.data) {
        dispatch({
          type: CREATE_EMPTY_VISUALIZATION_GRAPH_ERROR,
        });
        dispatch(closeLoader());
        return dispatch(openGlobalSnackbar(err.message, true, "error"));
      } else {
        // Alert = { type: "error", isOpen: true, message: err.statusText };
        dispatch({ type: CREATE_EMPTY_VISUALIZATION_GRAPH_ERROR });
        dispatch(closeLoader());
        return dispatch(openGlobalSnackbar(err.statusText, true, "error"));
      }
    }
  };
};

export const fetchConfigurationConstraintsAction = (visualization_type) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_CONFIGURATION_CONSTRAINTS_PENDING, loading: true });
    dispatch(openLoader({ isLoading: true }));
    try {
      const Result = await fetchConfigurationConstraints(visualization_type);
      dispatch({
        type: FETCH_CONFIGURATION_CONSTRAINTS_SUCCESS,
        payload: Result,
      });
      return dispatch(closeLoader());
    } catch (err) {
      if (!!err && !!err.response && !!err.response.data) {
        dispatch({
          type: FETCH_CONFIGURATION_CONSTRAINTS_ERROR,
        });
        dispatch(closeLoader());
        return dispatch(openGlobalSnackbar(err.message, true, "error"));
      } else {
        // Alert = { type: "error", isOpen: true, message: err.statusText };
        dispatch({ type: FETCH_CONFIGURATION_CONSTRAINTS_ERROR });
        dispatch(closeLoader());
        return dispatch(openGlobalSnackbar(err.statusText, true, "error"));
      }
    }
  };
};

export const updateGraphAction = (node_id, visualization_id, data) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_GRAPH_PENDING, loading: true });
    dispatch(openLoader({ isLoading: true }));
    try {
      const Result = await updateGraph(node_id, visualization_id, data);
      dispatch(closeLoader());
      return dispatch({
        type: UPDATE_GRAPH_SUCCESS,
        payload: Result,
      });
    } catch (err) {
      if (!!err && !!err.response && !!err.response.data) {
        dispatch(closeLoader());
        dispatch({
          type: UPDATE_CANVAS_ERROR,
        });
        return dispatch(openGlobalSnackbar(err.message, true, "error"));
      } else {
        // Alert = { type: "error", isOpen: true, message: err.statusText };
        dispatch({ type: UPDATE_CANVAS_ERROR });
        dispatch(closeLoader());
        return dispatch(openGlobalSnackbar(err.statusText, true, "error"));
      }
    }
  };
};

export const getChartDataAction = (node_id, visualization_id) => {
  return async (dispatch) => {
    dispatch({ type: GET_CHART_DATA_PENDING, loading: true });
    dispatch(openLoader({ isLoading: true }));
    try {
      const Result = await getChartData(node_id, visualization_id);
      dispatch({
        type: GET_CHART_DATA_SUCCESS,
        payload: Result,
      });
      return dispatch(closeLoader());
    } catch (err) {
      if (!!err && !!err.response && !!err.response.data) {
        dispatch({
          type: GET_CHART_DATA_ERROR,
        });
        dispatch(closeLoader());
        return dispatch(openGlobalSnackbar(err.message, true, "error"));
      } else {
        // Alert = { type: "error", isOpen: true, message: err.statusText };
        dispatch({ type: GET_CHART_DATA_ERROR });
        dispatch(closeLoader());
        return dispatch(openGlobalSnackbar(err.statusText, true, "error"));
      }
    }
  };
};
