import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { CardContent, Card, CardMedia, Typography, Box } from "@mui/material";
import loginLogo from "../../../assets/loginLogo.png";
import Button from "../../themeComponents/button";
import "./login.scss";
import IInput from "../../themeComponents/input";

const useStyles = makeStyles(() => ({
  inputBox: {
    boxSizing: "border-box",
    height: "71px",
    width: "471px",
    border: " 1px solid #E6E6E6",
    borderRadius: " 8px",
    backgroundColor: "#FFFFFF",
    fontSize: "20px",
    paddingLeft: "20px",
    paddingTop: "5px",
    marginBottom: "20px",
    paddingRight: "20px",
  },
  inputBoxError: {
    boxSizing: "border-box",
    height: "71px",
    width: "471px",
    border: " 2px solid #EE5353",
    borderRadius: " 8px",
    backgroundColor: "#FFFFFF",
    fontSize: "20px",
    paddingLeft: "20px",
    paddingTop: "5px",
    marginBottom: "20px",
    paddingRight: "20px",
  },
  emailLabel: {
    height: "16px",
    width: "auto",
    color: "#EE5353",
    fontFamily: "Product Sans",
    fontSize: "12px",
    fontWeight: "bold",
    letterSpacing: 0,
    lineHeight: "16px",
    float: "left",
    marginTop: "-18px",
    marginLeft: "16px",
  },
  dialogHead: {
    height: "32px",
    width: "500px",
    color: "#242424",
    fontFamily: "Sofia Pro",
    fontSize: "28px",
    fontWeight: "bold",
    letterSpacing: 0,
    lineHeight: "32px",
    //margin: "24px 40px 10px 30px",
    boxSizing: "border-box",
  },
  dialogSubHead: {
    height: "16px",
    width: "500px",
    color: " #8B97A2",
    fontFamily: "Product Sans",
    fontSize: "14px",
    letterSpacing: "0.25px",
    lineHeight: "16px",
    margin: "7px 0px 10px 0px",
  },
  input: {
    height: "22px",
    //width: "488.72px",
    color: "#1E2429",
    fontFamily: "Product Sans",
    fontSize: "20px",
    fontWeight: "bold !important",
    letterSpacing: 0,
    lineHeight: "22px",
  },
  root: {
    "& .MuiFormLabel-root": {
      height: "15px",
      //width: "488.72px",
      color: "#8B97A2",
      fontFamily: "Product Sans",
      letterSpacing: 0.5,
      lineHeight: "15px",
      //fontWeight: "bold !important",
      marginTop: "6px",
    },
  },
  createButton: {
    height: "50px",
    width: "158px",
    borderRadius: "8px",
    backgroundColor: "#3C3996",
    boxShadow: "0 2px 7px 0 rgba(36, 36, 36, 0.2)",
    outline: "noe",
    border: "none",
    marginRight: "18px",
    marginTop: "18px",
    marginBottom: "14px",
    "& div": {
      height: "16px",
      //width: "122px",
      color: " #FFFFFF",
      //fontFamily: "Product Sans",
      fontSize: "14px",
      fontWeight: "bold",
      letterSpacing: 0.5,
      lineHeight: "16px",
      textAlign: "center",
    },
  },
  cancelButton: {
    height: "50px",
    width: "82px",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    marginLeft: "30px",
    marginTop: "18px",
    marginBottom: "14px",
    outline: "noe",
    border: "none",
    "& div": {
      height: "16px",
      width: "46px",
      color: "#3C3996",
      fontFamily: "Product Sans",
      fontSize: "14px",
      fontWeight: "bold",
      letterSpacing: 0.5,
      lineHeight: "16px",
      textAlign: "center",
    },
  },
}));

export default function Login() {
  const classes = useStyles();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState({
    email: false,
    password: false,
  });

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleSubmit = () => {
    if (!validateEmail(user.email)) {
      setError({ ...error, email: true });
      return;
    }
    if (user.password.length < 5) {
      setError({ ...error, password: true });
      return;
    }
    if (user.email.trim().length !== 0 && user.password.trim().length !== 0) {
      window.location.href = "/app/dashboard";
    }
  };

  const onChangeInput = (e) => {
    setError({
      email: false,
      password: false,
    });
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  return (
    <Box component="main" className="login-container">
      <Card className="login-card">
        <CardMedia
          component="img"
          className="login-card-img"
          image={loginLogo}
          alt="logo"
        />
        <CardContent className="login-card-content" sx={{ padding: "0px" }}>
          <Typography component="div" className="login-title">
            Login
          </Typography>
          <Typography omponent="div" className="login-sub-title">
            Use your credentials to login below.
          </Typography>

          <IInput
            value={user.email}
            type="email"
            label={"Email"}
            name="email"
            onChangeInput={onChangeInput}
            styleProps={{
              border: error && error.email ? "2px solid #ee5353" : "",
            }}
          />
          {error && error.email ? (
            <label className={classes.emailLabel}>Email is not valid</label>
          ) : null}
          <IInput
            value={user.password}
            type="password"
            label={"Password"}
            name="password"
            onChangeInput={onChangeInput}
            styleProps={{
              border: error && error.password ? "2px solid #ee5353" : "",
            }}
          />
          {error && error.password ? (
            <label className={classes.emailLabel}>Password is not valid</label>
          ) : null}
          <Box component="div" className="login-buttons">
            <Typography component="div" className="forgot-password">
              Forgot Password?
            </Typography>
            <Button
              type={
                user.email === "" || user.password === ""
                  ? "disabled"
                  : "disabled-enable"
              }
              handleClick={handleSubmit}
            >
              Login
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
