import React, { useState } from "react";
import "./tableFilterSortPopup.scss";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ICheckBox from "../../../checkBox";

export default function TableFilterSortPopup({
  dropDownData,
  closeFilterSortPopup,
}) {
  const [move, setMove] = useState(0);

  return (
    <div className="table-popup-main">
      <div className="popup-heading-container">
        <div className="popup-heading">Filter &amp; Sort</div>
        <CloseIcon className="close-icon" onClick={closeFilterSortPopup} />
      </div>
      <div className="drop-down">
        <div className="drop-down-title">Filter Pipeline</div>
        <div className="drop-down-input-holder">
          <input
            type="text"
            placeholder="Type to search.."
            className="drop-down-input"
          />
        </div>
        <KeyboardArrowDownIcon className="drop-down-down-icon" />
      </div>
      <div className="drop-down-results-container">
        {dropDownData.map((item) => {
          return (
            <div key={item.id + item.title} className="drop-down-result">
              <div className="result-title">{item.title}</div>
              <ICheckBox
                onChangeCheckbox={(e) => {
                  console.log(e);
                }}
              />
            </div>
          );
        })}
      </div>
      <div className="table-popup-bottom">
        <div className="sort-from-title">Sort from</div>
        <div className="sort-order" onClick={() => setMove(!move)}>
          <div
            className={move ? "sort-order-label-default" : "sort-order-label"}
          >
            A to Z
          </div>
          <div
            className={move ? "sort-order-label" : "sort-order-label-default"}
          >
            Z to A
          </div>
          <div className={move ? "sort-switch-move" : "sort-switch"}></div>
        </div>
      </div>
    </div>
  );
}
