import React, { useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Popover,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ICheckBox from "../../checkBox";
import IButton from "../../button";
import Loader from "../../loader";
import IInput from "../../input";
import "./configureSample.scss";
import {
  updateSamplingForNodeAction,
  getSamplingSettingForNodeAction,
} from "../../../../redux/actions/nodeAction";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";

function ConfigureSample() {
  const [open, setOpen] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputDispalyName, setInputDisplayName] = useState("");
  const [nextDisaplyInput, setNextDisplayInput] = useState({});
  const [inputKey, setInputKey] = useState("");
  const [nextDispalyInputValue, setNextDispalyInputValue] = useState("");

  const dispatch = useDispatch();
  const params = useParams();

  const samplingData = useSelector(
    (state) => state.getSamplingSettingForNodeReducer.configureSampleNodeId
  );

  let samplingDataLoading = useSelector(
    (state) => state.getSamplingSettingForNodeReducer.loading
  );

  useEffect(() => {
    if (open) {
      dispatch(getSamplingSettingForNodeAction());
    }
  }, [dispatch, open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPopUp(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenPopUp(false);
  };

  const onOptionClick = (item) => {
    setInputDisplayName(item.display_name);
    setInputKey(item.key);
    setNextDisplayInput(
      Object.entries(item.required_fields).map(([key, value]) => {
        return {
          label: key,
          type: value,
        };
      })
    );
    handlePopoverClose();
  };

  const onSubmitConfigure = () => {
    let noRecords = nextDispalyInputValue;
    let sampleSetting = inputKey;
    let nodeId = params.nodeId;
    if (
      noRecords &&
      noRecords.trim().length !== 0 &&
      sampleSetting &&
      sampleSetting.trim().length !== 0
    ) {
      let configureObj = {
        sampling_setting: {
          sampling_config: {
            "NO. records": noRecords,
          },
          sampling_setting: sampleSetting,
        },
      };
      dispatch(updateSamplingForNodeAction(nodeId, configureObj));
      handleClose();
    }
  };

  return (
    <>
      <Popover
        id="mouse-over-popover"
        open={openPopUp}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        PaperProps={{
          sx: {
            boxShadow: "0 2px 7px 0 rgba(36, 36, 36, 0.2)",
            borderRadius: "8px",
            maxHeight: "233px",
            marginTop: "10px",
          },
        }}
      >
        {samplingData &&
          samplingData.sampling_settings &&
          samplingData.sampling_settings.map((item, idx) => {
            return (
              <div
                key={idx}
                className={"configure-popover-options"}
                onClick={() => onOptionClick(item)}
              >
                <div className="configure-popover-options-title">
                  {item.display_name}
                </div>
                <div className="configure-popover-options-description">
                  {item.description}
                </div>
              </div>
            );
          })}
      </Popover>

      <div variant="outlined" onClick={handleClickOpen}>
        Configure Sample
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: "paper-configure",
          root: "root-configure",
        }}
      >
        {samplingDataLoading ? (
          <Loader open={samplingDataLoading} noDrop={true} />
        ) : (
          <>
            <DialogTitle sx={{ padding: 0 }}>
              <div className={"configure-title-container"}>
                <Typography component="div" className="configure-title-title">
                  Configure sample
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
            </DialogTitle>

            <DialogContent sx={{ padding: 0 }}>
              {/* <div
                className="configure-drop-down"
                onClick={(e) => handlePopoverOpen(e)}
              > */}
              <Box className="configure-content">
                <IInput
                  type="text"
                  label="Sampling method"
                  value={inputDispalyName}
                  disabled={inputDispalyName === "" ? true : false}
                  onClick={(e) => handlePopoverOpen(e)}
                  isSelect={true}
                  styleProps={{
                    width: "470px",
                  }}
                />
                {/* </div> */}
                <IInput
                  type={
                    (nextDisaplyInput &&
                    nextDisaplyInput[0] &&
                    nextDisaplyInput[0].type === "integer"
                      ? "number"
                      : nextDisaplyInput &&
                        nextDisaplyInput[0] &&
                        nextDisaplyInput[0].type) || "text"
                  }
                  label={
                    (nextDisaplyInput &&
                      nextDisaplyInput[0] &&
                      nextDisaplyInput[0].label) ||
                    "Records"
                  }
                  name="records"
                  value={nextDispalyInputValue}
                  placeholder={
                    nextDisaplyInput &&
                    nextDisaplyInput[0] &&
                    nextDisaplyInput[0].type
                  }
                  onChangeInput={(e) =>
                    setNextDispalyInputValue(e.target.value)
                  }
                  className="configure-drop-down-input"
                  styleProps={{
                    width: "470px",
                  }}
                />

                <Typography
                  component={"div"}
                  className="configure-filter-title"
                >
                  Filtering
                </Typography>
                <div className="configure-filter-container">
                  <ICheckBox
                    onChangeCheckbox={(e) => {
                      console.log(e);
                    }}
                  />
                  <Typography className="configure-filter-text">
                    Save previously applied filters
                  </Typography>
                </div>
              </Box>
            </DialogContent>
            <DialogActions sx={{ padding: 0, borderTop: "2px solid #DEE2E7" }}>
              <div className="configure-action-buttons">
                <IButton type="cancel" handleClick={handleClose}>
                  Cancel
                </IButton>
                <IButton
                  type={
                    nextDispalyInputValue.trim().length === 0
                      ? "primary-disabled"
                      : "primary"
                  }
                  handleClick={onSubmitConfigure}
                >
                  Submit
                </IButton>
              </div>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}

export default memo(ConfigureSample);
