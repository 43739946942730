import React, { Fragment, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TinyLineGraph from "../../../commonComponents/graphs/tableGraph";
import TableFilterSortPopup from "./tableFilterSortPopup";
import LoginIcon from "@mui/icons-material/Login";
import "./table.scss";
import IProgressBar from "../../progressBar";

const tableHeadData = [
  { title: "pipeline" },
  { title: "priority" },
  { title: "health" },
  { title: "drift" },
  { title: "accuracy" },
  { title: "activity" },
  { title: "productions/day" },
  { title: "last prediction" },
];

const tableBodyData = [
  {
    id: 1,
    title: "Biologic API fermentation pipeline",
    priority: 50,
    health: "success",
    drift: "success",
    accuracy: "success",
    activity: "graph here",
    predictions_per_day: 560,
    last_pridiction: "A few seconds ago",
    description: "Pipeline for Texas perfusion bioreactor facility",
    username: "@username",
    endpoint: "@userhttps://cdmo-model-1.isomer.ainame",
  },
  {
    id: 2,
    title: "CMDO Facility 2",
    priority: 75,
    health: "success",
    drift: "failed",
    accuracy: "warning",
    activity: "graph here",
    predictions_per_day: 737,
    last_pridiction: "A few mins ago",
    description: "Pipeline for Texas perfusion bioreactor facility",
    username: "@username",
    endpoint: "@userhttps://cdmo-model-1.isomer.ainame",
  },
  {
    id: 3,
    title: "CMDO Facility 2",
    priority: 75,
    health: "warning",
    drift: "failed",
    accuracy: "warning",
    activity: "graph here",
    predictions_per_day: 1205,
    last_pridiction: "An hour ago",
    description: "Pipeline for Texas perfusion bioreactor facility",
    username: "@username",
    endpoint: "@userhttps://cdmo-model-1.isomer.ainame",
  },
  {
    id: 4,
    title: "Biologics pilot pipeline",
    priority: 80,
    health: "success",
    drift: "success",
    accuracy: "success",
    activity: "graph here",
    predictions_per_day: 1102,
    last_pridiction: "An hour ago",
    description: "Pipeline for Texas perfusion bioreactor facility",
    username: "@username",
    endpoint: "@userhttps://cdmo-model-1.isomer.ainame",
  },
  {
    id: 5,
    title: "CMO small molecule pipeline",
    priority: 80,
    health: "warning",
    drift: "success",
    accuracy: "success",
    activity: "graph here",
    predictions_per_day: 560,
    last_pridiction: "Two hours ago",
    description: "Pipeline for Texas perfusion bioreactor facility",
    username: "@username",
    endpoint: "@userhttps://cdmo-model-1.isomer.ainame",
  },
  {
    id: 6,
    title: "Vaccine fermentation pipeline",
    priority: 40,
    health: "success",
    drift: "warning",
    accuracy: "warning",
    activity: "graph here",
    predictions_per_day: 560,
    last_pridiction: "Yesterday",
    description: "Pipeline for Texas perfusion bioreactor facility",
    username: "@username",
    endpoint: "@userhttps://cdmo-model-1.isomer.ainame",
  },
  {
    id: 7,
    title: "Vaccine fermentation pipeline",
    priority: 78,
    health: "failed",
    drift: "warning",
    accuracy: "success",
    activity: "graph here",
    predictions_per_day: 829,
    last_pridiction: "Jan 8, 2021",
    description: "Pipeline for Texas perfusion bioreactor facility",
    username: "@username",
    endpoint: "@userhttps://cdmo-model-1.isomer.ainame",
  },
  {
    id: 8,
    title: "Vaccine fermentation pipeline",
    priority: 88,
    health: "success",
    drift: "failed",
    accuracy: "warning",
    activity: "graph here",
    predictions_per_day: 1205,
    last_pridiction: "Jan 8, 2021",
    description: "Pipeline for Texas perfusion bioreactor facility",
    username: "@username",
    endpoint: "@userhttps://cdmo-model-1.isomer.ainame",
  },
  {
    id: 9,
    title: "Vaccine fermentation pipeline",
    priority: 88,
    health: "failed",
    drift: "warning",
    accuracy: "success",
    activity: "graph here",
    predictions_per_day: 829,
    last_pridiction: "Jan 5, 2021",
    description: "Pipeline for Texas perfusion bioreactor facility",
    username: "@username",
    endpoint: "@userhttps://cdmo-model-1.isomer.ainame",
  },
  {
    id: 10,
    title: "Vaccine fermentation pipeline",
    priority: 48,
    health: "success",
    drift: "warning",
    accuracy: "warning",
    activity: "graph here",
    predictions_per_day: 737,
    last_pridiction: "Dec 25, 2020",
    description: "Pipeline for Texas perfusion bioreactor facility",
    username: "@username",
    endpoint: "@userhttps://cdmo-model-1.isomer.ainame",
  },
  {
    id: 11,
    title: "Vaccine fermentation pipeline",
    priority: 36,
    health: "failed",
    drift: "warning",
    accuracy: "success",
    activity: "graph here",
    predictions_per_day: 829,
    last_pridiction: "Dec 25, 2020",
    description: "Pipeline for Texas perfusion bioreactor facility",
    username: "@username",
    endpoint: "@userhttps://cdmo-model-1.isomer.ainame",
  },
];

export default function Table() {
  const [showFilterSort, setShowFilterSort] = useState(false);

  const onTableHeadClick = () => {
    setShowFilterSort(true);
  };

  const toggleRow = (e, id) => {
    const elem = document.getElementsByClassName(`row${id}`);
    const parentTr = e.target.closest("tr");
    if (elem[0].style.display === "none") {
      elem[0].style.display = "";
      e.target.closest("tr").style.boxShadow = "none";
      e.target.closest("tr").style.border = "none";
      e.target.closest("tr").style.backgroundColor = "#F1F4F8";
      parentTr.getElementsByTagName("td")[1].children[0].style.backgroundColor =
        "#FFFFFF";
    } else if (elem[0].style.display === "") {
      elem[0].style.display = "none";
      e.target.closest("tr").style.boxShadow = "0 1px 0 0 #dee2e7";
      e.target.closest("tr").style.border = "1px solid #dee2e7";
      e.target.closest("tr").style.backgroundColor = "#FFFFFF";
      parentTr.getElementsByTagName("td")[1].children[0].style.backgroundColor =
        "#F0F5F6";
    }
  };

  const onExplorerClick = (item) => {
    //console.log(item);
    // setExplorerPopup(true);
  };

  return (
    <>
      <div className="table-container">
        {showFilterSort ? (
          <TableFilterSortPopup
            dropDownData={tableBodyData}
            closeFilterSortPopup={() => setShowFilterSort(false)}
          />
        ) : null}
        <table className="table">
          <thead onClick={onTableHeadClick}>
            <tr className="tr">
              {tableHeadData.map((item) => {
                return (
                  <th className="th" key={item.title}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {item.title} <ArrowDropDownIcon className="down-icon" />
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableBodyData.map((item) => {
              return (
                <Fragment key={item.id}>
                  <tr
                    onClick={(e) => toggleRow(e, item.id)}
                    id={`row${item.id}`}
                    className="tr"
                  >
                    <td className="td">{item.title}</td>
                    <td className="td">
                      <IProgressBar
                        value={item.priority}
                        borderRadius={"4px"}
                        barBgColor="#3C3996"
                        bgColor={"#F0F5F6"}
                        width={71.88}
                        height={15}
                        barBorderRadius={"4px"}
                      />
                    </td>
                    <td className="td">
                      {item.health === "success" ? (
                        <CheckCircleIcon className="success-icon" />
                      ) : item.health === "warning" ? (
                        <ErrorIcon className="warning-icon" />
                      ) : item.health === "failed" ? (
                        <CancelIcon className="failed-icon" />
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="td">
                      {item.drift === "success" ? (
                        <CheckCircleIcon className="success-icon" />
                      ) : item.drift === "warning" ? (
                        <ErrorIcon className="warning-icon" />
                      ) : item.drift === "failed" ? (
                        <CancelIcon className="failed-icon" />
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="td">
                      {item.accuracy === "success" ? (
                        <CheckCircleIcon className="success-icon" />
                      ) : item.accuracy === "warning" ? (
                        <ErrorIcon className="warning-icon" />
                      ) : item.accuracy === "failed" ? (
                        <CancelIcon className="failed-icon" />
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="td">
                      <TinyLineGraph />{" "}
                    </td>
                    <td className="td">
                      {item.predictions_per_day.toLocaleString()}
                    </td>
                    <td className="td">{item.last_pridiction}</td>
                  </tr>
                  <tr
                    className={`row${item.id}`}
                    style={{
                      height: "100px",
                      backgroundColor: "#F1F4F8",
                      boxShadow: " 0 1px 0 0 #C8CED5",
                      display: "none",
                    }}
                  >
                    <td colSpan={Object.keys(tableBodyData[0]).length - 1}>
                      <div className="hidden-row">
                        <div
                          className="explorer-button"
                          onClick={() => onExplorerClick(item)}
                        >
                          <LoginIcon className="explorer-button-icon" />
                          <div className="explorer-button-label">
                            Launch Explorer
                          </div>
                        </div>
                        <div className="description-container">
                          <div>
                            <div className="description-title">Description</div>
                            <div className="description-value">
                              {item.description}
                            </div>
                          </div>
                          <div>
                            <div className="description-title">Created by</div>
                            <div className="description-value">
                              {item.username}
                            </div>
                          </div>
                          <div>
                            <div className="description-title">Endpoint</div>
                            <div className="description-value-link">
                              {item.endpoint}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
