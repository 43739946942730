import {
  GET_ALL_CANVAS_ERROR,
  GET_ALL_CANVAS_PENDING,
  GET_ALL_CANVAS_SUCCESS,
  CREATE_CANVAS_ERROR,
  CREATE_CANVAS_PENDING,
  CREATE_CANVAS_SUCCESS,
  UPDATE_CANVAS_ERROR,
  UPDATE_CANVAS_SUCCESS,
  UPDATE_CANVAS_PENDING,
  DELETE_CANVAS_ERROR,
  DELETE_CANVAS_PENDING,
  DELETE_CANVAS_SUCCESS,
  GET_ALL_CANVAS_NODES_ERROR,
  GET_ALL_CANVAS_NODES_PENDING,
  GET_ALL_CANVAS_NODES_SUCCESS,
} from "../type";
import {
  getAllCanvas,
  createNewCanvas,
  updateCanvas,
  deleteCanvas,
  getAllCanvasNodes,
} from "../../api/canvasApi";
import { openGlobalSnackbar } from "./globalSnackbarAction";
import { openLoader, closeLoader } from "./globalLoaderAction";

var Alert;
// Fetch all Canvas
export const getAllCanvasAction = (user_id) => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_CANVAS_PENDING, loading: true });
    dispatch(openLoader({ isLoading: true }));
    try {
      const CanvasResult = await getAllCanvas(user_id);
      dispatch(closeLoader());
      return dispatch({
        type: GET_ALL_CANVAS_SUCCESS,
        payload: CanvasResult,
      });
    } catch (err) {
      if (!!err && !!err.response && !!err.response.data) {
        Alert = {
          type: "error",
          isOpen: true,
          message: err.response.data.message,
        };
        dispatch({
          type: GET_ALL_CANVAS_ERROR,
          payload: Alert,
        });
        dispatch(closeLoader());
        return dispatch(openGlobalSnackbar(err.message, true, "error"));
      } else {
        Alert = { type: "error", isOpen: true, message: err.statusText };
        dispatch(closeLoader());
        dispatch({ type: GET_ALL_CANVAS_ERROR, payload: Alert });
        return dispatch(openGlobalSnackbar(err.statusText, true, "error"));
      }
    }
  };
};

//create new canvas
export const createNewCanvasAction = (data) => {
  return async (dispatch) => {
    dispatch(openLoader({ isLoading: true }));
    dispatch({ type: CREATE_CANVAS_PENDING, loading: true });
    try {
      const CanvasResult = await createNewCanvas(data);
      dispatch(closeLoader());
      return dispatch({
        type: CREATE_CANVAS_SUCCESS,
        payload: CanvasResult,
      });
    } catch (err) {
      if (!!err && !!err.response && !!err.response.data) {
        Alert = {
          type: "error",
          isOpen: true,
          message: err.response.data.message,
        };
        dispatch({
          type: CREATE_CANVAS_ERROR,
          payload: Alert,
        });
        return dispatch(openGlobalSnackbar(err.message, true, "error"));
      } else {
        Alert = { type: "error", isOpen: true, message: err.statusText };
        dispatch({ type: CREATE_CANVAS_ERROR, payload: Alert });
        dispatch(closeLoader());
        return dispatch(openGlobalSnackbar(err.statusText, true, "error"));
      }
    }
  };
};
//update canvas
export const updateCanvasAction = (data, canvas_id) => {
  return async (dispatch) => {
    dispatch(openLoader({ isLoading: true }));
    dispatch({ type: UPDATE_CANVAS_PENDING, loading: true });
    try {
      await updateCanvas(data, canvas_id);
      Alert = {
        isOpen: true,
        type: "success",
        message: "UPDATED SUCCESSFULLY",
      };
      dispatch({
        type: UPDATE_CANVAS_SUCCESS,
        payload: Alert,
      });
      dispatch(closeLoader());
      return dispatch(
        openGlobalSnackbar("UPDATED SUCCESSFULLY", true, "success")
      );
    } catch (err) {
      if (!!err && !!err.response && !!err.response.data) {
        Alert = {
          type: "error",
          isOpen: true,
          message: err.response.data.message,
        };
        dispatch({
          type: UPDATE_CANVAS_ERROR,
          payload: Alert,
        });
        dispatch(closeLoader());
        return dispatch(openGlobalSnackbar(err.message, true, "error"));
      } else {
        Alert = { type: "error", isOpen: true, message: err.data.message };
        dispatch({ type: UPDATE_CANVAS_ERROR, payload: Alert });
        dispatch(closeLoader());
        return dispatch(openGlobalSnackbar(err.statusText, true, "error"));
      }
    }
  };
};
//delete canvas
export const deleteCanvasAction = (data, canvas_id) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_CANVAS_PENDING, loading: true });
    dispatch(openLoader({ isLoading: true }));

    try {
      await deleteCanvas(data, canvas_id);
      Alert = {
        isOpen: true,
        type: "success",
        message: "DELETED SUCCESSFULLY",
      };
      dispatch({
        type: DELETE_CANVAS_SUCCESS,
        payload: Alert,
      });
      dispatch(closeLoader());
      return dispatch(
        openGlobalSnackbar("DELETED SUCCESSFULLY", true, "success")
      );
    } catch (err) {
      if (!!err && !!err.response && !!err.response.data) {
        Alert = {
          type: "error",
          isOpen: true,
          message: err.response.data.message,
        };
        dispatch({
          type: DELETE_CANVAS_ERROR,
          payload: Alert,
        });
        dispatch(closeLoader());
        return dispatch(openGlobalSnackbar(err.message, true, "error"));
      } else {
        Alert = { type: "error", isOpen: true, message: err.data.message };
        dispatch({ type: DELETE_CANVAS_ERROR, payload: Alert });
        dispatch(closeLoader());
        return dispatch(openGlobalSnackbar(err.statusText, true, "error"));
      }
    }
  };
};

//get all nodes
export const getAllCanvasNodesAction = (canvas_id) => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_CANVAS_NODES_PENDING, loading: true });
    dispatch(openLoader({ isLoading: true }));
    try {
      const NodesResult = await getAllCanvasNodes(canvas_id);
      dispatch(closeLoader());
      return dispatch({
        type: GET_ALL_CANVAS_NODES_SUCCESS,
        payload: NodesResult,
      });
    } catch (err) {
      if (!!err && !!err.response && !!err.response.data) {
        dispatch({
          type: GET_ALL_CANVAS_NODES_ERROR,
          payload: err.response.data,
        });
        dispatch(closeLoader());
        dispatch(openGlobalSnackbar(err.response.data, true));
      } else {
        dispatch({ type: GET_ALL_CANVAS_NODES_ERROR, payload: err });
        dispatch(closeLoader());
        return dispatch(openGlobalSnackbar(err.statusText, true, "error"));
      }
    }
  };
};
