import * as React from "react";
import Radio from "@mui/material/Radio";

export default function IRadio({ value, onChange }) {
  return (
    <>
      <Radio
        sx={{
          color: "#3C3996",
          "&.Mui-checked": {
            color: "#3C3996",
          },
        }}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </>
  );
}
