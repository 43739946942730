import {
  addNode,
  deleteNode,
  updateNode,
  fetchNodeDetails,
  analyzeColumnType,
  editColumnType,
  getColumnDatabynodeId,
  getDatabynodeId,
  getSamplingSettingForNode,
  updateSamplingForNode,
  getFileURL,
  uploadFile,
  getCofigureDataset,
} from "../../api/nodeApi";
import {
  ADD_NODE_ERROR,
  ADD_NODE_PENDING,
  ADD_NODE_SUCCESS,
  ANALYZE_COLUMN_TYPE_ERROR,
  ANALYZE_COLUMN_TYPE_PENDING,
  ANALYZE_COLUMN_TYPE_SUCCESS,
  DELETE_NODE_ERROR,
  DELETE_NODE_PENDING,
  DELETE_NODE_SUCCESS,
  FETCH_NODE_DETAILS_ERROR,
  FETCH_NODE_DETAILS_PENDING,
  FETCH_NODE_DETAILS_SUCCESS,
  GET_CLOUMN_LIST_ERROR,
  GET_CLOUMN_LIST_PEDING,
  GET_CLOUMN_LIST_SUCCESS,
  EDIT_COLUMN_TYPE_ERROR,
  EDIT_COLUMN_TYPE_SUCCESS,
  EDIT_COLUMN_TYPE_PENDING,
  GET_CLOUMN_DATA_ERROR,
  GET_CLOUMN_DATA_PEDING,
  GET_CLOUMN_DATA_SUCCESS,
  UPDATE_NODE_PENDING,
  UPDATE_NODE_SUCCESS,
  UPDATE_NODE_ERROR,
  CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_ERROR,
  CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_PENDING,
  CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_SUCCESS,
  GET_FILE_URL_SUCCESS,
  GET_FILE_URL_PENDING,
  GET_FILE_URL_ERROR,
  POST_FILE_PENDING,
  POST_FILE_SUCCESS,
  POST_FILE_ERROR,
  GET_COFIGURE_DATASET_PENDING,
  GET_COFIGURE_DATASET_SUCCESS,
  GET_COFIGURE_DATASET_ERROR,
} from "../type";
import { openLoader, closeLoader } from "./globalLoaderAction";
import { openGlobalSnackbar } from "./globalSnackbarAction";

export const addNodeAction = (data) => {
  return async (dispatch) => {
    dispatch({ type: ADD_NODE_PENDING, loading: true });
    dispatch(openLoader({ isLoading: true }));
    try {
      const nodeResult = await addNode(data);
      dispatch(closeLoader());
      return dispatch({
        type: ADD_NODE_SUCCESS,
        payload: nodeResult,
      });
    } catch (err) {
      if (!!err && !!err.response && !!err.response.data) {
        dispatch(closeLoader());
        dispatch(openGlobalSnackbar(err.data.message, true, "error"));
        return dispatch({
          type: ADD_NODE_ERROR,
          payload: err.response.data,
        });
      } else {
        dispatch(closeLoader());
        dispatch(openGlobalSnackbar(err.data.message, true, "error"));
        return dispatch({ type: ADD_NODE_ERROR, payload: err });
      }
    }
  };
};

export const updateNodeAction = (node_id, data) => {
  console.log(node_id, data);
  return async (dispatch) => {
    dispatch({ type: UPDATE_NODE_PENDING, loading: true });
    dispatch(openLoader({ isLoading: true }));
    try {
      const nodeResult = await updateNode(node_id, data);
      dispatch(closeLoader());
      return dispatch({
        type: UPDATE_NODE_SUCCESS,
        payload: nodeResult,
      });
    } catch (err) {
      if (!!err && !!err.response && !!err.response.data) {
        dispatch(closeLoader());
        dispatch(openGlobalSnackbar(err.data.message, true, "error"));
        return dispatch({
          type: UPDATE_NODE_ERROR,
          payload: err.response.data,
        });
      } else {
        dispatch(closeLoader());
        dispatch(openGlobalSnackbar(err.data.message, true, "error"));
        return dispatch({ type: UPDATE_NODE_ERROR, payload: err });
      }
    }
  };
};

export const deleteNodeAction = (node_id) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_NODE_PENDING, loading: true });
    dispatch(openLoader({ isLoading: true }));
    try {
      const nodeResult = await deleteNode(node_id);
      dispatch(closeLoader());
      return dispatch({
        type: DELETE_NODE_SUCCESS,
        payload: nodeResult,
      });
    } catch (err) {
      if (!!err && !!err.response && !!err.response.data) {
        dispatch(closeLoader());
        dispatch(openGlobalSnackbar(err.data.message, true, "error"));
        return dispatch({
          type: DELETE_NODE_ERROR,
          payload: err.response.data,
        });
      } else {
        dispatch(closeLoader());
        dispatch(openGlobalSnackbar(err.data.message, true, "error"));
        return dispatch({ type: DELETE_NODE_ERROR, payload: err });
      }
    }
  };
};

export const fetchNodeDetailsAction = (node_id) => {
  return async (dispatch) => {
    dispatch(openLoader({ isLoading: true }));
    dispatch({ type: FETCH_NODE_DETAILS_PENDING, loading: true });
    try {
      const nodeResult = await fetchNodeDetails(node_id);
      dispatch(closeLoader());
      return dispatch({
        type: FETCH_NODE_DETAILS_SUCCESS,
        payload: nodeResult,
      });
    } catch (err) {
      if (!!err && !!err.response && !!err.response.data) {
        dispatch(closeLoader());
        return dispatch({
          type: FETCH_NODE_DETAILS_ERROR,
          payload: err.response.data,
        });
      } else {
        dispatch(closeLoader());
        return dispatch({ type: FETCH_NODE_DETAILS_ERROR, payload: err });
      }
    }
  };
};

export const analyzeColumnTypeAction = (node_id, columnName) => {
  return async (dispatch) => {
    dispatch({ type: ANALYZE_COLUMN_TYPE_PENDING, loading: true });
    dispatch(openLoader({ isLoading: true }));
    try {
      const ColumnTypeResult = await analyzeColumnType(node_id, columnName);
      dispatch(closeLoader());
      return dispatch({
        type: ANALYZE_COLUMN_TYPE_SUCCESS,
        payload: ColumnTypeResult,
      });
    } catch (err) {
      dispatch(closeLoader());
      if (!!err && !!err.response && !!err.response.data) {
        return dispatch({
          type: ANALYZE_COLUMN_TYPE_ERROR,
          payload: err.response.data,
        });
      } else {
        dispatch(closeLoader());
        dispatch(openGlobalSnackbar(err.message, true, "error"));
        return dispatch({ type: ANALYZE_COLUMN_TYPE_ERROR, payload: err });
      }
    }
  };
};

export const editColumnTypeAction = (node_id, columnName, data) => {
  return async (dispatch) => {
    dispatch({ type: EDIT_COLUMN_TYPE_PENDING, loading: true });
    dispatch(openLoader({ isLoading: true }));
    try {
      const ColumnTypeResult = await editColumnType(node_id, columnName, data);
      await getColumnDatabynodeId(node_id);
      await getDatabynodeId({}, node_id, 0, 20);
      dispatch(closeLoader());
      return dispatch({
        type: EDIT_COLUMN_TYPE_SUCCESS,
        payload: ColumnTypeResult,
      });
    } catch (err) {
      dispatch(closeLoader());
      if (!!err && !!err.response && !!err.response.data) {
        return dispatch({
          type: EDIT_COLUMN_TYPE_ERROR,
          payload: err.response.data,
        });
      } else {
        dispatch(closeLoader());
        dispatch(openGlobalSnackbar(err.message, true, "error"));
        return dispatch({ type: EDIT_COLUMN_TYPE_ERROR, payload: err });
      }
    }
  };
};

export const getColumnDatabynodeIdAction = (node_id) => {
  return async (dispatch) => {
    dispatch({ type: GET_CLOUMN_LIST_PEDING, loading: true });
    dispatch(openLoader({ isLoading: true }));
    try {
      const ColumnListResult = await getColumnDatabynodeId(node_id);

      dispatch({
        type: GET_CLOUMN_LIST_SUCCESS,
        payload: ColumnListResult,
      });
      return dispatch(closeLoader());
    } catch (err) {
      if (!!err && !!err.response && !!err.response.data) {
        dispatch(closeLoader());

        return dispatch({
          type: GET_CLOUMN_LIST_ERROR,
          payload: err.response.data,
        });
      } else {
        dispatch(closeLoader());
        dispatch(openGlobalSnackbar(err.message, true, "error"));
        return dispatch({ type: GET_CLOUMN_LIST_ERROR, payload: err });
      }
    }
  };
};

export const getDatabynodeIdAction = (data, node_id, offset, limit) => {
  return async (dispatch) => {
    dispatch({ type: GET_CLOUMN_DATA_PEDING, loading: true });
    dispatch(openLoader({ isLoading: true }));
    try {
      const ColumnListResult = await getDatabynodeId(
        data,
        node_id,
        offset,
        limit
      );

      dispatch({
        type: GET_CLOUMN_DATA_SUCCESS,
        payload: ColumnListResult,
      });
      return dispatch(closeLoader());
    } catch (err) {
      if (!!err && !!err.response && !!err.response.data) {
        dispatch(closeLoader());
        dispatch(openGlobalSnackbar(err.data.message, true, "error"));
        return dispatch({
          type: GET_CLOUMN_DATA_ERROR,
          payload: err.response.data,
        });
      } else {
        dispatch(closeLoader());
        dispatch(openGlobalSnackbar(err.message, true, "error"));
        return dispatch({ type: GET_CLOUMN_DATA_ERROR, payload: err });
      }
    }
  };
};

export const getSamplingSettingForNodeAction = (node_id) => {
  return async (dispatch) => {
    dispatch({
      type: CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_PENDING,
      loading: true,
    });
    dispatch(openLoader({ isLoading: true }));
    try {
      const samplingSettingsByNode = await getSamplingSettingForNode(node_id);
      dispatch(closeLoader());
      return dispatch({
        type: CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_SUCCESS,
        payload: samplingSettingsByNode,
      });
    } catch (err) {
      dispatch(closeLoader());
      if (!!err && !!err.response && !!err.response.data) {
        return dispatch({
          type: CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_ERROR,
          payload: err.response.data,
        });
      } else {
        dispatch(closeLoader());
        dispatch(openGlobalSnackbar(err.data.message, true, "error"));
        return dispatch({
          type: CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_ERROR,
          payload: err,
        });
      }
    }
  };
};

export const updateSamplingForNodeAction = (node_id, data) => {
  return async (dispatch) => {
    dispatch({
      type: CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_PENDING,
      loading: true,
    });
    dispatch(openLoader({ isLoading: true }));
    try {
      const samplingSettingsByNode = await updateSamplingForNode(node_id, data);
      await getColumnDatabynodeId(node_id);
      await getDatabynodeId({}, node_id, 0, 20);
      dispatch(closeLoader());
      return dispatch({
        type: CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_SUCCESS,
        payload: samplingSettingsByNode,
      });
    } catch (err) {
      dispatch(closeLoader());
      if (!!err && !!err.response && !!err.response.data) {
        return dispatch({
          type: CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_ERROR,
          payload: err.response.data,
        });
      } else {
        dispatch(closeLoader());
        dispatch(openGlobalSnackbar(err.message, true, "error"));
        return dispatch({
          type: CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_ERROR,
          payload: err,
        });
      }
    }
  };
};

export const getFileURLAction = (fileName) => {
  return async (dispatch) => {
    dispatch({
      type: GET_FILE_URL_PENDING,
      loading: true,
    });
    dispatch(openLoader({ isLoading: true }));
    try {
      const fileURL = await getFileURL(fileName);
      dispatch(closeLoader());
      return dispatch({
        type: GET_FILE_URL_SUCCESS,
        payload: fileURL,
      });
    } catch (err) {
      dispatch(closeLoader());
      if (!!err && !!err.response && !!err.response.data) {
        return dispatch({
          type: GET_FILE_URL_ERROR,
          payload: err.response.data,
        });
      } else {
        dispatch(closeLoader());
        dispatch(openGlobalSnackbar(err.message, true, "error"));
        return dispatch({
          type: GET_FILE_URL_ERROR,
          payload: err,
        });
      }
    }
  };
};

export const uploadFileAction = (url, uploadfile) => {
  return async (dispatch) => {
    dispatch({
      type: POST_FILE_PENDING,
      loading: true,
    });
    dispatch(openLoader({ isLoading: true }));
    try {
      const file = await uploadFile(url, uploadfile);
      dispatch(closeLoader());
      dispatch({
        type: POST_FILE_SUCCESS,
        payload: file,
      });
      return dispatch(
        openGlobalSnackbar("FILE UPLOADED SUCCESSFULLY", true, "success")
      );
    } catch (err) {
      dispatch(closeLoader());
      if (!!err && !!err.response && !!err.response.data) {
        return dispatch({
          type: GET_FILE_URL_ERROR,
          payload: err.response.data,
        });
      } else {
        dispatch(closeLoader());
        dispatch(openGlobalSnackbar(err.message, true, "error"));
        return dispatch({
          type: POST_FILE_ERROR,
          payload: err,
        });
      }
    }
  };
};

export const getCofigureDatasetAction = (user_id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_COFIGURE_DATASET_PENDING,
      loading: true,
    });
    dispatch(openLoader({ isLoading: true }));
    try {
      const datasets = await getCofigureDataset(user_id);
      dispatch(closeLoader());
      return dispatch({
        type: GET_COFIGURE_DATASET_SUCCESS,
        payload: datasets,
      });
    } catch (err) {
      dispatch(closeLoader());
      if (!!err && !!err.response && !!err.response.data) {
        return dispatch({
          type: GET_COFIGURE_DATASET_ERROR,
          payload: err.response.data,
        });
      } else {
        dispatch(closeLoader());
        dispatch(openGlobalSnackbar(err.message, true, "error"));
        return dispatch({
          type: GET_COFIGURE_DATASET_ERROR,
          payload: err,
        });
      }
    }
  };
};
