import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import IButton from "../../button";
import "./configurePopup.scss";

const ConfigurePopup = ({
  handleClose,
  open,
  datasets,
  submitConfigDataset,
  setSelectedDataset,
  selectedDataset,
}) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="draggable-dialog-title"
      classes={{
        paper: "paper",
        root: "root",
      }}
    >
      <DialogTitle className={"dialogTitle"}>
        <Typography className={"dialogHead"}>Cofigure Datasets</Typography>
        <Typography className={"dialogSubHead"}>
          Select a dataset from below
        </Typography>
      </DialogTitle>
      <DialogContent classes={{ root: "dialogContent" }}>
        <Box className={"config-data-container"}>
          {datasets &&
            datasets.datasets &&
            datasets.datasets.map((data, idx) => {
              return (
                <div
                  className={
                    data === selectedDataset
                      ? "config-data-rows selected-dataset"
                      : `config-data-rows`
                  }
                  key={idx}
                  onClick={() => setSelectedDataset(data)}
                >
                  {data.dataset_name}
                </div>
              );
            })}
        </Box>
      </DialogContent>
      <DialogActions className={"dialogActions"}>
        <IButton type={"cancel"} handleClick={handleClose}>
          Cancel
        </IButton>
        <IButton
          type={
            selectedDataset && Object.keys(selectedDataset).length === 0
              ? "primary-disabled"
              : "primary"
          }
          handleClick={submitConfigDataset}
        >
          Submit
        </IButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfigurePopup;
