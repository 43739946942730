import { CLOSE_SNACKBAR, OPEN_SNACKBAR } from "../type";

const initialSettings = {
  message: "",
  open: false,
  error: false,
  type: "",
};
const snackBarReducer = (state = initialSettings, { type, payload }) => {
  switch (type) {
    case OPEN_SNACKBAR:
      return {
        ...state,
        message: payload.message,
        open: true,
        error: payload.error,
        type: payload.type,
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        message: "",
        open: false,
        error: false,
        type: "",
      };

    default:
      return state;
  }
};

export default snackBarReducer;
