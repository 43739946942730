import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import SortOutlinedIcon from "@mui/icons-material/SortOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import BasicMenuBtn from "../../menu";
import ICheckBox from "../../checkBox";
import IButton from "../../button";
import "./exploreSortPopup.scss";
import { getDatabynodeIdAction } from "../../../../redux/actions/nodeAction";
import { useParams } from "react-router-dom";

let i = 1;

export default function SortDialog({
  optionContainerStyle,
  iconStyle,
  titleStyle,
  columnsList,
  columnsData,
  closeMenu,
}) {
  const [open, setOpen] = useState(false);
  const [sortItems, setSortItems] = useState([]);
  const [rowSelected, setRowSelected] = useState({});
  const [defaultSortType] = useState("Ascending");
  const params = useParams();
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const exploreSortOptions = ["", "Column", "Sort On", "Order", "Color / Icon"];
  const sortOrderOptions = [
    {
      name: "Ascending",
    },
    {
      name: "Descending",
    },
  ];

  const addSortColumn = () => {
    setSortItems([
      ...sortItems,
      {
        name:
          columnsList &&
          columnsList.columns &&
          columnsList.columns[sortItems.length].name,
        type: sortItems.length === 0 ? "Sort by" : "Then by",
        sortType: defaultSortType,
      },
    ]);
  };

  useEffect(() => {
    if (sortItems && sortItems.length === 0) {
      i = 1;
      setRowSelected({});
    }
  }, [sortItems]);

  const checkSortOptionOnLoad = useCallback(() => {
    const sortItemsFromLocal = JSON.parse(localStorage.getItem("sortOptions"));

    if (sortItemsFromLocal && sortItemsFromLocal !== null) {
      if (
        columnsData &&
        columnsData.sort_order &&
        columnsData.sort_order.length === 0
      ) {
        setSortItems([]);
        window.localStorage.removeItem("sortOptions");
      } else {
        setSortItems(sortItemsFromLocal);
      }
    }
  }, [columnsData]);

  useEffect(() => {
    checkSortOptionOnLoad();
  }, [checkSortOptionOnLoad]);

  const removeSortColumn = () => {
    if (sortItems.length !== 0) {
      setSortItems(
        sortItems.filter(
          (item, index) => sortItems.length - 1 !== index && item
        )
      );
    }
  };

  const onChangeMenuColumnName = (e, idx) => {
    setSortItems(
      sortItems.filter((it, index) => {
        if (index === idx) {
          it.name = e.name;
        }
        return it;
      })
    );
  };

  const onChangeMenuSortType = (e, idx) => {
    setSortItems(
      sortItems.filter((it, index) => {
        if (index === idx) {
          it.sortType = e.name;
        }
        return it;
      })
    );
  };

  const onAddRowClick = (e, idx, item) => {
    if (
      (rowSelected && rowSelected.idx === idx) ||
      Object.keys(rowSelected).length === 0
    ) {
      const targetId = e.target.parentNode.id;
      document
        .getElementById(`${targetId}`)
        .classList.toggle("onAddSortRowClick");
      if (i !== 1 || (rowSelected && rowSelected.idx === idx)) {
        setRowSelected({});
        i = 1;
      } else {
        setRowSelected({ idx, item });
      }
    }
  };

  const onSortOptionCopy = () => {
    if (sortItems.length !== 0) {
      if (
        rowSelected &&
        rowSelected.item &&
        rowSelected.item.name !== undefined
      ) {
        let rowIndex =
          columnsList &&
          columnsList.columns.findIndex(
            (item, index) => item.name === rowSelected.item.name && index
          );
        let CN = columnsList.columns[rowIndex + i++];
        if (CN !== undefined) {
          const newRow = {
            name: columnsList && columnsList.columns && CN.name,
            type: "Then by",
            sortType: rowSelected.item.sortType,
          };
          setSortItems([...sortItems, newRow]);
        }
      }
    }
  };

  const onSortSubmit = () => {
    const filteredSortItems = sortItems.map((item) => {
      return {
        name: item.name,
        ascending: item.sortType === "Ascending" ? true : false,
      };
    });
    const nodeId = params.nodeId;
    dispatch(
      getDatabynodeIdAction(
        { sort_properties: filteredSortItems },
        nodeId,
        0,
        20
      )
    );
    window.localStorage.setItem("sortOptions", JSON.stringify(sortItems));
    handleClose();
    closeMenu();
  };

  return (
    <>
      <div className={optionContainerStyle} onClick={handleClickOpen}>
        <div className={iconStyle}>{<SortOutlinedIcon />}</div>
        <div className={titleStyle}>Sort</div>
      </div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        classes={{
          paper: "paper-ex",
          root: "root-ex",
        }}
      >
        <DialogTitle sx={{ padding: 0 }}>
          <Box component="div" className="exp-sort-head-container">
            <Typography component="div" className="exp-sort-head-title">
              Add levels to sort by
            </Typography>
            <Typography
              component="div"
              className="exp-sort-head-checkbox-container"
            >
              <ICheckBox
                defaultChecked={true}
                onChangeCheckbox={(e) => {
                  console.log(e);
                }}
              />
              <Typography className="exp-sort-head-checkbox-tilte">
                My list has headers
              </Typography>
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: "0px" }}>
          <Box component="div" className="exp-sort-content">
            <Box component="div" className="exp-sort-columns">
              {exploreSortOptions.map((item, idx) => {
                return (
                  <div key={idx} className="exp-sort-column">
                    {item}
                  </div>
                );
              })}
            </Box>
            {sortItems &&
              sortItems.map((item, idx) => {
                return (
                  <div key={idx} className="exp-added-rows" id={`R${idx}`}>
                    <Typography
                      component={"div"}
                      className="exp-added-row-item"
                      onClick={(e) => onAddRowClick(e, idx, item)}
                    >
                      {item.type}
                    </Typography>
                    <Typography
                      component={"div"}
                      className="exp-added-row-item"
                    >
                      <BasicMenuBtn
                        menuData={
                          columnsList &&
                          columnsList.columns &&
                          columnsList.columns
                        }
                        title={item.name}
                        menuStyle={"exp-sort-options"}
                        menuHeadStyle={"exp-sort-head"}
                        iconStyle={"export-sort-icons"}
                        menuItemIcon={
                          <>
                            <ArrowDropUp className="export-sort-icon-up" />
                            <ArrowDropDown className="export-sort-icon-down" />
                          </>
                        }
                        av={"bottom"}
                        ah={"center"}
                        tv={"top"}
                        th={"center"}
                        onChangeMenuItem={(e) => onChangeMenuColumnName(e, idx)}
                      />
                    </Typography>
                    <Typography
                      component={"div"}
                      className="exp-added-row-item"
                    >
                      Values
                    </Typography>
                    <Typography
                      className="exp-added-row-item"
                      component={"div"}
                    >
                      <BasicMenuBtn
                        menuData={sortOrderOptions}
                        title={item.sortType}
                        menuStyle={"exp-sort-options"}
                        menuHeadStyle={"exp-sort-head"}
                        iconStyle={"export-sort-icons"}
                        menuItemIcon={
                          <>
                            <ArrowDropUp className="export-sort-icon-up" />
                            <ArrowDropDown className="export-sort-icon-down" />
                          </>
                        }
                        av={"bottom"}
                        ah={"center"}
                        tv={"top"}
                        th={"center"}
                        onChangeMenuItem={(e) => onChangeMenuSortType(e, idx)}
                      />
                    </Typography>
                    <Typography
                      component={"div"}
                      className="exp-added-row-item"
                    ></Typography>
                  </div>
                );
              })}
          </Box>
          <Box component="div" className="exp-sort-content-bottom">
            <IButton
              type={"default"}
              handleClick={addSortColumn}
              customClass={"exp-sort-btns"}
              disabled={
                columnsList &&
                columnsList.columns &&
                columnsList.columns.length === sortItems.length
              }
            >
              <AddIcon />
            </IButton>
            <IButton
              type={"default"}
              handleClick={removeSortColumn}
              customClass={"exp-sort-btns"}
            >
              <RemoveIcon />
            </IButton>
            <IButton
              type={"default"}
              customClass={"exp-sort-btns-text"}
              handleClick={onSortOptionCopy}
            >
              Copy
            </IButton>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            height: "auto",
            width: "auto",
            alignItems: "center",
            justifyContent: "space-between",
            borderTop: "2px solid #DEE2E7",
            paddingLeft: "20px",
          }}
        >
          <IButton type={"cancel"} handleClick={handleClose}>
            Cancel
          </IButton>
          <Box component="div" sx={{ display: "flex" }}>
            <IButton type={"default"} handleClick={null}>
              Options
            </IButton>
            <IButton
              type={sortItems.length === 0 ? "primary-disabled" : "primary"}
              handleClick={onSortSubmit}
            >
              Submit
            </IButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
