import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import SnackBar from "./components/themeComponents/snackbar";
import Loader from "./components/themeComponents/loader";
import { closeGlobalSnackbar } from "./redux/actions/globalSnackbarAction";
import AllRoutes from "./components/routing/AllRoutes";
import "./App.scss";

const App = () => {
  const dispatch = useDispatch();
  const SnackbarData = useSelector((state) => state.snackBarReducer);
  const LoaderData = useSelector((state) => state.loaderReducer.isLoading);
  const handleClose = () => {
    dispatch(closeGlobalSnackbar());
  };

  return (
    <div className="App">
      {LoaderData && LoaderData === true ? <Loader open={LoaderData} /> : null}
      <SnackBar
        isOpen={SnackbarData.open}
        type={SnackbarData.type || "primary"}
        message={SnackbarData.message}
        handleClose={() => handleClose()}
      />
      <Router>
        <AllRoutes />
      </Router>
    </div>
  );
};

export default App;
