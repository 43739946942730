import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import NavBar from "../../themeComponents/Navbar/workspaceNavbar";

export default function WorkSpaceLayout({ children }) {
  const navigate = useNavigate();
  const params = useParams();
  const LoaderData = useSelector((state) => state.loaderReducer.isLoading);
  const canvasTitle = JSON.parse(window.localStorage.getItem("canvasName"));

  const newCanvasDetails = useSelector(
    (state) => state.createNewCanvasReducer.newCanvas
  );

  const hanldeGoBack = (e) => {
    params.nodeId && params.nodeId !== undefined
      ? navigate(`/app/workspace/${params.canvasId}`)
      : navigate("/app/dashboard");
  };

  return (
    <>
      <NavBar
        onBack={(e) => hanldeGoBack(e)}
        LoaderData={LoaderData}
        canvasTitle={
          newCanvasDetails.canvas_name
            ? newCanvasDetails.canvas_name
            : canvasTitle
        }
      />
      {children}
    </>
  );
}
