import request from "../redux/services/request";
import axios from "axios";

export const addNode = (data) => {
  return request({
    url: `/node`,
    method: "POST",
    data,
  });
};

export const deleteNode = (node_id) => {
  return request({
    url: `/node/${node_id}`,
    method: "DELETE",
  });
};

export const updateNode = (node_id, data) => {
  return request({
    url: `/node/${node_id}`,
    method: "PUT",
    data,
  });
};

export const fetchNodeDetails = (node_id) => {
  return request({
    url: `/node/${node_id}`,
    method: "GET",
  });
};

//return all the analysis data of the column (min, max, std, valid, invalid)
export const analyzeColumnType = (node_id, columnName) => {
  return request({
    url: `/node/${node_id}/columns/${columnName}/analyze`,
    method: "GET",
  });
};

export const editColumnType = (node_id, columnName, data) => {
  return request({
    url: `/node/${node_id}/columns/${columnName}/type`,
    method: "POST",
    data,
  });
};

//return the list of columns and their respective types
export const getColumnDatabynodeId = (node_id) => {
  return request({
    url: `/node/${node_id}/columns`,
    method: "GET",
  });
};

//return explore table columns data
export const getDatabynodeId = (data, node_id, offset, limit) => {
  return request({
    url: `/node/${node_id}/data/?offset=${offset}&limit=${limit}`,
    method: "POST",
    data,
  });
};

export const getSamplingSettingForNode = () => {
  return request({
    url: `/node/sampling_settings`,
    method: "GET",
  });
};

export const updateSamplingForNode = (node_id, data) => {
  return request({
    url: `/node/${node_id}/sampling_settings`,
    method: "POST",
    data,
  });
};

export const getFileURL = (fileName) => {
  return request({
    url: `upload_file`,
    method: "GET",
    params: {
      tenant_id: "isomercustomer1",
      user_id: "tawfeeq123",
      file_name: fileName,
    },
  });
};

export const uploadFile = (url, uploadFile) => {
  axios
    .put(url, uploadFile, {
      headers: {
        "Content-Type": "text/csv",
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const getCofigureDataset = (user_id) => {
  return request({
    url: `datasets/${user_id}`,
    method: "GET",
  });
};
