import React from "react";
import { Checkbox } from "@mui/material";
import { withStyles } from "@material-ui/core";

const CustomCheckBox = withStyles({
  root: {
    "& .MuiSvgIcon-root": {
      fill: "#8b97a2",
      height: 20,
      width: 20,
      "&:hover": {
        backgroundColor: "#FFFFFF",
      },
    },
    "&$checked": {
      "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
        borderRadius: 4,
      },
      "& .MuiIconButton-label:after": {
        content: '""',
        left: 4,
        top: 4,
        height: 14,
        width: 14,
        position: "absolute",
        backgroundColor: "#FFFFFF",
        zIndex: -1,
      },
    },
    "&:not($checked) .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
      borderRadius: 3,
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 15,
      width: 15,
      position: "absolute",
      backgroundColor: "#FFFFFF",
      zIndex: -1,
    },
  },
  checked: {
    "& .MuiSvgIcon-root": {
      fill: "#3C3996",
      "&:hover": {
        backgroundColor: "#FFFFFF",
      },
    },
  },
})(Checkbox);

export default function ICheckBox({
  defaultChecked,
  onChangeCheckbox,
  className,
  checked,
  disabled,
}) {
  return (
    <>
      <CustomCheckBox
        defaultChecked={defaultChecked}
        onChange={(e) => onChangeCheckbox(e)}
        className={className}
        checked={checked}
        disabled={disabled}
      />
    </>
  );
}
