import React from "react";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Box,
  Avatar,
  Toolbar,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowLeft from "../../../../assets/icons/leftArrow.svg";
import ArrowRight from "../../../../assets/icons/rightArrow.svg";
import man from "../../../../assets/man.png";
import "./dashboardNavbar.scss";

const drawerWidth = 300;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  marginLeft: `calc(${theme.spacing(7)} + 1px)`,
  width: `calc(100% - ${theme.spacing(9.4)} + 1px)`,
  backgroundColor: "#FFFFFF",
  boxShadow: "0 1px 3px 0 rgba(0,0,0,0.15)",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    zIndex: 1,
  },
}));

export default function NavBar({ open, handleDrawerClose, handleDrawerOpen }) {
  const resize = useMediaQuery("(max-width:600px)");
  return (
    <>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          {resize ? (
            <IconButton
              className={"nav-button-mobile"}
              onClick={open ? handleDrawerClose : handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={open ? handleDrawerClose : handleDrawerOpen}
              className={"nav-button"}
            >
              {open ? (
                <img src={ArrowLeft} alt="left" />
              ) : (
                <img src={ArrowRight} alt="right" />
              )}
            </IconButton>
          )}
          <Box
            component="div"
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box component="div">
              <Typography className="appbar-head" noWrap component="div">
                Welcome, Andrew!
              </Typography>
            </Box>
            <Box
              sx={{
                width: resize ? "auto" : "197.91px",
                height: "58.32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              component="div"
            >
              <IconButton>
                <HelpOutlineOutlinedIcon className="help-icon" />
              </IconButton>
              <Avatar alt="Jackson" src={man} className="profile-icon" />
              <Box component="div" sx={{ width: "inherit", display: "flex" }}>
                {resize ? null : (
                  <>
                    <Box component="div">
                      <Typography className="user-name">Jackson A.</Typography>
                      <Typography className="user-role">
                        Basetwo Admin
                      </Typography>
                    </Box>
                    <IconButton
                      sx={{ marginLeft: "-20px", marginTop: "-10px" }}
                    >
                      <KeyboardArrowDownIcon />
                    </IconButton>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
