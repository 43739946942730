import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ArrowBack, Close, Search } from "@mui/icons-material";
import "./exploreConfigHeader.scss";
import {
  exploreConfigPopoverData1,
  exploreConfigPopoverData2,
} from "../../../../utils/dummyData";
import ICheckBox from "../../checkBox";
import ISearchDefault from "../../search/defaultSearch";
import { LensBlur, NumbersOutlined, TipsAndUpdates } from "@mui/icons-material";
import addIcon from "../../../../assets/icons/addIcon.svg";
import deleteIcon from "../../../../assets/icons/deleteWhite.svg";
import IButton from "../../button";

export default function ExploreConfigHeader({
  onCloseConfig,
  Columns,
  onCreateCard,
}) {
  const [anchorEl, setAnchorEl] = useState(false);
  const [openSecond, setOpenSecond] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [droppedItems, setDroppedItems] = useState([]);
  const [cardData, setCardData] = useState({
    titles: "",
    variables: [],
    checkBoxLabel: [],
  });

  const openMenu = (ev, item) => {
    setAnchorEl(!anchorEl);
    setOpenSecond(false);
  };

  const onChangeCheck = (e, item) => {
    if (cardData.checkBoxLabel.includes(item.title)) {
      let fData = cardData.checkBoxLabel.filter((it) => {
        let obj;
        if (it.trim() !== item.title.trim()) {
          obj = it;
        }
        return obj;
      });
      setCardData({
        ...cardData,
        checkBoxLabel: fData,
      });
    } else {
      setCardData({
        ...cardData,
        checkBoxLabel: [...cardData.checkBoxLabel, item.title],
      });
    }
    setOpenSecond(true);
  };

  const onOpenDialog = (item) => {
    setCardData({
      ...cardData,
      titles: item.title,
    });
    setOpenDialog(true);
  };

  const onGoBackPopup = () => {
    setOpenDialog(false);
    setAnchorEl(true);
    setOpenSecond(true);
  };

  const closeDialog = () => {
    setOpenDialog(false);
    setAnchorEl(false);
    setOpenSecond(false);
  };

  const onDragStartColumn = (e) => {
    e.dataTransfer.setData("text", e.target.childNodes[2].innerHTML);
    e.dataTransfer.effectAllowed = "move";
  };

  const dropVariable = (ev) => {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    const arr = [];
    arr.push(data);
    setDroppedItems(droppedItems.concat(arr));
  };

  function allowDrop(ev) {
    ev.preventDefault();
  }

  const deleteVariable = (id) => {
    const filterdItems = droppedItems.filter((item, idx) => {
      let it;
      if (idx !== id) {
        it = item;
      }
      return it;
    });
    setDroppedItems(filterdItems);
  };

  return (
    <>
      {anchorEl ? (
        <div
          style={{
            boxSizing: "border-box",
            height: "auto",
            width: " 274px",
            borderRadius: "8px",
            backgroundColor: " #FFFFFF",
            boxShadow: "0 5px 20px 0 rgba(36,36,36,0.25)",
            padding: "16px 24px 16px 16px",
            margin: "16px 0px 0px -16px",
            position: "absolute",
            zIndex: 990,
            top: "40px",
            left: "16px",
          }}
        >
          {exploreConfigPopoverData1.map((item, index) => {
            return (
              <div key={index} className="ex-con-popover-item">
                <FormGroup>
                  <FormControlLabel
                    // className="ex-con-popover-item-text"
                    classes={{
                      label: "ex-con-popover-item-text",
                    }}
                    sx={{ fontSize: 12 }}
                    control={
                      <ICheckBox
                        onChangeCheckbox={(e) => onChangeCheck(e, item)}
                      />
                    }
                    label={item.title}
                  />
                </FormGroup>
                <div className="ex-con-popover-item-count">{item.count}</div>
              </div>
            );
          })}
        </div>
      ) : null}

      {openSecond ? (
        <div
          style={{
            boxSizing: "border-box",
            height: "auto",
            width: " auto",
            borderRadius: "8px",
            backgroundColor: " #FFFFFF",
            boxShadow: "0 5px 20px 0 rgba(36,36,36,0.25)",
            padding: "16px 8px 16px 8px",
            margin: "16px 0px 0px 110px",
            position: "absolute",
            zIndex: 890,
            left: "160px",
            top: "40px",
          }}
        >
          {exploreConfigPopoverData2.map((item, index) => {
            return (
              <div
                key={index}
                className="ex-con-popover-item"
                // onClick={closeMenu}
              >
                <div
                  className="ex-con-popover-item-text-two"
                  onClick={() => onOpenDialog(item)}
                >
                  <item.icon className="ex-con-popover-item-icon" />
                  {item.title}
                </div>
              </div>
            );
          })}
        </div>
      ) : null}

      {openDialog ? (
        <Dialog
          open={openDialog}
          fullWidth
          onClose={closeDialog}
          classes={{
            paper: "paper-ex-config",
            root: "root-ex-config",
          }}
        >
          <DialogTitle sx={{ padding: 0 }}>
            <Box className="s-popup-title-container">
              <Box className="s-popup-pca-container">
                <ArrowBack
                  onClick={onGoBackPopup}
                  className="s-popup-pca-icon"
                />

                <Typography className="s-popup-pca-title">
                  {cardData && cardData.titles}
                </Typography>
              </Box>

              <IconButton onClick={closeDialog} className={"s-popup-close"}>
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ padding: 0 }}>
            <Box sx={{ display: "flex", padding: "0px 30px 24px 30px" }}>
              <Box className="s-popup-second-content-card">
                <Typography className="s-popup-second-content-sub-title">
                  1. Select variables
                </Typography>
                <Typography className="s-popup-second-content-title">
                  {Columns && Columns.length} available variables
                </Typography>
                <Box className="s-popup-second-content-box">
                  <ISearchDefault width={"255px"} />
                  <Box className="all-columns-conatiner">
                    {Columns &&
                      Columns.map((column, index) => {
                        return (
                          <div
                            key={index}
                            className="all-columns-conatiner-item"
                            draggable="true"
                            onDragStart={(event) => onDragStartColumn(event)}
                            id={index}
                          >
                            {" "}
                            {column.inferred_type === "integer" ||
                            column.inferred_type === "number" ? (
                              <NumbersOutlined className="item-icon" />
                            ) : (
                              <LensBlur className="item-icon-other" />
                            )}
                            <div>{`${column.name}`}</div>
                          </div>
                        );
                      })}
                  </Box>
                  <Box className="s-popup-second-content-box-tips">
                    <TipsAndUpdates className="s-popup-second-content-box-tips-icon" />
                    <Typography className="s-popup-second-content-box-tips-text">
                      Use <b>Shift + Click</b> to select a range of variables.
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="s-popup-second-content-card">
                <Typography className="s-popup-second-content-sub-title">
                  2. Add variables
                </Typography>

                <Box className="s-popup-title-icons-container">
                  <div>
                    <Typography className="s-popup-second-content-title">
                      {droppedItems.length} selected variables
                    </Typography>
                  </div>
                  <div className="s-popup-title-icons">
                    <img
                      src={addIcon}
                      alt="add"
                      className="s-popup-title-icon"
                    />
                    <img
                      src={deleteIcon}
                      alt="delete"
                      className="s-popup-title-icon"
                    />
                  </div>
                </Box>

                <Box
                  className="s-popup-second-content-box-two"
                  onDrop={(e) => dropVariable(e)}
                  onDragOver={(event) => allowDrop(event)}
                >
                  {droppedItems.length === 0 ? (
                    <Typography className="s-popup-drag-text">
                      Drag variable(s) here...
                    </Typography>
                  ) : (
                    <Box className="s-popup-drag-items">
                      {droppedItems.map((item, index) => {
                        return (
                          <Box key={index + item} className="s-popup-drag-item">
                            <Typography className="s-popup-drag-item-title">
                              {item}
                            </Typography>
                            <img
                              src={deleteIcon}
                              className="s-popup-drag-item-icon"
                              alt="delete"
                              onClick={() => deleteVariable(index)}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{ padding: 0 }}>
            <Box className={"s-popup-actions"}>
              <IButton handleClick={closeDialog} type={"cancel"}>
                Cancel
              </IButton>
              <IButton
                type={
                  droppedItems.length === 0 ? "primary-disabled" : "primary"
                }
                handleClick={() => {
                  onCreateCard(cardData, droppedItems);
                  closeDialog();
                }}
              >
                Create card
              </IButton>
            </Box>
          </DialogActions>
        </Dialog>
      ) : null}
      <Box className="config-container-header">
        <Typography
          className="config-container-header-dropdown-text"
          component={"div"}
          onClick={openMenu}
        >
          Processors library
          <ArrowDropDownIcon />
        </Typography>
        <Typography className="config-container-header-sub-title">
          11 processors
        </Typography>
        <Box className="config-container-search">
          <Search className="config-container-search-input-icon" />
          <input
            className="config-container-search-input"
            placeholder="Search processors"
          />
        </Box>
        <Close
          className="config-container-close-icon"
          onClick={onCloseConfig}
        />
      </Box>
    </>
  );
}
