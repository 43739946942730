/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import IButton from "../../button";
import IInput from "../../input";
import "./dashboardPopup.scss";

export default function DialogeBox({
  open,
  handleClose,
  type,
  data,
  createstate,
  handleSubmit,
}) {
  const [canvasDetails, setcanvasDetails] = useState({
    canvas_name: "",
    canvas_description: "",
  });

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setcanvasDetails({
      ...canvasDetails,
      [name]: value,
    });
  };

  useEffect(() => {
    if (type === "update") {
      setcanvasDetails({
        canvas_name: data.canvas_name,
        canvas_description: data.canvas_description,
      });
    }
  }, []);

  return (
    <Dialog
      open={open}
      aria-labelledby="draggable-dialog-title"
      classes={{
        paper: "paper",
        root: "root",
      }}
    >
      <DialogTitle className={"dialogTitle"}>
        <Typography className={"dialogHead"}>
          {type === "create" ? "Add New" : "Update"} Workspace
        </Typography>
        <Typography className={"dialogSubHead"}>
          Enter the details below in order to{" "}
          {type === "create" ? "Add" : "Update"} your workspace.
        </Typography>
      </DialogTitle>
      <DialogContent classes={{ root: "dialogContent" }}>
        <IInput
          type="text"
          label="Name"
          value={canvasDetails.canvas_name}
          name="canvas_name"
          onChangeInput={onChangeInput}
          styleProps={{
            fontWeight: "bold",
            fontSize: "20px",
          }}
        />
        <IInput
          type="text"
          label="Description"
          value={canvasDetails.canvas_description}
          name="canvas_description"
          onChangeInput={onChangeInput}
        />
      </DialogContent>
      <DialogActions className={"dialogActions"}>
        <IButton type={"cancel"} handleClick={handleClose}>
          Cancel
        </IButton>
        <IButton
          handleClick={() => handleSubmit(canvasDetails)}
          type={
            canvasDetails.canvas_name.trim().length === 0
              ? "primary-disabled"
              : "primary"
          }
        >
          {type === "create" ? "Create" : "Update"} Workspace
        </IButton>
      </DialogActions>
    </Dialog>
  );
}
