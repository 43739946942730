import React, { useState, useEffect } from "react";
import Donut from "../graphs/pieChart";
import "./card.scss";

export default function Card({ title, pieData }) {
  const [showDonut, setShowDonut] = useState(true);

  useEffect(() => {
    pieData &&
      pieData.forEach((item) => {
        if (item.name === "total deployments") {
          setShowDonut(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="card-pipeline">
      <div className="title">{title}</div>
      <div className="elements">
        <div className="pie-chart">
          {showDonut ? <Donut pieData={pieData} /> : null}
        </div>
        <div className="card-counts">
          {pieData.map((item) => {
            return (
              <div
                key={item.name}
                className={
                  item.name === "total deployments" ? "" : "count-block"
                }
              >
                <div
                  className={
                    item.name === "total deployments" ? "large-value" : "values"
                  }
                >
                  {item.value}
                </div>
                <div className="names">
                  {item.name === "total deployments" ? "" : item.name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
