import React from "react";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  border: " 1px solid #E6E6E6",
  borderRadius: " 8px",
  backgroundColor: "#FFFFFF",
  marginLeft: "0px !important",
  height: "50px",
  width: "100%",
  display: "flex",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    backgroundColor: "inherit",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
    caretColor: "#3c3996",
    fontWeight: "bold",
    fontFamily: "Product Sans",
  },
}));

export default function SearchBox({ placeholder }) {
  return (
    <Box
      component="div"
      sx={{ maxWidth: "400px", margin: "10px", backgroundColor: "inherit" }}
    >
      <Search>
        <SearchIconWrapper>
          <SearchIcon style={{ color: "#8B97A2" }} />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder={`${placeholder}`}
          inputProps={{ "aria-label": "search" }}
        />
      </Search>
    </Box>
  );
}
