import ScienceIcon from "@mui/icons-material/Science";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FlagIcon from "@mui/icons-material/Flag";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CropFreeIcon from "@mui/icons-material/CropFree";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import SuperscriptIcon from "@mui/icons-material/Superscript";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CloseFullscreenOutlinedIcon from "@mui/icons-material/CloseFullscreenOutlined";

export const VgetGraphData = {
  x: "region",
  value: "Average of Total Revenue",
  group_by: "country",
  data: [
    {
      region: "North America",
      Canada: 275,
      USA: 350,
      Mexico: 700,
    },
    {
      region: "Europe",
      Germany: 700,
      France: 600,
      England: 250,
    },
    {
      region: "South America",
      Brazil: 965,
      Cuba: 3520,
      Argentina: 230,
    },
  ],
};
export const VgetGraphData2 = {
  x: "region",
  value: "Average of Total Revenue",
  group_by: "country",
  data: [
    {
      region: "North America",
      countryName1: "Canada",
      countryData1: 1250,
      countryName2: "USA",
      countryData2: 950,
      countryName3: "Mexico",
      countryData3: 700,
    },
    {
      region: "Europe",
      countryName1: "Germany",
      countryData1: 1700,
      countryName2: "France",
      countryData2: 2600,
      countryName3: "England",
      countryData3: 3250,
    },
    {
      region: "South America",
      countryName1: "Brazil",
      countryData1: 1965,
      countryName2: "Cuba",
      countryData2: 3520,
      countryName3: "Argentina",
      countryData3: 2230,
    },
  ],
};
export const VfilterOptions = [
  {
    title: "Filters",
    type: "filters",
  },
  {
    title: "Display",
    type: "display",
  },
  {
    title: "Color",
    type: "color",
  },
  {
    title: "Animation",
    type: "animation",
  },
  {
    title: "Subcharts",
    type: "sub charts",
  },
  {
    title: "Tooltip",
    type: "tooltip",
  },
  {
    title: "My Saved Charts",

    data: [
      {
        title: "chart 1",
        id: 1,
      },
      {
        title: "chart 2",
        id: 2,
      },
    ],
  },
];

export const exploreConfigPopoverData1 = [
  {
    title: "Filter data",
    count: 13,
  },
  {
    title: "Data cleansing",
    count: 11,
  },
  {
    title: "Strings",
    count: 11,
  },
  {
    title: "Math / Numbers",
    count: 16,
  },
  {
    title: "Split / Extract",
    count: 7,
  },
  {
    title: "Web logs",
    count: 6,
  },
  {
    title: "Dates",
    count: 10,
  },
  {
    title: "Enrich",
    count: 11,
  },
  {
    title: "Reshaping",
    count: 16,
  },
  {
    title: "Code",
    count: 4,
  },
];

export const exploreConfigPopoverData2 = [
  {
    title: "Normalize measure",
    icon: ScienceIcon,
  },
  {
    title: "Filter invalid rows / cells",
    icon: FilterAltIcon,
  },
  {
    title: "Flag invalid rows",
    icon: FlagIcon,
  },
  {
    title: "Remove rows where cell is empty",
    icon: DeleteOutlineIcon,
  },
  {
    title: "Fill empty cells with fixed value",
    icon: CropFreeIcon,
  },
  {
    title: "Split invalid cells into another column",
    icon: VerticalSplitIcon,
  },
  {
    title: "Fill empty cells with previous / next value",
    icon: CropFreeIcon,
  },
  {
    title: "Convert number formats",
    icon: SuperscriptIcon,
  },
  {
    title: "Impute with computed value ",
    icon: CircleOutlinedIcon,
  },
  {
    title: "Group long-tail values",
    icon: CloseFullscreenOutlinedIcon,
  },
];
