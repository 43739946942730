import React, { useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import "./workspacePopup.scss";

export default function WorkspacePopup(props) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        style: { borderRadius: 8 },
      }}
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
      style={{ top: "15%", left: "20%" }}
    >
      <DialogContent>
        <div className="popup_root">
          <div className="title_area">
            <div className="model_title">{props.title}</div>
            <div className="model_subtitle">{props.subtitle}</div>
          </div>
          <div className={"model_body"}>
            <div className="body-area">
              <img src={props.body} alt="Empty Space Popup" />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
