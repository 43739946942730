import React from "react";
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
} from "recharts";

import { VgetGraphData2 } from "../../../../utils/dummyData";

export default function LineGraph() {
  return (
    <>
      <ResponsiveContainer width="90%" height="100%">
        <LineChart data={VgetGraphData2.data} barCategoryGap="1%">
          <CartesianGrid strokeDasharray="3" />
          <XAxis dataKey="region" />
          <YAxis />
          <Tooltip />
          <Legend iconType="circle" />
          <Line
            dot={false}
            dataKey="countryData1"
            fill="#DBE2E7"
            type="monotone"
          />
          <Line
            dot={false}
            dataKey="countryData2"
            fill="#32BCD0"
            type="monotone"
          />
          <Line
            dot={false}
            dataKey="countryData3"
            fill="#3C3996"
            type="monotone"
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}
