import request from "../redux/services/request";

// get all Canvas api
export const getAllCanvas = (user_id) => {
  return request({
    url: `/canvas/find_by_user_id/${user_id}`,
    method: "GET",
  });
};

// create new canvas api
export const createNewCanvas = (data) => {
  return request({
    url: `/canvas`,
    method: "POST",
    data,
  });
};
// update a canvas
export const updateCanvas = (data, canvas_id) => {
  return request({
    url: `/canvas/${canvas_id}`,
    method: "PUT",
    data,
  });
};
// delete a canvas api
export const deleteCanvas = (data, canvas_id) => {
  return request({
    url: `/canvas/${canvas_id}`,
    method: "DELETE",
    data,
  });
};
// get all Nodes of canvas
export const getAllCanvasNodes = (canvas_id) => {
  return request({
    url: `/canvas/${canvas_id}`,
    method: "GET",
  });
};
