import {
  GET_ALL_CANVAS_ERROR,
  GET_ALL_CANVAS_PENDING,
  GET_ALL_CANVAS_SUCCESS,
  CREATE_CANVAS_ERROR,
  CREATE_CANVAS_PENDING,
  CREATE_CANVAS_SUCCESS,
  UPDATE_CANVAS_ERROR,
  UPDATE_CANVAS_PENDING,
  UPDATE_CANVAS_SUCCESS,
  DELETE_CANVAS_SUCCESS,
  DELETE_CANVAS_PENDING,
  DELETE_CANVAS_ERROR,
  GET_ALL_CANVAS_NODES_ERROR,
  GET_ALL_CANVAS_NODES_PENDING,
  GET_ALL_CANVAS_NODES_SUCCESS,
} from "../type";

const initialState = {
  allCanvas: [],
  loading: null,
  error: null,
  message: "",
  newCanvas: {},
  updateCanvas: {},
  allNodes: [],
};

// fetch all canvas
export const getAllCanvasReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_ALL_CANVAS_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_CANVAS_SUCCESS:
      return {
        ...state,
        loading: false,
        allCanvas: payload,
        error: null,
      };
    case GET_ALL_CANVAS_ERROR:
      return {
        ...state,
        loading: false,
        allCanvas: [],
        error: payload,
      };

    default:
      return state;
  }
};

//create new canvas
export const createNewCanvasReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case CREATE_CANVAS_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_CANVAS_SUCCESS:
      return {
        ...state,
        loading: false,
        newCanvas: payload,
        error: null,
      };
    case CREATE_CANVAS_ERROR:
      return {
        ...state,
        loading: false,
        newCanvas: {},
        error: payload,
      };
    default:
      return state;
  }
};

//update a canvas
export const updateCanvasReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case UPDATE_CANVAS_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_CANVAS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateCanvas: payload,
        error: null,
      };
    case UPDATE_CANVAS_ERROR:
      return {
        ...state,
        loading: false,
        updateCanvas: {},
        error: payload,
      };

    default:
      return state;
  }
};

//delete a canvas
export const deleteCanvasReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case DELETE_CANVAS_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_CANVAS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "DELETED SUCCESSFULLY",
        error: null,
      };
    case DELETE_CANVAS_ERROR:
      return {
        ...state,
        loading: false,
        message: "ERROR",
        error: payload,
      };

    default:
      return state;
  }
};

//get all nodes
export const getAllCanvasNodesReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_ALL_CANVAS_NODES_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_CANVAS_NODES_SUCCESS:
      return {
        ...state,
        loading: false,
        allNodes: payload,
        error: null,
      };
    case GET_ALL_CANVAS_NODES_ERROR:
      return {
        ...state,
        loading: false,
        allNodes: [],
        error: payload,
      };

    default:
      return state;
  }
};
