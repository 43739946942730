/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactFlow, {
  addEdge,
  ReactFlowProvider,
  Controls,
  Background,
  removeElements,
} from "react-flow-renderer";

import { debounce } from "lodash";
import WorkFlowSidebar from "../../commonComponents/workFlowSidebar";
import DataSourceNode from "../../themeComponents/nodes/dataSourceNode/index";
import WorkspacePopup from "../../themeComponents/popups/workspacePopup";
import canvasBody from "../../../assets/canvasBody.png";
import Loader from "../../themeComponents/loader";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAllNodeGroupTypeAction } from "../../../redux/actions/nodeGroupAction";
import { useEffect } from "react";
import { getAllCanvasNodesAction } from "../../../redux/actions/canvasAction";
import {
  addNodeAction,
  deleteNodeAction,
  getCofigureDatasetAction,
  updateNodeAction,
} from "../../../redux/actions/nodeAction";
import "./workflow.scss";
import IPopup from "../../themeComponents/popup model";
import ConfigurePopup from "../../themeComponents/popups/configurePopup/Index";

const WorkFlow = ({ className, LoaderData }) => {
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [elements, setElements] = useState([]);
  const [, setNodeType] = useState("");
  const [newUser, setNewUser] = useState(false);
  const [callApi, setCallApi] = useState(false);
  const [, setShowTables] = useState(false);
  const [deleteNodeState, setDeleteNodeState] = useState(false);
  const [configureState, setConfigureState] = useState(false);
  const [selectedDataset, setSelectedDataset] = useState({});
  const [selectedEleemnts, setSelectedElements] = useState([]);

  const dispatch = useDispatch();
  let navigate = useNavigate();
  let paramsMatch = useParams();

  const apicallFun = () => {
    setCallApi(!callApi);
  };

  const onLoad = (_reactFlowInstance) =>
    setReactFlowInstance(_reactFlowInstance);

  const allNoedsofCanvas = useSelector(
    (state) => state.getAllCanvasNodesReducer.allNodes
  );

  const NodeGroupLoader = useSelector(
    (state) => state.getAllNodeGroupTypesReducer.loading
  );

  const addedNodeData = useSelector((state) => state.addNodeReducer.newNode);

  const AddedNodeError = useSelector((state) => state.addNodeReducer.error);

  const UpdatedNodeError = useSelector(
    (state) => state.updateNodeReducer.error
  );
  const DeletedNodeError = useSelector(
    (state) => state.deleteNodeReducer.error
  );

  useEffect(() => {
    let canvas_id = paramsMatch.canvasId;
    dispatch(getAllCanvasNodesAction(canvas_id));
  }, [AddedNodeError, UpdatedNodeError, DeletedNodeError]);

  useEffect(() => {
    setElements((elements) => [
      ...elements,
      {
        canvas_id: addedNodeData.canvas_id,
        children: addedNodeData.children,
        last_updated_at_ms: addedNodeData.last_updated_at_ms,
        node_config_payload: addedNodeData.node_config_payload,
        id: addedNodeData.node_id,
        type: addedNodeData.node_group_type,
        name: addedNodeData.node_type,
        parents: addedNodeData.parents,
        data: addedNodeData,
        position: { x: addedNodeData.x, y: addedNodeData.y },
      },
    ]);
  }, [addedNodeData]);

  const nodeGroupTypes = useSelector(
    (state) => state.getAllNodeGroupTypesReducer.allNodeGroupTypes
  );

  const updatedNode = useSelector(
    (state) => state.updateNodeReducer.updateNode
  );
  const datasets = useSelector(
    (state) => state.getCofigureDatasetReducer.datasets
  );

  useEffect(() => {
    let arr = elements;
    const lastIndex =
      updatedNode.node_id &&
      arr.findIndex((node) => {
        return node.id === updatedNode.node_id;
      });
    const prevNode = arr[lastIndex];
    if (prevNode && updatedNode) {
      prevNode.canvas_id = updatedNode.canvas_id;
      prevNode.children = updatedNode.children;
      // prevNode.data = updatedNode.data
      prevNode.id = updatedNode.node_id;
      prevNode.node_config_payload = updatedNode.node_config_payload;
      prevNode.parents = updatedNode.parents;
      prevNode.position.x = updatedNode.x;
      prevNode.position.y = updatedNode.y;
      prevNode.type = updatedNode.node_group_type;
      prevNode.name = updatedNode.node_type;
    }
  }, [updatedNode]);

  useEffect(() => {
    allNoedsofCanvas.nodes && allNoedsofCanvas.nodes.length === 0
      ? setNewUser(true)
      : setNewUser(false);
    const initialElements = [];
    allNoedsofCanvas.nodes &&
      allNoedsofCanvas.nodes.map((node) => {
        initialElements.push({
          canvas_id: node.canvas_id,
          children: node.children,
          last_updated_at_ms: node.last_updated_at_ms,
          node_config_payload: node.node_config_payload,
          id: node.node_id,
          type: node.node_group_type,
          name: node.node_type,
          parents: node.parents,
          data: node,
          position: { x: node.x, y: node.y },
        });
        if (node.children.length !== 0) {
          node.children.map((child) => {
            initialElements.push({
              id: `edge-${node.node_id}-${child}`,
              source: node.node_id,
              target: child,
              type: "smoothstep",
              arrowHeadType: "arrowclosed",
            });
          });
        }
      });
    setElements(initialElements);
  }, [allNoedsofCanvas]);

  //getting all nodeGroupType && showing new user popup if canvas empty
  useEffect(() => {
    dispatch(getAllNodeGroupTypeAction());
    let canvas_id = paramsMatch.canvasId;
    dispatch(getAllCanvasNodesAction(canvas_id));
    if (window.location.pathname.includes("/explore")) {
      setShowTables(true);
    }
  }, [callApi]);

  useEffect(() => {
    return () => {
      window.localStorage.removeItem("sortOptions");
    };
  }, []);

  useEffect(() => {
    if (!window.location.pathname.includes("explore")) {
      setShowTables(false);
    }
  }, [window.location.pathname]);

  const onConnect = (params) => {
    setElements((els) =>
      addEdge(
        { ...params, arrowHeadType: "arrowclosed", type: "smoothstep" },
        els
      )
    );
    const node_id1 = params.source;
    const node_id2 = params.target;
    let ExistingChilds = [];
    let ExistingParents = [];
    elements.map((ele) => {
      if (ele.id === node_id1) {
        ExistingChilds = ele.children;
      }
      return ele.id === node_id2 ? (ExistingParents = ele.parents) : null;
    });
    ExistingChilds.push(node_id2);
    ExistingParents.push(node_id1);
    const data1 = {
      children: ExistingChilds,
    };
    const data2 = {
      parents: ExistingParents,
    };
    dispatch(updateNodeAction(node_id1, data1));
    dispatch(updateNodeAction(node_id2, data2));
  };

  const elementRemoverFunction = (elementsToRemove) => {
    const LatestEle = removeElements(elementsToRemove, elements);
    setElements(LatestEle);
    let NodeToRemove = [];
    elementsToRemove.map((item) => {
      if (Object.keys(item).includes("position")) {
        NodeToRemove.push(item);
      }
    });
    NodeToRemove.map((element) => {
      elements &&
        elements.map((item) => {
          if (item.id === element.id) {
            dispatch(deleteNodeAction(item.id));
            //parents update
            item.parents &&
              item.parents.map((parent) => {
                const filteredParent =
                  LatestEle &&
                  LatestEle.filter((item) => {
                    return item.id === parent;
                  });
                if (filteredParent.length > 0) {
                  const arr = filteredParent[0].children.filter(
                    (e) => e !== item.id
                  );
                  const data = {
                    children: arr,
                  };
                  dispatch(updateNodeAction(filteredParent[0].id, data));
                }
              });
            //children update
            item.children &&
              item.children.map((child) => {
                const filteredChildren =
                  LatestEle &&
                  LatestEle.filter((item) => {
                    return item.id === child;
                  });
                if (filteredChildren.length > 0) {
                  const arr = filteredChildren[0].parents.filter(
                    (e) => e !== item.id
                  );
                  const data = {
                    parents: arr,
                  };
                  dispatch(updateNodeAction(filteredChildren[0].id, data));
                }
              });
          }
        });
    });
  };

  const onElementsRemove = () => {
    setDeleteNodeState(true);
  };

  const getDebouncedByType = (func, wait, options) => {
    const memory = {};
    return (...args) => {
      const [params] = args;
      if (typeof memory[params] === "function") {
        return memory[params](...args);
      }
      memory[params] = debounce(func, wait, { ...options, leading: true });
      return memory[params](...args);
    };
  };

  const updateNodeCall = (node_id, data) => {
    dispatch(updateNodeAction(node_id, data));
  };

  const handleDebounce = getDebouncedByType(updateNodeCall, 6000);

  const onNodeDragStop = (event, node) => {
    const node_id = node.id;
    const data = {
      x: node.position.x,
      y: node.position.y,
    };
    handleDebounce(node_id, data);
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };
  const onDrop = (event) => {
    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const NodeObj = JSON.parse(
      event.dataTransfer.getData("application/reactflow")
    );
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });
    const type = NodeObj.groupName;
    const name = NodeObj.nodeName;
    setNodeType(name);
    let canvas_id = window.location.pathname.split("/").pop();
    dispatch(
      addNodeAction({
        canvas_id: canvas_id,
        node_group_type: type,
        node_type: name,
        x: position.x,
        y: position.y,
      })
    );
  };

  const onNodeDoubleClick = (event, node) => {
    navigate(`/app/workspace/${paramsMatch.canvasId}/node/${node.id}/explore`);
    setShowTables(true);
    if (node && node.data && node.data.node_group_type) {
      window.localStorage.setItem("node_group_type", node.data.node_group_type);
    }
  };

  const deleteNodePopupFunction = () => {
    setDeleteNodeState(true);
  };
  const closedeleteNodePopupFunction = () => {
    setDeleteNodeState(false);
  };
  const deleteNodeFunction = (x) => {
    setDeleteNodeState(false);

    elementRemoverFunction(selectedEleemnts);
  };
  const closeConfigure = () => {
    setConfigureState(false);
  };
  const configureLocalFile = () => {
    dispatch(getCofigureDatasetAction("tawfeeq123"));
    setConfigureState(true);
  };

  const submitConfigDataset = () => {
    let node_id = window.localStorage.getItem("node_id");
    if (
      node_id &&
      node_id !== undefined &&
      selectedDataset &&
      Object.keys(selectedDataset).length > 0
    ) {
      dispatch(
        updateNodeAction(node_id, { dataset_id: selectedDataset.dataset_id })
      );
    }
    setConfigureState(false);
    window.localStorage.removeItem("node_id");
    setSelectedDataset({});
  };

  return (
    <>
      <div className={`workflow__wrapper ${className}`}>
        {NodeGroupLoader ? <Loader open={NodeGroupLoader} /> : null}
        <ReactFlowProvider>
          <div className={`workflow_sidebar ${className}`}>
            <WorkFlowSidebar
              nodeGroupTypes={nodeGroupTypes}
              apicallFun={apicallFun}
            />
          </div>
          <div
            className={`workflow_canvas ${className}`}
            ref={reactFlowWrapper}
          >
            {newUser === true ? (
              <>
                <WorkspacePopup
                  title={"Workspace Empty"}
                  subtitle={
                    "Click and drag the icons on the left to begin developing your workflow."
                  }
                  body={canvasBody}
                />
              </>
            ) : null}
            {deleteNodeState ? (
              <>
                <IPopup
                  title={"Confirm deletion"}
                  subtitle={"Are you sure you want to do delete node?"}
                  DialogeContainer={"DialogeContainer"}
                  titleClass={"TitleClass"}
                  subtitleClass={"subtitleClass"}
                  dialogAction={"dialogAction"}
                  deleteNodeState={deleteNodeState}
                  closedeleteNodePopupFunction={closedeleteNodePopupFunction}
                  actionButtons={[
                    {
                      type: "cancel",
                      name: "Cancel",
                      customClass: "cancelWoBorder",
                    },
                    {
                      type: "primary",
                      name: "Delete",
                      function: deleteNodeFunction,
                    },
                  ]}
                />
              </>
            ) : null}
            {configureState ? (
              <ConfigurePopup
                open={configureState}
                selectedDataset={selectedDataset}
                setSelectedDataset={setSelectedDataset}
                handleClose={closeConfigure}
                submitConfigDataset={submitConfigDataset}
                datasets={datasets}
              />
            ) : null}
            <ReactFlow
              elements={elements}
              deleteKeyCode={46}
              onLoad={onLoad}
              arrowHeadColor="#3C3996"
              nodeTypes={{
                DataNode: (data) => (
                  <DataSourceNode
                    data={data}
                    deleteNodePopupFunction={deleteNodePopupFunction}
                    setSelectedElements={setSelectedElements}
                    configureLocalFile={configureLocalFile}
                  />
                ),
                TransformNode: (data) => (
                  <DataSourceNode
                    data={data}
                    deleteNodePopupFunction={deleteNodePopupFunction}
                    setSelectedElements={setSelectedElements}
                    configureLocalFile={configureLocalFile}
                  />
                ),
                ModelNode: (data) => (
                  <DataSourceNode
                    data={data}
                    deleteNodePopupFunction={deleteNodePopupFunction}
                    setSelectedElements={setSelectedElements}
                    configureLocalFile={configureLocalFile}
                  />
                ),
              }}
              onDragOver={onDragOver}
              onDrop={onDrop}
              onConnect={onConnect}
              onElementsRemove={onElementsRemove}
              onNodeDragStop={onNodeDragStop}
              onNodeDoubleClick={onNodeDoubleClick}
            >
              <Background variant="dots" color="#8B97A2" gap={40} size={1} />
              <Controls />
            </ReactFlow>
          </div>
        </ReactFlowProvider>
      </div>
    </>
  );
};

export default WorkFlow;
