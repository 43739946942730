import React from "react";
import { Search } from "@mui/icons-material";
import "./defaultSearch.scss";

export default function ISearchDefault({ width }) {
  return (
    <div className={"visual-search-container"} style={{ width: width }}>
      <input placeholder="Search" className={"visual-search-input"} />
      <Search className={"visual-search-input-icon"} />
    </div>
  );
}
