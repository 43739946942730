import React from "react";
import {
  ResponsiveContainer,
  Bar,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
} from "recharts";

import { VgetGraphData } from "../../../../utils/dummyData";

export default function BarGraph() {
  return (
    <>
      <ResponsiveContainer width="90%" height="100%">
        <BarChart
          data={VgetGraphData.data}
          margin={{
            top: 50,
            right: 50,
            left: 20,
            bottom: 10,
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray="0" opacity={0.5} />{" "}
          {VgetGraphData.data.map((item, idx) => {
            return Object.entries(item).map(([key, value], id) => {
              return (
                <>
                  {id !== 0 ? (
                    <XAxis
                      dataKey={`${key}`}
                      minTickGap={0}
                      tick={{
                        fill: "#8B97A2",
                        opacity: "0.5",
                      }}
                      stroke="#D8D8D8"
                    >
                      <Label
                        value={`${VgetGraphData.x}`}
                        offset={10}
                        position="right"
                        style={{
                          fontSize: "14px",
                          fill: "#8B97A2",
                          opacity: "0.5",
                        }}
                        dy={-18}
                      />
                    </XAxis>
                  ) : null}
                </>
              );
            });
          })}
          <YAxis
            yAxisId="left"
            offset={10}
            tick={{ fill: "#8B97A2" }}
            orientation="left"
            stroke="#D8D8D8"
            tickLine={false}
          >
            <Label
              value={`${VgetGraphData.value}`}
              offset={14}
              width={120}
              position="top"
              style={{
                fontSize: "14px",
                fill: "#8B97A2",
              }}
              dx={30}
              dy={-10}
            />
          </YAxis>
          <Tooltip cursor={false} />
          {VgetGraphData.data.map((item, idx) => {
            return Object.entries(item).map(([key, value], id) => {
              return (
                <>
                  {id !== 0 ? (
                    <Bar
                      yAxisId="left"
                      dataKey={`${key}`}
                      fill={
                        id === 1
                          ? "#DBE2E7"
                          : id === 2
                          ? "#32BCD0"
                          : id === 3
                          ? "#6063FF"
                          : "#3C3996"
                      }
                      padding={0}
                      radius={4}
                    />
                  ) : null}
                </>
              );
            });
          })}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}
