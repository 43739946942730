import React from "react";
import Avatar from "@mui/material/Avatar";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import { ArrowBack, KeyboardArrowDownOutlined } from "@mui/icons-material";
import IosShareIcon from "@mui/icons-material/IosShare";
import IButton from "../../button";
import logo from "../../../../assets/logo.png";
import man from "../../../../assets/man.png";
import "./Navbar.scss";
import FileUploadPopup from "../../popups/uploadFilePopup";

function NavBar({ canvasTitle, onBack, LoaderData }) {
  return (
    <div className={"Navbar_wraper"}>
      <div className={`button_logo`}>
        <div className={"navbar_backbutton"} onClick={() => onBack(false)}>
          <ArrowBack className="left-arrow" />
        </div>
        <div className={"navbar_logo"}>
          <img src={logo} alt="logo" />
        </div>
      </div>
      <div className={`right_section`}>
        <div className={`navbar_title`}>
          {canvasTitle}
          {LoaderData !== null ? (
            <div className="canvasSaving">Saving...</div>
          ) : null}
        </div>

        <div className={`button_avatar`}>
          <div className="navbar_button">
            {/* <IButton type={"neutral"}>
              Canvas
              <RemoveRedEyeOutlinedIcon style={{ marginLeft: "5px" }} />
            </IButton> */}
            <FileUploadPopup />
            <IButton type={"secondary"}>
              <ChevronRightIcon
                style={{ fill: "#FFFFFF", marginRight: "5px" }}
              />
              Test
              <KeyboardArrowDownOutlined
                style={{ fill: "#FFFFFF", marginLeft: "5px" }}
              />
            </IButton>
            <IButton
              type={"primary-disabled"}
              customClass={"button-text-color"}
            >
              <RocketLaunchOutlinedIcon
                style={{ fill: "#FFFFFF", marginRight: "5px" }}
              />
              Deploy
            </IButton>
            <div className="navbar_button_share">
              <IosShareIcon />
            </div>
          </div>
          <Avatar
            alt="Remy Sharp"
            src={man}
            sx={{ width: 50, height: 50, float: "right" }}
          />
        </div>
      </div>
    </div>
  );
}
export default NavBar;
