import React from "react";
import { useSelector } from "react-redux";
import { Outlet, useRoutes } from "react-router-dom";
import WorkFlow from "../pageComponents/workFlow";
import Login from "../pageComponents/login";
import DashBoard from "../pageComponents/dashboard";
import ExploreTab from "../pageComponents/exploreTab";
import VisualizationDashboard from "../pageComponents/visualization";
import WorkSpaceLayout from "../layout/workspaceLayout";
import VisualizationCharts from "../themeComponents/graphs/visualizationCharts";
import Statistics from "../pageComponents/statistics";

const AllRoutes = () => {
  const LoaderData = useSelector((state) => state.loaderReducer.isLoading);

  const allRoutes = useRoutes([
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/app/dashboard",
      element: <DashBoard />,
    },
    {
      element: (
        <>
          <WorkSpaceLayout />
          <Outlet />
        </>
      ),
      children: [
        {
          path: "/app/workspace/:canvasId",
          element: <WorkFlow LoaderData={LoaderData} />,
        },
        {
          path: "/app/workspace/:canvasId/node/:nodeId/explore",
          element: <ExploreTab />,
        },
        {
          element: (
            <>
              <VisualizationDashboard />
            </>
          ),
          children: [
            {
              path: "/app/workspace/:canvasId/node/:nodeId/visualization",
              element: <Outlet />,
            },
            {
              path: "/app/workspace/:canvasId/node/:nodeId/visualization/:visId",
              element: <VisualizationCharts />,
            },
          ],
        },
        {
          path: "/app/workspace/:canvasId/node/:nodeId/statistics",
          element: <Statistics />,
        },
      ],
    },
  ]);

  return allRoutes;
};

export default AllRoutes;
