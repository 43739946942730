import { makeStyles } from "@material-ui/core";
import { TextField } from "@mui/material";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./input.scss";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#ffffff",
    width: "100%",
    "& .MuiFormLabel-root": {
      color: "#8B97A2",
      fontFamily: "Product Sans",
      letterSpacing: 0,
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    "& .MuiFilledInput-root": {
      background: "#ffffff",
      borderRadius: "8px !important",
    },
  },
  input: {
    border: (styleProps) =>
      styleProps.border ? styleProps.border : "1px solid #E6E6E6",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px !important",
    color: "#1E2429",
    fontfamily: "Product Sans",
    fontSize: (styleProps) =>
      styleProps.fontSize ? styleProps.fontSize : "16px",
    letterSpacing: "0.35px",
    lineHeight: (styleProps) =>
      styleProps.lineHeight ? styleProps.lineHeight : "19px",
    paddingLeft: "20px",
    paddingRight: "10px",
    caretColor: "#3c3996",
    fontWeight: (styleProps) =>
      styleProps.fontWeight ? styleProps.fontWeight : "normal",
  },
}));
export default function IInput({
  label,
  type,
  value,
  name,
  onChangeInput,
  disabled,
  placeholder,
  styleProps,
  isSelect,
  onClick,
}) {
  const classes = useStyles(styleProps);
  const iInputContainerStyle = {
    position: "relative",
    marginBottom: "20px",
    cursor: isSelect ? "pointer" : "default",
    width: styleProps && styleProps.width ? styleProps.width : "auto",
  };
  return (
    <div style={iInputContainerStyle} onClick={isSelect ? onClick : null}>
      <TextField
        label={`${label}`}
        variant="filled"
        InputProps={{
          disableUnderline: true,
          classes: {
            input: classes.input,
          },
        }}
        disabled={disabled}
        type={type}
        name={name}
        value={value}
        onChange={(e) => onChangeInput(e)}
        className={classes.root}
        placeholder={placeholder}
        size="medium"
      />
      {isSelect ? (
        <KeyboardArrowDownIcon className="i-input-arrow-down" />
      ) : null}
    </div>
  );
}
