import { Add, Reorder, Search } from "@mui/icons-material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Suggestions from "../../../../assets/ExConfigSidebarSuggestions.png";
import IButton from "../../button";
import ICheckBox from "../../checkBox";
import "./exploreConfigSidebar.scss";
import pencilBlue from "../../../../assets/icons/pencilBlue.svg";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import deleteIcon from "../../../../assets/icons/deleteWhite.svg";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

export default function ExploreConfigSidebar({ cardData }) {
  return (
    <Box className="config-container-sidebar">
      {cardData.length === 0 ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "Center",
            flexDirection: "column",
          }}
        >
          <Typography className="config-container-sidebar-title">
            No step yet in your script.
          </Typography>
          <Typography className="config-container-sidebar-sub-title">
            You can add steps either by:
          </Typography>
          <img
            src={Suggestions}
            alt="img"
            className="config-container-sidebar-img"
          />
          <IButton type={"primary"} customClass="config-container-sidebar-btn">
            <Add className="config-container-sidebar-btn-icon" />
            Add a new step
          </IButton>
        </div>
      ) : (
        <>
          <Box className="ex-sidebar-search-container">
            <ICheckBox onChangeCheckbox={(e) => console.log(e)} />
            <ArrowDropDown className={"ex-sidebar-search-checkbox-icon"} />
            <Box className="config-container-search">
              <Search className="config-container-search-input-icon" />
              <input
                className="config-container-search-input"
                placeholder="Search processors"
              />
            </Box>
          </Box>
          <Box className="ex-sidebar-cards-container">
            {cardData.map((item, index) => {
              return (
                <div key={index} className="ex-sidebar-card">
                  <ICheckBox onChangeCheckbox={(e) => console.log(e)} />
                  <div
                    style={{
                      height: "100%",
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="ex-sidebar-card-text">
                      {item.titles}
                      {item.variables.map((i, idx) => {
                        return (
                          <span
                            style={{ color: "#3c3996", marginLeft: "4px" }}
                            key={idx}
                          >
                            {i}
                          </span>
                        );
                      })}
                    </div>
                    <div className="ex-sidebar-card-icons">
                      <img src={pencilBlue} alt={"edit"} />
                      <div className="ex-sidebar-card-sub-icons">
                        <RemoveRedEyeOutlinedIcon className="ex-sidebar-card-icon" />
                        <PowerSettingsNewOutlinedIcon className="ex-sidebar-card-icon" />
                        <img
                          src={deleteIcon}
                          alt={"delete"}
                          className="ex-sidebar-card-icon"
                        />
                        <MoreHorizOutlinedIcon className="ex-sidebar-card-icon" />
                      </div>
                    </div>
                  </div>
                  <Reorder className="ex-sidebar-card-right-icon" />
                </div>
              );
            })}
          </Box>
        </>
      )}

      {cardData.length === 0 ? null : (
        <>
          <IButton type={"primary"} customClass="config-container-sidebar-btn">
            <Add className="config-container-sidebar-btn-icon" />
            Add a new step
          </IButton>
          <IButton
            type={"default"}
            customClass="config-container-sidebar-btn-two"
          >
            <Add className="config-container-sidebar-btn-icon" />
            Add a group
          </IButton>
        </>
      )}
    </Box>
  );
}
