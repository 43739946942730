/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/material";
import CustomTable from "../../themeComponents/table/exploreTable";
import {
  getDatabynodeIdAction,
  getColumnDatabynodeIdAction,
  fetchNodeDetailsAction,
} from "../../../redux/actions/nodeAction";
import "./exploreTab.scss";
import { memo } from "react";
import WorkSpaceHeader from "../../themeComponents/header/workSpaceHeader";
import { useParams } from "react-router-dom";
import ExploreConfigSidebar from "../../themeComponents/sidebar/exploreConfigSidebar";
import ExploreConfigHeader from "../../themeComponents/header/exploreConfigHeader";

var ls = 0;

function Explore() {
  const dispatch = useDispatch();
  const [offset, setOffSet] = useState(0);
  const [limit, setLimit] = useState(20);
  const params = useParams();
  const [showConfig, setShowConfig] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [cards, setCards] = useState([]);
  const columnsList = useSelector(
    (state) => state.getColumnDatabynodeIdReducer.columnList
  );

  const columnsData = useSelector(
    (state) => state.getDatabynodeIdReducer.columnData
  );
  useEffect(() => {
    const nodeId = params.nodeId;
    dispatch(getColumnDatabynodeIdAction(nodeId));
    dispatch(fetchNodeDetailsAction(nodeId));
    if (window.localStorage.getItem("node_group_type") === "TransformNode") {
      setShowConfig(true);
      setShowSettings(true);
    } else {
      setShowConfig(false);
      setShowSettings(false);
    }
    return () => {
      localStorage.removeItem("sortOptions");
    };
  }, []);

  // useEffect(() => {
  //   if (
  //     fetchNodeDeatils &&
  //     fetchNodeDeatils.node_group_type === "TransformNode"
  //   ) {
  //     setShowConfig(true);
  //     setShowSettings(true);
  //   } else {
  //     setShowConfig(false);
  //     setShowSettings(false);
  //   }
  // }, [fetchNodeDeatils]);

  useEffect(() => {
    const nodeId = params.nodeId;

    let sortProperties = [];
    if (
      columnsData &&
      columnsData.sort_columns &&
      columnsData.sort_columns.length > 0
    ) {
      for (let i = 0; i < columnsData.sort_columns.length; i++) {
        sortProperties.push({
          name: columnsData.sort_columns[i],
          ascending: columnsData.sort_order[i],
        });
      }
    }

    dispatch(
      getDatabynodeIdAction(
        { sort_properties: sortProperties },
        nodeId,
        offset,
        limit
      )
    );
    return () => {
      sortProperties = [];
    };
  }, [offset, limit]);

  const hanldeTableScroll = (e) => {
    let st = e.target.scrollTop;
    let load =
      e.target.scrollTop > e.target.scrollHeight - e.target.offsetHeight;

    if (load === true) {
      ls = e.target.scrollTop;
      // setTimeout(() => {
      if (limit < 20) {
        setOffSet(0);
        setLimit(limit + 20);
      } else if (limit >= 20) {
        // console.log("Add 20", limit);
        //   // console.log(limit / 2);
        // console.log(limit, offset);
        setOffSet(Number((limit / 2).toFixed()));
        setLimit(limit + 20);
      }
      // }, 100);
    }

    if (st === 0 && ls / ls === 1) {
      // console.log("scroll up", limit, offset);
      // console.log(st, ls / ls);
      // setTimeout(() => {
      // if (limit > 100) {
      if (offset !== 0) {
        setOffSet(0);
        setLimit(offset);
      } else {
        setOffSet(0);
        setLimit(limit);
      }
      // }
      // }, 100);
    }
  };

  const onConfigSetting = () => {
    setShowConfig(!showConfig);
  };

  const onCloseConfig = () => {
    setShowConfig(false);
  };

  const onCreateCard = (i, j) => {
    i.variables = j;
    setCards((cards) => [...cards, i]);
  };

  return (
    <>
      <Box component="div" className="exploretab-container">
        <Box component="div" className="line">
          <Box component="div" className="line1"></Box>
        </Box>
        <Box component="div" className="dataset-container">
          <WorkSpaceHeader
            columnsData={columnsData}
            columnsList={columnsList}
            isExplore={true}
            onConfigSetting={onConfigSetting}
            showSettings={showSettings}
          />
          {showConfig ? (
            <Box className="config-container">
              <ExploreConfigSidebar cardData={cards} />
              <Box className="config-container-main">
                <ExploreConfigHeader
                  onCloseConfig={onCloseConfig}
                  Columns={columnsList && columnsList.columns}
                  onCreateCard={(i, j) => onCreateCard(i, j)}
                />
                <CustomTable
                  columnsData={columnsData}
                  count={
                    columnsData && columnsData.data && columnsData.data.length
                  }
                  columnsList={columnsList}
                  onTableScroll={(e) => hanldeTableScroll(e)}
                />
              </Box>
            </Box>
          ) : (
            <CustomTable
              columnsData={columnsData}
              count={columnsData && columnsData.data && columnsData.data.length}
              columnsList={columnsList}
              onTableScroll={(e) => hanldeTableScroll(e)}
            />
          )}
        </Box>
      </Box>
    </>
  );
}

export default memo(Explore);
