import { Box, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { VfilterOptions } from "../../../../../utils/dummyData";
import "./visualizationFilterSidebar.scss";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { ArrowDropDown } from "@mui/icons-material";
import IButton from "../../../button";
import AddIcon from "@mui/icons-material/Add";
import deleteIcon from "../../../../../assets/icons/deletePlain.svg";
import { useNavigate, useParams } from "react-router-dom";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
    color: "#8b97a2",
  },
  marginLeft: -24,
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowDropDown />} {...props} />
))(({ theme }) => ({
  backgroundColor: "#f1f4f8",
  flexDirection: "row-reverse",
  color: "#8b97a2",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
    backgroundColor: "#f1f4f8",
    color: "#8b97a2",
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: "#8b97a2",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    backgroundColor: "#f1f4f8",
    color: "#8b97a2",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: "#f1f4f8",
  color: "#8b97a2",
  marginLeft: 8,
  marginTop: -12,
  width: "100%",
}));

export default function VisualizationFilterSidebar({
  savedCharts,
  changeVisId,
}) {
  const [expanded, setExpanded] = useState(6);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const navigate = useNavigate();
  const params = useParams();

  const createNewChart = () => {
    let canvasId = params.canvasId;
    let nodeId = params.nodeId;
    navigate(`/app/workspace/${canvasId}/node/${nodeId}/visualization`);
  };

  return (
    <Box className="graph-options">
      {VfilterOptions.map((item, index) => {
        return (
          <Accordion
            expanded={expanded === index}
            onChange={handleChange(index)}
            key={index}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              sx={{
                width: "120%",
                borderTop:
                  item.title === "My Saved Charts"
                    ? "1px solid #dbe2e7"
                    : "none",
              }}
              id={index}
            >
              <Typography
                sx={{
                  fontSize: 13,
                }}
              >
                {item.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box component={"div"} className={"details-container"}>
                {item.type}
                {item.data ? (
                  <>
                    <IButton
                      customClass={"addChart-btn"}
                      type={"primary"}
                      handleClick={createNewChart}
                    >
                      <AddIcon /> Add chart
                    </IButton>
                    {savedCharts &&
                      savedCharts.visualizations &&
                      savedCharts.visualizations.map((i, id) => {
                        return (
                          <Box
                            key={id}
                            component={"div"}
                            className={
                              params.visId === i.visualization_id
                                ? "chart-details-selected"
                                : "chart-details"
                            }
                          >
                            <Typography
                              component={"div"}
                              className={"chart-details-name"}
                              onClick={() => changeVisId(i)}
                            >
                              {i.visualization_id}
                            </Typography>
                            {/* <IconButton disableRipple={true}> */}
                            <img
                              src={deleteIcon}
                              alt="delete"
                              className="details-delete-icon"
                            />
                            {/* </IconButton> */}
                          </Box>
                        );
                      })}
                  </>
                ) : null}
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}
