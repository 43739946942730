/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Typography, IconButton, CssBaseline, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CardImage from "../../../assets/cardImage.png";
import {
  createNewCanvasAction,
  getAllCanvasAction,
  updateCanvasAction,
} from "../../../redux/actions/canvasAction";
import { useDispatch, useSelector } from "react-redux";
import { deleteCanvasAction } from "../../../redux/actions/canvasAction";
import NavBar from "../../themeComponents/Navbar/dashboardNavbar";
import DialogeBox from "../../themeComponents/popups/dashboardPopup";
import SearchBox from "../../themeComponents/search";
import SideBar from "../../themeComponents/sidebar/dashboardSidebar";
import Button from "../../themeComponents/button";
import Pipeline from "../pipelines";
import "./dashboard.scss";
import IPopover from "../../themeComponents/popover/index";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function WelcomeDashBoard({ children }) {
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [optionValue, setOptionValue] = useState({});
  const [renameData, setRenameData] = useState({});
  const [dialogeBoxState, setDialogeBoxState] = useState("");
  const [createstate, setcreatestate] = useState(false);
  const [showWorkspace, setShowWorkSpace] = useState(true);

  const navigateRoute = useNavigate();
  const dispatch = useDispatch();

  //getting all canvas
  const state = useSelector(
    (state) => state.getAllCanvasReducer.allCanvas.canvases
  );

  const newCanvas = useSelector(
    (state) => state.createNewCanvasReducer.newCanvas
  );
  const deleteCanvasResponse = useSelector(
    (state) => state.deleteCanvasReducer.message
  );
  const renameCanvasResponse = useSelector(
    (state) => state.updateCanvasReducer.updateCanvas
  );

  //geting all canvas everytime canvas gets updated or renamed
  useEffect(() => {
    dispatch(getAllCanvasAction("user123"));
    return () => {
      setDialogeBoxState("");
    };
  }, [newCanvas, deleteCanvasResponse, renameCanvasResponse]);

  //routing to canvas if new canvas created
  useEffect(() => {
    if (createstate === true) {
      navigateRoute(`/app/workspace/${newCanvas.canvas_id}`);
    }
    return () => setcreatestate(false);
  }, [newCanvas]);

  // helper function of material ui
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handlePopoverOpen = (event, idx) => {
    setAnchorEl(event.currentTarget);
    setOptionValue(idx);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const onCreateNewButton = () => {
    setOpenDialog(true);
    setDialogeBoxState("create");
  };

  //creating or updating the canvas
  const submitDialog = (e) => {
    setOpenDialog(false);
    if (dialogeBoxState === "create") {
      dispatch(
        createNewCanvasAction({
          user_id: "user123",
          company_id: "Comp123",
          canvas_name: e.canvas_name,
          canvas_description: e.canvas_description,
        })
      );
      setcreatestate(true);
    } else if (dialogeBoxState === "update") {
      dispatch(
        updateCanvasAction(
          {
            user_id: "user123",
            company_id: "Comp123",
            canvas_name: e.canvas_name,
            canvas_description: e.canvas_description,
          },
          renameData.canvas_id
        )
      );
      setOpenDialog(false);
    }
  };

  //calling this function when we rename canvas
  const renameCanvas = (item) => {
    const data = {
      canvas_name: item.canvas_name,
      canvas_description: item.canvas_description,
      canvas_id: item.canvas_id,
    };
    setOpenDialog(true);
    setRenameData(data);
    setDialogeBoxState("update");
    handlePopoverClose();
  };

  //calling this function when we edit canvas
  const editCanvas = (item) => {
    const canvas_id = item.canvas_id;
    const canvas_name = JSON.stringify(item.canvas_name);
    window.localStorage.setItem("canvasName", canvas_name);
    window.location.href = `/app/workspace/${canvas_id}`;
  };

  //calling this function when we edit canvas
  const deleteCanvas = (item) => {
    const canvas_id = item.canvas_id;
    const data = {
      user_id: item.user_id,
      company_id: item.company_id,
    };
    dispatch(deleteCanvasAction(data, canvas_id));
    handlePopoverClose();
    setTimeout(() => {
      dispatch(getAllCanvasAction("user123"));
    }, 1000);
  };

  const switchTabs = (e) => {
    const buttonName = e.target.name;
    if (buttonName === "Workspaces") {
      setShowWorkSpace(true);
    } else if (buttonName === "Pipelines") {
      setShowWorkSpace(false);
    } else {
      setShowWorkSpace(true);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      {/* workspace dialog */}
      {openDialog ? (
        <DialogeBox
          createstate={createstate}
          type={dialogeBoxState}
          data={renameData}
          open={openDialog}
          handleSubmit={(e) => submitDialog(e)}
          handleClose={() => setOpenDialog(false)}
        />
      ) : null}
      <CssBaseline />
      <IPopover
        state={anchorEl}
        data={[
          { name: "Edit", function: () => renameCanvas(optionValue) },
          { name: "Delete", function: () => deleteCanvas(optionValue) },
        ]}
        handleClose={handlePopoverClose}
      />
      {/* {resize ? (
        <>
          <MenuAppBar openMenu={handleDrawerOpen} />
          <SideBar open={open} />
        </>
      ) : ( */}
      <>
        <NavBar
          open={open}
          handleDrawerClose={handleDrawerClose}
          handleDrawerOpen={handleDrawerOpen}
        />
        <SideBar open={open} closeMenu={handleDrawerClose} />
      </>
      {/* )} */}

      {/* content and cards */}
      <Box component="main" className="content-main">
        <DrawerHeader />
        <Box component="div" className="buttons-container">
          <Box component="div" sx={{ marginTop: "8px", display: "flex" }}>
            <Button
              type={showWorkspace ? "secondary" : "default"}
              handleClick={(e) => switchTabs(e)}
              name={"Workspaces"}
            >
              Workspaces
            </Button>
            <Button
              type={showWorkspace ? "default" : "secondary"}
              handleClick={(e) => switchTabs(e)}
              name={"Pipelines"}
            >
              Pipelines
            </Button>
          </Box>
          {showWorkspace ? (
            <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
              <SearchBox placeholder={"Search workspaces..."} />
              <Button handleClick={onCreateNewButton} type={"primary"}>
                <AddIcon />
                Create New
              </Button>
            </Box>
          ) : null}
        </Box>
        {showWorkspace ? (
          <Box className="cards-container">
            {state &&
              state.map((item, index) => {
                return (
                  <Box key={index} component="div" className="card">
                    <img
                      src={CardImage}
                      alt="card"
                      className="card-image"
                      onClick={() => editCanvas(item)}
                    />
                    <Box component="div" className="card-data">
                      <Typography
                        className="card-title"
                        onClick={() => editCanvas(item)}
                      >
                        {item.canvas_name}
                      </Typography>
                      <Typography className="card-sub-title">
                        {item.canvas_description}
                      </Typography>
                      <Box component="div" className="card-staus-box"></Box>
                      <IconButton
                        component="div"
                        className="card-menu"
                        onClick={(e) => handlePopoverOpen(e, item)}
                      >
                        <MoreVertIcon style={{ color: "##8B97A2" }} />
                      </IconButton>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        ) : (
          <Pipeline />
        )}
      </Box>
    </Box>
  );
}

export default WelcomeDashBoard;
