import React from "react";
import { Popover } from "@mui/material";
import "./popover.scss";

const IPopover = ({ data, state, handleClose, option_classname, nodeType }) => {
  const open = Boolean(state);
  const id = open ? "simple-popover" : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={state}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        sx: {
          boxShadow: "0 2px 7px 0 rgba(36, 36, 36, 0.2)",
          borderRadius: "8px",
        },
      }}
    >
      <div className={"popover_menu"} onClick={handleClose}>
        {data.map((item, idx) => (
          <button
            key={idx}
            className={`popover_menu_options ${option_classname}`}
            onClick={item.function}
            style={{ color: item.name === "Delete" ? "crimson" : "inherit" }}
            disabled={nodeType !== "LOCAL_FILE" && item.name === "Configure"}
          >
            {item.name}
          </button>
        ))}
      </div>
    </Popover>
  );
};

export default IPopover;
