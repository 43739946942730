import {
  ADD_NODE_ERROR,
  ADD_NODE_PENDING,
  ADD_NODE_SUCCESS,
  ANALYZE_COLUMN_TYPE_ERROR,
  ANALYZE_COLUMN_TYPE_PENDING,
  ANALYZE_COLUMN_TYPE_SUCCESS,
  DELETE_NODE_ERROR,
  DELETE_NODE_PENDING,
  DELETE_NODE_SUCCESS,
  FETCH_NODE_DETAILS_ERROR,
  FETCH_NODE_DETAILS_PENDING,
  FETCH_NODE_DETAILS_SUCCESS,
  GET_CLOUMN_LIST_ERROR,
  GET_CLOUMN_LIST_PEDING,
  GET_CLOUMN_LIST_SUCCESS,
  EDIT_COLUMN_TYPE_PENDING,
  EDIT_COLUMN_TYPE_ERROR,
  EDIT_COLUMN_TYPE_SUCCESS,
  GET_CLOUMN_DATA_ERROR,
  GET_CLOUMN_DATA_PEDING,
  GET_CLOUMN_DATA_SUCCESS,
  UPDATE_NODE_ERROR,
  UPDATE_NODE_SUCCESS,
  UPDATE_NODE_PENDING,
  CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_ERROR,
  CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_SUCCESS,
  CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_PENDING,
  GET_FILE_URL_PENDING,
  GET_FILE_URL_SUCCESS,
  GET_FILE_URL_ERROR,
  POST_FILE_PENDING,
  POST_FILE_SUCCESS,
  POST_FILE_ERROR,
  GET_COFIGURE_DATASET_PENDING,
  GET_COFIGURE_DATASET_SUCCESS,
  GET_COLUMN_NAME_AND_TYPE_ERROR,
} from "../type";

const initialState = {
  loading: null,
  error: null,
  message: "",
  newNode: {},
  updateNode: {},
  cloumnName: {},
  editCloumnType: {},
  nodeDetails: {},
  columnList: {},
  columnData: {},
  configureSampleNodeId: {},
  updateConfigureSample: {},
  fileURL: {},
  uploadedFile: {},
  datasets: {},
};

export const addNodeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_NODE_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_NODE_SUCCESS:
      return {
        ...state,
        loading: false,
        newNode: payload,
        error: null,
      };
    case ADD_NODE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export const updateNodeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_NODE_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_NODE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateNode: payload,
        error: null,
      };
    case UPDATE_NODE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export const deleteNodeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case DELETE_NODE_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_NODE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "DELETED SUCCESSFULLY",
        error: null,
      };
    case DELETE_NODE_ERROR:
      return {
        ...state,
        loading: false,
        message: "ERROR",
        error: payload,
      };

    default:
      return state;
  }
};

export const fetchNodeDetailsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case FETCH_NODE_DETAILS_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_NODE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        nodeDetails: payload,
        error: null,
      };
    case FETCH_NODE_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

//analyze column type
export const analyzeColumnTypeReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case ANALYZE_COLUMN_TYPE_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ANALYZE_COLUMN_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        cloumnName: payload,
        error: null,
      };
    case ANALYZE_COLUMN_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        cloumnName: {},
      };

    default:
      return state;
  }
};

export const editColumnTypeReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case EDIT_COLUMN_TYPE_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EDIT_COLUMN_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        editCloumnType: payload,
        error: null,
      };
    case EDIT_COLUMN_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export const getColumnDatabynodeIdReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_CLOUMN_LIST_PEDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_CLOUMN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        columnList: payload,
        error: null,
      };
    case GET_CLOUMN_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export const getDatabynodeIdReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_CLOUMN_DATA_PEDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_CLOUMN_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        columnData: payload,
        error: null,
      };
    case GET_CLOUMN_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export const getSamplingSettingForNodeReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        configureSampleNodeId: payload,
        error: null,
        Alert: payload,
      };
    case CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_ERROR:
      return {
        ...state,
        loading: false,
        configureSampleNodeId: [],
        error: payload,
        Alert: payload,
      };
    default:
      return state;
  }
};

export const updateSettingForNodeReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        updateConfigureSample: payload,
        error: null,
        Alert: payload,
      };
    case CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_ERROR:
      return {
        ...state,
        loading: false,
        updateConfigureSample: {},
        error: payload,
        Alert: payload,
      };
    default:
      return state;
  }
};

export const getFileURLReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_FILE_URL_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_FILE_URL_SUCCESS:
      return {
        ...state,
        loading: false,
        fileURL: payload,
        error: null,
      };
    case GET_FILE_URL_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export const uploadFileReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case POST_FILE_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case POST_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        uploadedFile: payload,
        error: null,
      };
    case POST_FILE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export const getCofigureDatasetReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_COFIGURE_DATASET_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_COFIGURE_DATASET_SUCCESS:
      return {
        ...state,
        loading: false,
        datasets: payload,
        error: null,
      };
    case GET_COLUMN_NAME_AND_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
