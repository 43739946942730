export const GET_ALL_CANVAS_PENDING = "GET_ALL_CANVAS_PENDING";
export const GET_ALL_CANVAS_SUCCESS = "GET_ALL_CANVAS_SUCCESS";
export const GET_ALL_CANVAS_ERROR = "GET_ALL_CANVAS_ERROR";

export const CREATE_CANVAS_PENDING = "CREATE_CANVAS_PENDING";
export const CREATE_CANVAS_SUCCESS = "CREATE_CANVAS_SUCCESS";
export const CREATE_CANVAS_ERROR = "CREATE_CANVAS_ERROR";

export const UPDATE_CANVAS_PENDING = "UPDATE_CANVAS_PENDING";
export const UPDATE_CANVAS_SUCCESS = "UPDATE_CANVAS_SUCCESS";
export const UPDATE_CANVAS_ERROR = "UPDATE_CANVAS_ERROR";

export const DELETE_CANVAS_PENDING = "DELETE_CANVAS_PENDING";
export const DELETE_CANVAS_SUCCESS = "DELETE_CANVAS_SUCCESS";
export const DELETE_CANVAS_ERROR = "DELETE_CANVAS_ERROR";

export const GET_ALL_CANVAS_NODES_PENDING = "GET_ALL_CANVAS_NODES_PENDING";
export const GET_ALL_CANVAS_NODES_SUCCESS = "GET_ALL_CANVAS_NODES_SUCCESS";
export const GET_ALL_CANVAS_NODES_ERROR = "GET_ALL_CANVAS_NODES_ERROR";

export const GET_ALL_NODEGROUPTYPE_PENDING = "GET_ALL_NODEGROUPTYPE_PENDING";
export const GET_ALL_NODEGROUPTYPE_SUCCESS = "GET_ALL_NODEGROUPTYPE_SUCCESS";
export const GET_ALL_NODEGROUPTYPE_ERROR = "GET_ALL_NODEGROUPTYPE_ERROR";

export const GET_ALL_GROUPTYPE_NODES_PENDING =
  "GET_ALL_GROUPTYPE_NODES_PENDING";
export const GET_ALL_GROUPTYPE_NODES_SUCCESS =
  "GET_ALL_GROUPTYPE_NODES_SUCCESS";
export const GET_ALL_GROUPTYPE_NODES_ERROR = "GET_ALL_GROUPTYPE_NODES_ERROR";

export const GET_SPECIFIC_NODE_PENDING = "GET_SPECIFIC_NODE_PENDING";
export const GET_SPECIFIC_NODE_SUCCESS = "GET_SPECIFIC_NODE_SUCCESS";
export const GET_SPECIFIC_NODE_ERROR = "GET_SPECIFIC_NODE_ERROR";

export const ADD_NODE_PENDING = "ADD_NODE_PENDING";
export const ADD_NODE_SUCCESS = "ADD_NODE_SUCCESS";
export const ADD_NODE_ERROR = "ADD_NODE_ERROR";

export const DELETE_NODE_PENDING = "DELETE_NODE_PENDING";
export const DELETE_NODE_SUCCESS = "DELETE_NODE_SUCCESS";
export const DELETE_NODE_ERROR = "DELETE_NODE_ERROR";

export const UPDATE_NODE_PENDING = "UPDATE_NODE_PENDING";
export const UPDATE_NODE_SUCCESS = "UPDATE_NODE_SUCCESS";
export const UPDATE_NODE_ERROR = "UPDATE_NODE_ERROR";

export const FETCH_NODE_DETAILS_PENDING = "FETCH_NODE_DETAILS_PENDING";
export const FETCH_NODE_DETAILS_SUCCESS = "FETCH_NODE_DETAILS_SUCCESS";
export const FETCH_NODE_DETAILS_ERROR = "FETCH_NODE_DETAILS_ERROR";

export const ANALYZE_COLUMN_TYPE_PENDING = "ANALYZE_COLUMN_TYPE_PENDING";
export const ANALYZE_COLUMN_TYPE_SUCCESS = "ANALYZE_COLUMN_TYPE_SUCCESS";
export const ANALYZE_COLUMN_TYPE_ERROR = "ANALYZE_COLUMN_TYPE_ERROR";

export const EDIT_COLUMN_TYPE_PENDING = "EDIT_COLUMN_TYPE_PENDING";
export const EDIT_COLUMN_TYPE_SUCCESS = "EDIT_COLUMN_TYPE_SUCCESS";
export const EDIT_COLUMN_TYPE_ERROR = "EDIT_COLUMN_TYPE_ERROR";

export const GET_CLOUMN_LIST_PEDING = "GET_CLOUMN_LIST_PEDING";
export const GET_CLOUMN_LIST_SUCCESS = "GET_CLOUMN_LIST_SUCCESS";
export const GET_CLOUMN_LIST_ERROR = "GET_CLOUMN_LIST_ERROR";

export const GET_CLOUMN_DATA_PEDING = "GET_CLOUMN_DATA_PEDING";
export const GET_CLOUMN_DATA_SUCCESS = "GET_CLOUMN_DATA_SUCCESS";
export const GET_CLOUMN_DATA_ERROR = "GET_CLOUMN_DATA_ERROR";

export const CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_ERROR =
  "CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_ERROR";
export const CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_PENDING =
  "CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_PENDING";
export const CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_SUCCESS =
  "CONFIGURE_SAMPLE_SETTING_BY_NODE_ID_SUCCESS";

export const OPEN_SNACKBAR = "OPEN_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const UPDATE_DATA = "UPDATE_DATA";

export const OPEN_LOADER = "OPEN_LOADER";
export const CLOSE_LOADER = "CLOSE_LOADER";

export const GET_COLUMN_NAME_AND_TYPE_PENDING =
  "GET_COLUMN_NAME_AND_TYPE_PENDING";
export const GET_COLUMN_NAME_AND_TYPE_SUCCESS =
  "GET_COLUMN_NAME_AND_TYPE_SUCCESS";
export const GET_COLUMN_NAME_AND_TYPE_ERROR = "GET_COLUMN_NAME_AND_TYPE_ERROR";

export const GET_VISUALIZATION_GROUP_NAME_PENDING =
  "GET_VISUALIZATION_GROUP_NAME_PENDING";
export const GET_VISUALIZATION_GROUP_NAME_SUCCESS =
  "GET_VISUALIZATION_GROUP_NAME_SUCCESS";
export const GET_VISUALIZATION_GROUP_NAME_ERROR =
  "GET_VISUALIZATION_GROUP_NAME_ERROR";

export const GET_VISUALIZATION_GROUPS_PENDING =
  "GET_VISUALIZATION_GROUPS_PENDING";
export const GET_VISUALIZATION_GROUPS_SUCCESS =
  "GET_VISUALIZATION_GROUPS_SUCCESS";
export const GET_VISUALIZATION_GROUPS_ERROR = "GET_VISUALIZATION_GROUPS_ERROR";

export const CREATE_EMPTY_VISUALIZATION_GRAPH_PENDING =
  "CREATE_EMPTY_VISUALIZATION_GRAPH_PENDING";
export const CREATE_EMPTY_VISUALIZATION_GRAPH_SUCCESS =
  "CREATE_EMPTY_VISUALIZATION_GRAPH_SUCCESS";
export const CREATE_EMPTY_VISUALIZATION_GRAPH_ERROR =
  "CREATE_EMPTY_VISUALIZATION_GRAPH_ERROR";

export const FETCH_CONFIGURATION_CONSTRAINTS_PENDING =
  "FETCH_CONFIGURATION_CONSTRAINTS_PENDING";
export const FETCH_CONFIGURATION_CONSTRAINTS_SUCCESS =
  "FETCH_CONFIGURATION_CONSTRAINTS_SUCCESS";
export const FETCH_CONFIGURATION_CONSTRAINTS_ERROR =
  "FETCH_CONFIGURATION_CONSTRAINTS_ERROR";

export const UPDATE_GRAPH_PENDING = "UPDATE_GRAPH_PENDING";
export const UPDATE_GRAPH_SUCCESS = "UPDATE_GRAPH_SUCCESS";
export const UPDATE_GRAPH_ERROR = "UPDATE_GRAPH_ERROR";

export const GET_CHART_DATA_PENDING = "GET_CHART_DATA_PENDING";
export const GET_CHART_DATA_SUCCESS = "GET_CHART_DATA_SUCCESS";
export const GET_CHART_DATA_ERROR = "GET_CHART_DATA_ERROR";

export const GET_FILE_URL_PENDING = "GET_FILE_URL_PENDING";
export const GET_FILE_URL_SUCCESS = "GET_FILE_URL_SUCCESS";
export const GET_FILE_URL_ERROR = "GET_FILE_URL_ERROR";

export const POST_FILE_PENDING = "POST_FILE_PENDING";
export const POST_FILE_SUCCESS = "POST_FILE_SUCCESS";
export const POST_FILE_ERROR = "POST_FILE_ERROR";

export const GET_COFIGURE_DATASET_PENDING = "GET_COFIGURE_DATASET_PENDING";
export const GET_COFIGURE_DATASET_SUCCESS = "GET_COFIGURE_DATASET_SUCCESS";
export const GET_COFIGURE_DATASET_ERROR = "GET_COFIGURE_DATASET_ERROR";
