import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import PencilIcons from "../../../../assets/icons/PencilIcons.svg";
import RefreshIcons from "../../../../assets/icons/RefreshIcons.svg";
import ArrowExpandIcons from "../../../../assets/icons/ArrowExpandIcons.svg";
import "./visualizationCharts.scss";
import { Close } from "@mui/icons-material";
import BarGraph from "./barChart";
import LineGraph from "./lineCharts";
import DoneIcon from "@mui/icons-material/Done";

export default function VisualizationCharts() {
  const graphType = window.localStorage.getItem("graph_type");
  const [open, setOpen] = useState(false);
  const [togglePencilIcon, setTogglePencilIcons] = useState(true);
  const [changeGraphName, setChangeGraphname] = useState("New Chart");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const editGraphName = (e) => {
    setChangeGraphname(changeGraphName);
    setTogglePencilIcons(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            height: "90vh",
          },
        }}
      >
        <DialogContent>
          <Box sx={{ height: "100%", width: "100%", position: "relative" }}>
            <IconButton
              onClick={handleClose}
              style={{ position: "absolute", right: 0, top: 0 }}
            >
              <Close />
            </IconButton>
            {graphType === "HISTOGRAM" ? <BarGraph /> : <LineGraph />}
          </Box>
        </DialogContent>
      </Dialog>
      <Box className="draw-graph-container">
        <Box className="new-chart-tittle-container">
          <div className="new-chart-head">
            {togglePencilIcon ? (
              <>
                <img
                  src={PencilIcons}
                  alt="icons"
                  onClick={(e) => editGraphName(e)}
                />
                <Typography className="new-chart-tittle-style">
                  {" "}
                  {changeGraphName}
                </Typography>
              </>
            ) : (
              <>
                <input
                  className="new-chart-tittle-style-input"
                  placeholder={changeGraphName}
                  value={changeGraphName}
                  onChange={(e) => setChangeGraphname(e.target.value)}
                />
                <DoneIcon
                  className="done-icon-style"
                  onClick={() => setTogglePencilIcons(true)}
                />
              </>
            )}
            {/* New Chart */}
            {/* </Typography> */}
          </div>
          <div className="draw-extra-icons">
            <img
              className="refresh-icons-style"
              src={RefreshIcons}
              alt="refresh icons"
            />
            <img
              className="refresh-icons-style"
              src={ArrowExpandIcons}
              alt="arrow Icons"
              onClick={handleClickOpen}
            />
          </div>
        </Box>
        {/* <Box className="group-result-tittle-container">
      <Typography className="group-result-tittle-1">
        Please select how to group results
      </Typography>
    </Box> */}
        <Box className="visualization-graph-container">
          {graphType === "HISTOGRAM" ? <BarGraph /> : <LineGraph />}
        </Box>
      </Box>
    </>
  );
}
