import {
  CREATE_EMPTY_VISUALIZATION_GRAPH_ERROR,
  CREATE_EMPTY_VISUALIZATION_GRAPH_PENDING,
  CREATE_EMPTY_VISUALIZATION_GRAPH_SUCCESS,
  FETCH_CONFIGURATION_CONSTRAINTS_ERROR,
  FETCH_CONFIGURATION_CONSTRAINTS_PENDING,
  FETCH_CONFIGURATION_CONSTRAINTS_SUCCESS,
  GET_CHART_DATA_ERROR,
  GET_CHART_DATA_PENDING,
  GET_CHART_DATA_SUCCESS,
  GET_COLUMN_NAME_AND_TYPE_ERROR,
  GET_COLUMN_NAME_AND_TYPE_PENDING,
  GET_COLUMN_NAME_AND_TYPE_SUCCESS,
  GET_VISUALIZATION_GROUPS_ERROR,
  GET_VISUALIZATION_GROUPS_PENDING,
  GET_VISUALIZATION_GROUPS_SUCCESS,
  GET_VISUALIZATION_GROUP_NAME_ERROR,
  GET_VISUALIZATION_GROUP_NAME_PENDING,
  GET_VISUALIZATION_GROUP_NAME_SUCCESS,
  UPDATE_CANVAS_ERROR,
  UPDATE_CANVAS_PENDING,
  UPDATE_CANVAS_SUCCESS,
} from "../type";

const initialState = {
  loading: null,
  error: null,
  message: "",
  columns: {},
  chartData:{},
  VisualizationGroup: {},
  VisualizationGroupNames: {},
  newEmptyGraph: {},
  configurationConstraints: {},
  updatedGraph: {},
};

export const getColumnNameandTypeReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_COLUMN_NAME_AND_TYPE_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_COLUMN_NAME_AND_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        columns: payload,
        error: null,
      };
    case GET_COLUMN_NAME_AND_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export const getVisualizationGroupNameReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_VISUALIZATION_GROUP_NAME_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_VISUALIZATION_GROUP_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        VisualizationGroupNames: payload,
        error: null,
      };
    case GET_VISUALIZATION_GROUP_NAME_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export const getVisualizationGroupsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_VISUALIZATION_GROUPS_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_VISUALIZATION_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        VisualizationGroup: payload,
        error: null,
      };
    case GET_VISUALIZATION_GROUPS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export const createEmptyVisualizationGraphReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case CREATE_EMPTY_VISUALIZATION_GRAPH_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_EMPTY_VISUALIZATION_GRAPH_SUCCESS:
      return {
        ...state,
        loading: false,
        newEmptyGraph: payload,
        error: null,
      };
    case CREATE_EMPTY_VISUALIZATION_GRAPH_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export const fetchConfigurationConstraintsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case FETCH_CONFIGURATION_CONSTRAINTS_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_CONFIGURATION_CONSTRAINTS_SUCCESS:
      return {
        ...state,
        loading: false,
        configurationConstraints: payload,
        error: null,
      };
    case FETCH_CONFIGURATION_CONSTRAINTS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export const updateGraphReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_CANVAS_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_CANVAS_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedGraph: payload,
        error: null,
      };
    case UPDATE_CANVAS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export const getChartDataReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_CHART_DATA_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_CHART_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        chartData: payload,
        error: null,
      };
    case GET_CHART_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
