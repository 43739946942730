import React from "react";

export default function IProgressBar({
  barBgColor,
  value,
  barBorderRadius,
  borderRadius,
  bgColor,
  height,
  className,
  width,
}) {
  const progressContainer = {
    height: height,
    width: width,
    backgroundColor: `${bgColor}`,
    borderRadius: `${borderRadius}`,
  };

  const progress = {
    height: "100%",
    width: `${value}%`,
    backgroundColor: barBgColor,
    borderRadius: `${barBorderRadius}`,
    transition: "width 1s ease-in-out",
  };

  return (
    <div style={progressContainer} className={`${className}`}>
      <div style={progress}></div>
    </div>
  );
}
