import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "./menu.scss";

export default function BasicMenuBtn({
  title,
  menuStyle,
  iconStyle,
  titleStyle,
  menuData,
  av,
  ah,
  tv,
  th,
  menuItemIcon,
  menuHeadStyle,
  onChangeMenuItem,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={`${menuHeadStyle}`} onClick={handleClick}>
        {menuItemIcon ? <div className={iconStyle}>{menuItemIcon}</div> : null}
        <div className={titleStyle}>{title}</div>
      </div>
      <Menu
        className={`submenu ${menuStyle}`}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: `${av}`,
          horizontal: `${ah}`,
        }}
        transformOrigin={{
          vertical: `${tv}`,
          horizontal: `${th}`,
        }}
        onClick={handleClose}
        PaperProps={{
          sx: {
            boxShadow: "0 2px 7px 0 rgba(36, 36, 36, 0.2)",
            borderRadius: "8px",
          },
        }}
      >
        {menuData &&
          menuData.map((option, index) => {
            return (
              <MenuItem
                className={"submenu-item"}
                onClick={
                  onChangeMenuItem ? (e) => onChangeMenuItem(option) : null
                }
                key={index}
              >
                {option.name}
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
}
