/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bar, BarChart, XAxis, YAxis, Tooltip } from "recharts";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { analyzeColumnTypeAction } from "../../../../redux/actions/nodeAction";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import IButton from "../../button";
import Loader from "../../loader";
import "./analyzeStyle.scss";
import IProgressBar from "../../progressBar";
import { useParams } from "react-router-dom";

const topValues = [
  { x: Math.random() * 100, y: Math.random() * 1000, z: Math.random() * 100 },
  { x: Math.random() * 100, y: Math.random() * 1000, z: Math.random() * 100 },
  { x: Math.random() * 100, y: Math.random() * 1000, z: Math.random() * 100 },
  { x: Math.random() * 100, y: Math.random() * 1000, z: Math.random() * 100 },
  { x: Math.random() * 100, y: Math.random() * 1000, z: Math.random() * 100 },
  { x: Math.random() * 100, y: Math.random() * 1000, z: Math.random() * 100 },
];

const PopupHeadingSection = ({ analyzeData, headText }) => {
  return (
    <Box component="div" className="exp-analyze-content-head">
      <Typography className="exp-analyze-content-head-text">
        &lt;&lt; {headText} &gt;&gt; on{" "}
      </Typography>
      <Box component="div">
        <select
          disabled={"disabled"}
          defaultValue={"A"}
          className="exp-analyze-content-head-select"
        >
          <option value="A">Sample</option>
          <option value="B">Neumarical</option>
          <option value="C">Categorical</option>
        </select>
      </Box>
      <Typography className="exp-analyze-content-head-text-light">
        (
        {analyzeData &&
          analyzeData.categorical &&
          Object.keys(analyzeData.categorical).length}{" "}
        distinct)
      </Typography>
    </Box>
  );
};

const PopupSummarySection = ({ analyzeData }) => {
  const normalise = (value, MIN, MAX) => ((value - MIN) * 100) / (MAX - MIN);
  return (
    <Box component="div" className="exp-analyze-content-body-summary">
      <Typography className="exp-analyze-content-body-summary-head">
        Summary
      </Typography>
      <IProgressBar
        value={normalise(
          analyzeData && analyzeData.summary && analyzeData.summary.valid,
          0,
          10000
        )}
        height={17}
        width={"288px"}
        borderRadius={"2px"}
        bgColor={"#F0F5F6"}
        barBorderRadius={"2px"}
        barBgColor={"rgba(50,208,148,0.6)"}
      />
      <table className="ex-analyze-content-table">
        <tbody className="ex-analyze-content-table-body">
          {analyzeData &&
            analyzeData.summary &&
            Object.entries(analyzeData.summary).map(([item, index], idx) => {
              return (
                <tr key={idx} className="ex-analyze-content-table-row">
                  <td className="ex-analyze-content-table-row-cell">
                    {item}{" "}
                    <div
                      style={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        background:
                          item === "invalid"
                            ? "#EE5353"
                            : item === "valid"
                            ? "#32D094"
                            : " rgba(139,151,162,0.5)",
                        display: "inline-block",
                        marginLeft: "4px",
                      }}
                    ></div>
                  </td>
                  <td
                    className="ex-analyze-content-table-row-cell"
                    style={{ textAlign: "right" }}
                  >
                    {index === 0 ? index : (index / 100).toFixed(3)}
                  </td>
                  <td
                    className="ex-analyze-content-table-row-cell"
                    style={{
                      textAlign: "right",
                      color: "#8B97A2",
                    }}
                  >
                    {index / 100} %
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </Box>
  );
};

export default function AnalyzeColumnData({
  colName,
  optionContainerStyle,
  iconStyle,
  titleStyle,
  closeMenu,
}) {
  const [open, setOpen] = useState(false);
  const [switchTab, setSwitchTab] = useState(true);
  const [openAnalyze, setOpenAnalyze] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();

  const analyzeData = useSelector(
    (state) => state.analyzeColumnTypeReducer.cloumnName
  );

  const analyzeLoading = useSelector(
    (state) => state.analyzeColumnTypeReducer.loading
  );

  useEffect(() => {
    const nodeId = params.nodeId;
    if (openAnalyze === true) {
      dispatch(analyzeColumnTypeAction(nodeId, colName.name));
    }
  }, [openAnalyze]);

  const handleClickOpen = () => {
    setOpen(true);
    setOpenAnalyze(true);
  };

  const handleClose = () => {
    setOpen(false);
    closeMenu();
    setOpenAnalyze(false);
  };

  const getBarData = (data) => {
    let barGraphData = [];
    if (data) {
      Object.entries(analyzeData.numerical_histogram).forEach(
        ([key, value], index) => {
          barGraphData.push({
            x: Number(key).toFixed(1),
            y: value,
          });
        }
      );
    }
    return barGraphData;
  };

  return (
    <div>
      <div className={optionContainerStyle} onClick={handleClickOpen}>
        <div className={iconStyle}>{<AutoGraphIcon />}</div>
        <div className={titleStyle}>Analyze</div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          sx: {
            height: "80vh",
          },
        }}
        classes={{
          paper: "paper-analyze",
          root: "root-analyze",
        }}
      >
        {analyzeLoading ? (
          <Loader open={analyzeLoading} noDrop={true} />
        ) : (
          <>
            <DialogTitle sx={{ padding: 0 }}>
              <Box component="div" className="exp-analyze-head">
                <Typography component="div" className="exp-analyze-head-title">
                  Analyze
                </Typography>
                <Box component="div" className="exp-analyze-head-btns">
                  <IButton
                    type={switchTab ? "secondary" : "default"}
                    handleClick={() => setSwitchTab(true)}
                  >
                    Categorical
                  </IButton>
                  {analyzeData && analyzeData.numerical === null ? null : (
                    <IButton
                      type={switchTab ? "default" : "secondary"}
                      handleClick={() => setSwitchTab(false)}
                    >
                      Numerical
                    </IButton>
                  )}
                </Box>
              </Box>
            </DialogTitle>
            <DialogContent sx={{ padding: 0 }}>
              {switchTab ? (
                <>
                  <PopupHeadingSection
                    headText={"Run_mode"}
                    analyzeData={analyzeData}
                  />
                  <Box component="div" className="exp-analyze-content-body-one">
                    <Box component="div">
                      <PopupSummarySection analyzeData={analyzeData} />
                      <Box component="div">
                        {analyzeData &&
                          analyzeData.summary &&
                          Object.entries(analyzeData.summary).map(
                            ([item, index], idx) => {
                              return (
                                <Box
                                  key={idx}
                                  component="div"
                                  className="exp-analyze-summary-sub-section"
                                >
                                  <Box
                                    component="div"
                                    className="exp-analyze-summary-sub-section-head "
                                  >
                                    {" "}
                                    <div style={{ marginRight: "4px" }}>
                                      {item === "invalid" ? index : null}
                                    </div>
                                    {item === "invalid" ? item : null}
                                  </Box>
                                  <Box
                                    component="div"
                                    className="exp-analyze-summary-sub-section-per "
                                  >
                                    {item === "invalid"
                                      ? (index / 10).toFixed(1) + "%"
                                      : null}
                                  </Box>
                                </Box>
                              );
                            }
                          )}
                      </Box>
                    </Box>
                    <Box
                      component="div"
                      className="exp-analyze-cat-progress-container"
                    >
                      <Box
                        component="div"
                        className="exp-analyze-cat-progress-container-head"
                      >
                        <Typography
                          component={"div"}
                          className="exp-analyze-cat-progress-container-empty"
                        ></Typography>
                        <Typography
                          component={"div"}
                          className="exp-analyze-cat-progress-container-column"
                        >
                          Count
                        </Typography>
                        <Typography
                          component={"div"}
                          className="exp-analyze-cat-progress-container-column"
                        >
                          %
                        </Typography>
                        <Typography
                          component={"div"}
                          className="exp-analyze-cat-progress-container-column"
                        >
                          Cum. %
                        </Typography>
                      </Box>
                      <Box
                        component="div"
                        className="exp-analyze-cat-progress-container-items"
                      >
                        {analyzeData &&
                        analyzeData.categorical &&
                        analyzeData.categorical === null ? (
                          <div className="exp-analyze-nodata">No Data</div>
                        ) : (
                          analyzeData &&
                          analyzeData.categorical &&
                          Object.entries(analyzeData.categorical).map(
                            ([item, index], idx) => {
                              return (
                                <Box
                                  component="div"
                                  key={idx}
                                  className="exp-analyze-cat-progress-container-item"
                                >
                                  <Box
                                    component="div"
                                    className="exp-analyze-cat-progress-container-item-first"
                                  >
                                    <Typography className="exp-analyze-cat-progress-container-item-first-title">
                                      {item}
                                    </Typography>
                                    <IProgressBar
                                      value={index.percentage}
                                      height={15}
                                      width={286}
                                      borderRadius={"4px"}
                                      bgColor={"#F0F5F6"}
                                      barBgColor={"#84E3BF"}
                                      barBorderRadius={"4px"}
                                    />
                                  </Box>
                                  <Typography className="exp-analyze-cat-progress-container-item-r">
                                    {index.frequency}
                                  </Typography>
                                  <Typography className="exp-analyze-cat-progress-container-item-r">
                                    {index.percentage.toFixed(2)}
                                  </Typography>
                                  <Typography className="exp-analyze-cat-progress-container-item-r">
                                    {index.cumulative_percentage.toFixed(2)}
                                  </Typography>
                                </Box>
                              );
                            }
                          )
                        )}
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <PopupHeadingSection
                    headText={"Concentration mg/L"}
                    analyzeData={analyzeData}
                  />
                  <Box component="div" className="exp-analyze-content-body-one">
                    <PopupSummarySection analyzeData={analyzeData} />
                    <Box component="div" className="exp-analyze-content-graph">
                      <BarChart
                        width={740}
                        height={181}
                        data={getBarData(
                          analyzeData && analyzeData.numerical_histogram
                        )}
                        barGap={0}
                        barCategoryGap={0}
                      >
                        <XAxis minTickGap={0} dataKey="x" />
                        <YAxis dataKey="y" hide={true} />
                        <Bar
                          dataKey="y"
                          barSize={22}
                          maxBarSize={22}
                          fill="#84E3BF"
                          radius={[8, 8, 0, 0]}
                        />
                        <Tooltip cursor={false} />
                      </BarChart>
                    </Box>
                  </Box>
                  <Box
                    component="div"
                    className="exp-analyze-content-statistics"
                  >
                    {analyzeData &&
                    analyzeData.summary &&
                    analyzeData.numerical === null ? (
                      <div className="exp-analyze-nodata">No Data</div>
                    ) : (
                      <div className="exp-analyze-statistics">
                        <Typography className="exp-analyze-statistics-head">
                          Statistics
                        </Typography>
                        {analyzeData &&
                          analyzeData.summary &&
                          Object.entries(analyzeData.numerical).map(
                            ([item, index], idx) => {
                              return (
                                <div
                                  key={idx}
                                  className="exp-analyze-statistics-body-item"
                                >
                                  <div>{item}</div>
                                  <div style={{ color: "#8B97A2 " }}>
                                    {index}
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </div>
                    )}
                    <div className="exp-analyze-statistics">
                      <Typography className="exp-analyze-statistics-head">
                        Top values
                      </Typography>
                      {topValues &&
                        topValues.map((item, idx) => {
                          return (
                            <div
                              key={idx}
                              className="exp-analyze-statistics-body-item"
                            >
                              <div>{item.x.toFixed(1)}</div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "40%",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div style={{ color: "#8B97A2 " }}>
                                  {Math.floor(item.y)}
                                </div>
                                <div style={{ color: "#8B97A2 " }}>
                                  {(item.y / 10).toFixed(1) + "%"}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      <Typography className="morevalues-top-values">
                        More values and actions
                      </Typography>
                    </div>
                    <div className="exp-analyze-statistics statistics-outliers-main">
                      <Typography
                        component={"div"}
                        className="exp-analyze-statistics-head"
                      >
                        Outliers{" "}
                        <div
                          style={{
                            color: "#8B97A2 ",
                            textAlign: "right",
                            fontWeight: "normal",
                            marginLeft: "20px",
                            width: "auto",
                          }}
                        >
                          (0 low, {topValues.length - 1} high)
                        </div>
                      </Typography>
                      {topValues &&
                        topValues.map((item, idx) => {
                          return (
                            <div
                              key={idx}
                              className="exp-analyze-statistics-body-item statistics-outliers"
                            >
                              <div
                                style={{
                                  color: "#8B97A2 ",
                                  textAlign: "right",
                                }}
                              >
                                {Math.floor(item.y)}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div className="exp-analyze-statistics statistics-top-invalids">
                      <Typography className="exp-analyze-statistics-head">
                        Top invalids
                      </Typography>
                    </div>
                  </Box>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <IButton handleClick={handleClose} type={"primary"}>
                Close
              </IButton>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}
