/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import IButton from "../../button";
import SearchBox from "../../search";
import ConfigureSample from "../../popups/configureSample";
import "./workSpaceHeader.scss";
import { useNavigate, useParams } from "react-router-dom";
import { Settings } from "@mui/icons-material";

function WorkSpaceHeader({
  isExplore,
  columnsData,
  columnsList,
  onConfigSetting,
  showSettings,
}) {
  const [changeButtonType, setChangeButtonType] = useState({
    explore: false,
    visualization: false,
    statisics: false,
  });

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    let routePath = window.location.pathname;
    if (routePath.includes("visualization")) {
      setChangeButtonType({
        ...changeButtonType,
        visualization: true,
      });
    } else if (routePath.includes("statistics")) {
      setChangeButtonType({
        ...changeButtonType,
        statisics: true,
      });
    } else if (routePath.includes("explore")) {
      setChangeButtonType({
        ...changeButtonType,
        explore: true,
      });
    }
  }, []);

  const onChangeTab = (e) => {
    let routeName = e.target.name;
    let canvasId = params.canvasId;
    let nodeId = params.nodeId;
    if (routeName === "Explore") {
      navigate(`/app/workspace/${canvasId}/node/${nodeId}/explore`);
      setChangeButtonType({ ...changeButtonType, explore: true });
    } else if (routeName === "Visualization") {
      navigate(`/app/workspace/${canvasId}/node/${nodeId}/visualization`);
      setChangeButtonType({ ...changeButtonType, visualization: true });
    } else if (routeName === "Statistics") {
      navigate(`/app/workspace/${canvasId}/node/${nodeId}/statistics`);
      setChangeButtonType({ ...changeButtonType, statisics: true });
    }
  };

  return (
    <Box component="div" className="dataset-tableHeader">
      <Box component="div" className="tableHeader-title-items">
        <Box component="div" className="tableHeader-tilte-container">
          <Typography className="tableHeader-tilte">
            Viewing dataset sample
          </Typography>
          <Typography component="div" className="tableHeader-value-configure">
            {<ConfigureSample />}
          </Typography>
          {window.location.pathname.includes("explore") && showSettings ? (
            <Settings
              className="tableHeader-settings"
              onClick={onConfigSetting}
            />
          ) : null}
        </Box>
        <Box component="div">
          {isExplore ? (
            <Typography className="tableHeader-sub-title">
              {columnsData && columnsData.data && columnsData.data.length} rows,{" "}
              {columnsList && columnsList.columns && columnsList.columns.length}{" "}
              cols
            </Typography>
          ) : (
            <Typography className="tableHeader-sub-title">
              40 rows, 15 cols
            </Typography>
          )}
        </Box>
      </Box>
      <Box component="div" className="tableHeader-items">
        {isExplore ? (
          <SearchBox placeholder={"Search Data Sources..."} />
        ) : null}
        <IButton
          name="Explore"
          handleClick={onChangeTab}
          type={changeButtonType.explore ? "secondary" : "default"}
        >
          Explore
        </IButton>
        <IButton
          name="Visualization"
          handleClick={onChangeTab}
          type={changeButtonType.visualization ? "secondary" : "default"}
        >
          Visualization
        </IButton>
        <IButton
          name="Statistics"
          handleClick={onChangeTab}
          type={changeButtonType.statisics ? "secondary" : "default"}
        >
          Statistics
        </IButton>
      </Box>
    </Box>
  );
}

export default memo(WorkSpaceHeader);
