import {
  GET_ALL_GROUPTYPE_NODES_ERROR,
  GET_ALL_GROUPTYPE_NODES_PENDING,
  GET_ALL_GROUPTYPE_NODES_SUCCESS,
  GET_ALL_NODEGROUPTYPE_ERROR,
  GET_ALL_NODEGROUPTYPE_PENDING,
  GET_ALL_NODEGROUPTYPE_SUCCESS,
  GET_SPECIFIC_NODE_ERROR,
  GET_SPECIFIC_NODE_PENDING,
  GET_SPECIFIC_NODE_SUCCESS,
} from "../type";

const initialState = {
  allNodeGroupTypes: [],
  loading: null,
  error: null,
  message: "",
  allNodes: {},
  specificNode: {},
  Alert: {
    isOpen: false,
    message: "",
    type: "",
  },
};

export const getAllNodeGroupTypesReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_ALL_NODEGROUPTYPE_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_NODEGROUPTYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        allNodeGroupTypes: payload,
        error: null,
        Alert: payload,
      };
    case GET_ALL_NODEGROUPTYPE_ERROR:
      return {
        ...state,
        loading: false,
        allNodeGroupTypes: [],
        error: payload,
        Alert: payload,
      };
    default:
      return state;
  }
};

export const getAllNodeOfGroupReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_ALL_GROUPTYPE_NODES_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_GROUPTYPE_NODES_SUCCESS:
      return {
        ...state,
        loading: false,
        allNodes: payload,
        error: null,
      };
    case GET_ALL_GROUPTYPE_NODES_ERROR:
      return {
        ...state,
        loading: false,
        allNodes: {},
        error: payload,
      };
    default:
      return state;
  }
};

export const getSpecificNodeReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_SPECIFIC_NODE_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_SPECIFIC_NODE_SUCCESS:
      return {
        ...state,
        loading: false,
        specificNode: payload,
        error: null,
      };
    case GET_SPECIFIC_NODE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
