import request from "../redux/services/request";

export const getAllNpdeGroupTypes = () => {
  return request({
    url: `/node_group_type`,
    method: "GET",
  });
};

export const getAllNodesOfGroup = (NodeGroupName) => {
  return request({
    url: `/node_group_type/${NodeGroupName}`,
    method: "GET",
  });
};

export const getSpecificNodeType = (NodeName) => {
  return request({
    url: `/node_type/${NodeName}`,
    method: "GET",
  });
};
