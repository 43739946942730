import { CLOSE_SNACKBAR, OPEN_SNACKBAR } from "../type";

export const openGlobalSnackbar = (message, error, type, duration = 6000) => {
  if (!message || message === undefined) {
    message = "Operation has failed, please try again.";
    error = true;
  }
  return {
    type: OPEN_SNACKBAR,
    payload: { message, duration, error, type },
  };
};

export const closeGlobalSnackbar = () => {
  return {
    type: CLOSE_SNACKBAR,
  };
};
