import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import "./Ipopup.scss";
import IButton from "../../themeComponents/button/index";

export default function IPopup(props) {
  const [open] = useState(props.deleteNodeState);

  return (
    <Dialog
      onClose={props.closedeleteNodePopupFunction}
      open={open}
      PaperProps={{
        style: { borderRadius: 8, padding: "0px" },
      }}
    >
      <DialogContent className={`${props.DialogeContainer}`}>
        <div className="title_area">
          {props.title ? (
            <>
              <div className={`model_title ${props.titleClass}`}>
                {props.title}
              </div>
              {props.subtitle ? (
                <div className={`model_title ${props.subtitleClass}`}>
                  {props.subtitle}
                </div>
              ) : null}
            </>
          ) : null}
        </div>
        <div className={"model_body"}>
          {props.body ? (
            <>
              <div className="body-area">{props.body}</div>
            </>
          ) : null}
        </div>
      </DialogContent>
      <DialogActions className={`dialoge-action ${props.dialogAction}`}>
        {props.actionButtons
          ? props.actionButtons.map((item, idx) => {
              return (
                <IButton
                  key={idx}
                  handleClick={
                    item.function
                      ? item.function
                      : props.closedeleteNodePopupFunction
                  }
                  type={item.type}
                  customClass={item.customClass ? item.customClass : ""}
                >
                  {item.name}
                </IButton>
              );
            })
          : null}
      </DialogActions>
    </Dialog>
  );
}
