import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import "./pieChart.scss";

const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];
const data1 = [{ name: "Group A", value: 100 }];
const COLORS = ["#00C49F", "#EE5353", "#FF8042", "#FFBB28", "#0088FE"];

export default function Donut({ pieData }) {
  return (
    <div className="pie-main">
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={76} height={76}>
          <Pie
            data={pieData}
            innerRadius={27}
            outerRadius={35}
            fill="#F0F5F6"
            paddingAngle={0}
            dataKey="value"
            stroke=""
            startAngle={270}
            endAngle={630}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Pie
            data={data1}
            innerRadius={20}
            outerRadius={28}
            fill="#F0F5F6"
            paddingAngle={0}
            dataKey="value"
            stroke=""
          ></Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
