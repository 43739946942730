import React from "react";
import Card from "../../commonComponents/card";
import Table from "../../themeComponents/table/pipelineTable";
import "./pipelines.scss";

const cardData = [
  {
    id: 1,
    title: "Active Deployments",
    arr: [{ name: "total deployments", value: 11 }],
  },
  {
    id: 2,
    title: "Serivce Health Summary",
    arr: [{ name: "passing", value: 11 }],
  },
  {
    id: 3,
    title: "Data Drift Summary",
    arr: [
      { name: "passing", value: 2 },
      { name: "at risk", value: 3 },
      { name: "failing", value: 7 },
    ],
  },
  {
    id: 4,
    title: "Accuracy Summary",
    arr: [
      { name: "passing", value: 6 },
      { name: "failing", value: 1 },
    ],
  },
];

const Pipelines = () => {
  return (
    <div className="pipelines">
      <div className="cards">
        {cardData.map((item) => {
          return <Card key={item.id} title={item.title} pieData={item.arr} />;
        })}
      </div>
      {/* <div> */}
      <Table />
      {/* </div> */}
    </div>
  );
};

export default Pipelines;
