/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, memo, Fragment } from "react";
import { Box } from "@mui/material";
import "./visualization.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchConfigurationConstraintsAction,
  getColumnNameandTypeAction,
  getVisualizationGroupsAction,
  createEmptyVisualizationGraphAction,
  updateGraphAction,
  getChartDataAction,
} from "../../../redux/actions/visualizationAction";
import { getVisualizationGroupName } from "../../../api/visualizationApi";
import HistogramGraphIcons from "../../../assets/HistogramGraphIcons.png";
import HistogramLines from "../../../assets/LineGraphIcons.png";
import { ArrowBack } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import VisualizationSidebar from "../../themeComponents/sidebar/visualizationSidebar";
import WorkSpaceHeader from "../../themeComponents/header/workSpaceHeader";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import VisualizationFilterSidebar from "../../themeComponents/sidebar/visualizationSidebar/visualizationFilterSidebar";
import VisualizationHeader from "../../themeComponents/header/visualizationHeader";
import VisualizationContainer from "../../themeComponents/container/visualizationContainer";
import VisualizationPopover from "../../themeComponents/popover/visualizationPopover";
import { fetchNodeDetailsAction } from "../../../redux/actions/nodeAction";
import { useMatch } from "react-router-dom";

function VisualizationDashboard({ columnsData, columnsList, children }) {
  const dispatch = useDispatch();
  const [showGraph, setShowGraph] = useState(true);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [visualizationdata, setVisualizationData] = useState([]);
  const [YContainer, setYContiner] = useState([]);
  const [XContainer, setXContiner] = useState([]);
  const [graphType, setGraphType] = useState("");
  const [CContainer, setCContiner] = useState([]);
  const [constraintYValues, setConstraintYValues] = useState([]);
  const [constraintXValues, setConstraintXValues] = useState([]);
  const [constraintCValues, setConstraintCValues] = useState([]);
  const [MaxDisplayValue, setMaxDisplayValue] = useState("");
  const [NoOfBins, setNoOfBins] = useState("");
  const [adjustBin, setAdjustBin] = useState(false);
  const [groupExtraValue, setGroupExtraValue] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [savedCharts, setSavedCharts] = useState([]);
  const navigate = useNavigate();
  const params = useParams();

  const VisualizationGrouptype = useSelector(
    (state) =>
      state.getVisualizationGroupsReducer.VisualizationGroup
        .visualization_group_types
  );

  const Columns = useSelector(
    (state) => state.getColumnNameandTypeReducer.columns.columns
  );

  const ConfigurationConstraints = useSelector(
    (state) =>
      state.fetchConfigurationConstraintsReducer.configurationConstraints
  );
  const newGraphDetails = useSelector(
    (state) => state.createEmptyVisualizationGraphReducer.newEmptyGraph
  );

  const fetchNodeDeatils = useSelector(
    (state) => state.fetchNodeDetailsReducer.nodeDetails
  );

  const match = useMatch("app/workspace/:canvasId/node/:nodeId/visualization");
  const matchVis = useMatch(
    "app/workspace/:canvasId/node/:nodeId/visualization/:visId"
  );

  useEffect(() => {
    let node_id = params.nodeId;
    let canvasId = params.canvasId;
    let vis_id = params.visId;
    if (window.location.pathname.includes(vis_id)) {
      navigate(`/app/workspace/${canvasId}/node/${node_id}/visualization`);
    }
    dispatch(fetchNodeDetailsAction(node_id));
    dispatch(getColumnNameandTypeAction(node_id));
    dispatch(getVisualizationGroupsAction());
  }, []);

  const constraintInsertingFunction = (obj) => {
    if (
      obj.x_config &&
      Object.keys(obj.x_config).length > 0 &&
      obj.x_config.column_name
    ) {
      setXContiner([obj.x_config.column_name]);
    } else {
      setXContiner([]);
    }
    if (
      obj &&
      obj.y_config &&
      Object.keys(obj.y_config).length > 0 &&
      obj.y_config.column_name
    ) {
      setYContiner([obj.y_config.column_name]);
    } else {
      setYContiner([]);
    }
    if (
      obj &&
      obj.colour_config &&
      Object.keys(obj.colour_config).length > 0 &&
      obj.colour_config.column_name
    ) {
      setCContiner([obj.colour_config.column_name]);
    } else {
      setCContiner([]);
    }
  };

  useEffect(() => {
    if (
      fetchNodeDeatils &&
      fetchNodeDeatils.visualizations &&
      fetchNodeDeatils.visualizations.length > 0
    ) {
      setSavedCharts(fetchNodeDeatils);
      setShowGraph(false);
      let canvasId = params.canvasId;
      let nodeId = params.nodeId;
      window.localStorage.setItem(
        "graph_type",
        fetchNodeDeatils.visualizations[0].visualization_type
      );
      dispatch(
        fetchConfigurationConstraintsAction(
          fetchNodeDeatils.visualizations[0].visualization_type.toUpperCase()
        )
      );
      navigate(
        `/app/workspace/${canvasId}/node/${nodeId}/visualization/${fetchNodeDeatils.visualizations[0].visualization_id}`
      );
      constraintInsertingFunction(fetchNodeDeatils.visualizations[0]);
    }
  }, [fetchNodeDeatils]);

  useEffect(() => {
    if (VisualizationGrouptype) {
      let urls = [];
      urls = VisualizationGrouptype.map((item) => {
        return getVisualizationGroupName(`${Object.keys(item)[0]}`);
      });
      Promise.all(urls).then(function (values) {
        setVisualizationData(values);
      });
    }
  }, [VisualizationGrouptype]);

  const selectParticularGraph = (e) => {
    let node_id = params.nodeId;
    window.localStorage.setItem("graph_type", e.toUpperCase());
    setGraphType(e);
    setShowGraph(false);
    dispatch(fetchConfigurationConstraintsAction(e.toUpperCase()));
    dispatch(
      createEmptyVisualizationGraphAction(node_id, {
        visualization_type: e.toUpperCase(),
      })
    );
    setIsSelected(true);
  };

  const onDragStart = (event) => {
    event.dataTransfer.setData("text", event.target.childNodes[2].innerHTML);
    event.dataTransfer.effectAllowed = "move";
  };
  function allowDrop(ev) {
    ev.preventDefault();
  }
  const dropY = (ev) => {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    const arr = [];
    arr.push(data);
    setYContiner(YContainer.concat(arr));
  };
  const dropX = (ev) => {
    ev.preventDefault();
    const arr = [];
    if (XContainer.length === 0) {
      var data = ev.dataTransfer.getData("text");
      arr.push(data);
      setXContiner(XContainer.concat(arr));
    }
  };
  const dropC = (ev) => {
    ev.preventDefault();
    const arr = [];
    if (CContainer.length === 0) {
      var data = ev.dataTransfer.getData("text");
      arr.push(data);
      setCContiner(CContainer.concat(arr));
    }
  };

  const removeItemY = (e) => {
    setYContiner(
      YContainer.filter(
        (item) => item !== e.currentTarget.previousSibling.innerHTML
      )
    );
    setConstraintYValues(
      constraintYValues.filter((item) => {
        return (
          Object.keys(item)[0].trim() !==
          e.currentTarget.previousSibling.innerHTML.trim()
        );
      })
    );
  };
  const removeItemX = (e) => {
    setXContiner(
      XContainer.filter(
        (item) => item !== e.currentTarget.previousSibling.innerHTML
      )
    );
    setNoOfBins("");
    setAdjustBin(false);
    setConstraintXValues(
      constraintXValues.filter((item) => {
        return (
          Object.keys(item)[0] !== e.currentTarget.previousSibling.innerHTML
        );
      })
    );
  };
  const removeItemC = (e) => {
    setCContiner(
      CContainer.filter(
        (item) => item !== e.currentTarget.previousSibling.innerHTML
      )
    );
    setMaxDisplayValue("");
    setGroupExtraValue(false);
    setConstraintCValues(
      constraintCValues.filter((item) => {
        return (
          Object.keys(item)[0] !== e.currentTarget.previousSibling.innerHTML
        );
      })
    );
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPopUp(true);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenPopUp(false);
  };

  const handleChangeYConstrain = (e) => {
    let ObjectName = anchorEl.nextSibling.innerText;
    let ObjectVal = {};
    ObjectVal[e.target.name] = e.target.value;
    let finalObj = {};
    finalObj[ObjectName] = ObjectVal;
    if (constraintYValues.length === 0) {
      return setConstraintYValues((constraintYValues) => [
        ...constraintYValues,
        finalObj,
      ]);
    } else {
      constraintYValues.forEach((val) => {
        if (Object.keys(val)[0] === Object.keys(finalObj)[0]) {
          Object.values(val).map((item, index) => {
            return Object.keys(item).map((it) => {
              if (it === Object.keys(Object.values(finalObj)[0])[0]) {
                return (item = Object.values(finalObj)[0]);
              } else {
                return (Object.values(val)[0][
                  Object.keys(Object.values(finalObj)[0])[0]
                ] = Object.values(Object.values(finalObj)[0])[0]);
              }
            });
          });
        }
      });
      let filt = constraintYValues.filter((item) => {
        return Object.keys(item)[0] === Object.keys(finalObj)[0];
      });
      if (filt.length === 0) {
        setConstraintYValues((constraintYValues) => [
          ...constraintYValues,
          finalObj,
        ]);
      }
    }
  };
  const handleChangeXConstrain = (e) => {
    let ObjectName = anchorEl.nextSibling.innerText;
    let ObjectVal = {};
    ObjectVal[e.target.name] = e.target.value;
    let finalObj = {};
    finalObj[ObjectName] = ObjectVal;
    if (constraintXValues.length === 0) {
      setConstraintXValues((constraintXValues) => [
        ...constraintXValues,
        finalObj,
      ]);
    } else {
      constraintXValues.forEach((val) => {
        if (Object.keys(val)[0] === Object.keys(finalObj)[0]) {
          Object.values(val).forEach((item) => {
            if (
              Object.keys(item)[0] ===
              Object.keys(Object.values(finalObj)[0])[0]
            ) {
              val[Object.keys(val)[0]] = Object.values(finalObj)[0];
            } else {
              Object.values(val)[0][
                Object.keys(Object.values(finalObj)[0])[0]
              ] = Object.values(Object.values(finalObj)[0])[0];
            }
          });
        } else {
          setConstraintXValues((constraintYValues) => [
            ...constraintXValues,
            finalObj,
          ]);
        }
      });
    }
  };
  const handleChangeCConstrain = (e) => {
    let ObjectName = anchorEl.nextSibling.innerText;
    let ObjectVal = {};
    ObjectVal[e.target.name] = e.target.value;
    let finalObj = {};
    finalObj[ObjectName] = ObjectVal;
    if (constraintCValues.length === 0) {
      setConstraintCValues((constraintCValues) => [
        ...constraintCValues,
        finalObj,
      ]);
    } else {
      constraintCValues.forEach((val) => {
        if (Object.keys(val)[0] === Object.keys(finalObj)[0]) {
          Object.values(val).forEach((item) => {
            if (
              Object.keys(item)[0] ===
              Object.keys(Object.values(finalObj)[0])[0]
            ) {
              val[Object.keys(val)[0]] = Object.values(finalObj)[0];
            } else {
              Object.values(val)[0][
                Object.keys(Object.values(finalObj)[0])[0]
              ] = Object.values(Object.values(finalObj)[0])[0];
            }
          });
        } else {
          setConstraintCValues((constraintYValues) => [
            ...constraintCValues,
            finalObj,
          ]);
        }
      });
    }
  };

  const onPublish = () => {
    let node_id = params.nodeId;
    let vis_id = params.visId;
    dispatch(getChartDataAction(node_id, vis_id));
  };
  useEffect(() => {
    let canvasId = params.canvasId;
    let nodeId = params.nodeId;
    let vis_Obj =
      newGraphDetails &&
      newGraphDetails.visualizations &&
      newGraphDetails.visualizations.length > 0 &&
      newGraphDetails.visualizations.slice(-1);
    let vis_id = vis_Obj && vis_Obj[0].visualization_id;
    if (
      vis_id !== undefined &&
      window.location.pathname.split("/").pop() !== vis_id &&
      isSelected
    ) {
      setShowGraph(false);
      navigate(
        `/app/workspace/${canvasId}/node/${nodeId}/visualization/${vis_id}`
      );
      setSavedCharts(newGraphDetails);
    }
    return () => {
      setIsSelected(false);
    };
  }, [newGraphDetails]);

  useEffect(() => {
    let graphtype = window.localStorage.getItem("graph_type");
    let sorting =
      constraintCValues &&
      constraintCValues[0] &&
      Object.values(constraintCValues[0])[0] &&
      Object.values(Object.values(constraintCValues[0])[0])[0];

    let bodyObject = {
      type: graphtype,
      y_config:
        YContainer.length > 0
          ? {
              column_name:
                constraintYValues &&
                constraintYValues[0] &&
                Object.keys(constraintYValues[0])[0]
                  ? Object.keys(constraintYValues[0])[0]
                  : YContainer[0],
              numerical:
                constraintYValues &&
                constraintYValues[0] &&
                Object.values(constraintYValues[0])[0]
                  ? Object.values(constraintYValues[0])[0]
                  : {},
            }
          : {},
      x_config:
        XContainer.length > 0
          ? {
              column_name:
                constraintXValues &&
                constraintXValues[0] &&
                Object.keys(constraintXValues[0])[0]
                  ? Object.keys(constraintXValues[0])[0]
                  : XContainer[0],
              numerical: {
                "Fixed Number of Equal": {
                  adjust_bin_size_for_nicer_bounds: adjustBin,
                  number_of_bins: NoOfBins,
                },
              },
            }
          : {},
      colour_config:
        CContainer.length > 0
          ? {
              column_name:
                constraintCValues &&
                constraintCValues[0] &&
                Object.keys(constraintCValues[0])[0]
                  ? Object.keys(constraintCValues[0])[0]
                  : CContainer[0],
              string: {
                sorting: `${sorting}`,
                max_displayed_values: MaxDisplayValue,
                group_extra_values_into_others: groupExtraValue,
              },
            }
          : {},
    };

    if (!openPopUp && matchVis !== null) {
      const nodeId = params.nodeId;
      const visId = params.visId;
      dispatch(updateGraphAction(nodeId, visId, bodyObject));
    }
  }, [constraintXValues, constraintYValues, constraintCValues, openPopUp]);

  const onCloseGraphs = () => {
    let canvasId = params.canvasId;
    let nodeId = params.nodeId;
    if (showGraph) {
      navigate(`/app/workspace/${canvasId}/node/${nodeId}/explore`);
    } else {
      setShowGraph(true);
      navigate(`/app/workspace/${canvasId}/node/${nodeId}/visualization`);
      setXContiner([]);
      setCContiner([]);
      setYContiner([]);
      setConstraintXValues([]);
      setConstraintCValues([]);
      setConstraintCValues([]);
    }
  };

  const changeVisId = (obj) => {
    let canvasId = params.canvasId;
    let nodeId = params.nodeId;
    window.localStorage.setItem("graph_type", obj.visualization_type);
    if (
      String(matchVis && matchVis.params && matchVis.params.visId) !==
      String(obj && obj.visualization_id)
    ) {
      dispatch(
        fetchConfigurationConstraintsAction(
          obj.visualization_type.toUpperCase()
        )
      );
      navigate(
        `/app/workspace/${canvasId}/node/${nodeId}/visualization/${obj.visualization_id}`
      );
    }
    constraintInsertingFunction(obj);
  };

  return (
    <>
      <VisualizationPopover
        openPopUp={openPopUp}
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
        ConfigurationConstraints={ConfigurationConstraints}
        handleChangeCConstrain={handleChangeCConstrain}
        handleChangeXConstrain={handleChangeXConstrain}
        handleChangeYConstrain={handleChangeYConstrain}
        setAdjustBin={setAdjustBin}
        setGroupExtraValue={setGroupExtraValue}
        setMaxDisplayValue={setMaxDisplayValue}
        groupExtraValue={groupExtraValue}
        setNoOfBins={setNoOfBins}
        NoOfBins={NoOfBins}
        adjustBin={adjustBin}
        MaxDisplayValue={MaxDisplayValue}
      />

      <Box className={"mainCardContainer"}>
        <Box component="div" className="line">
          <Box component="div" className="line1"></Box>
        </Box>
        <Box component="div" className="dataset-container">
          <WorkSpaceHeader />
          <Box className="visual-tableData-container">
            <VisualizationSidebar
              Columns={Columns}
              onDragStartColumn={(e) => onDragStart(e)}
            />
            <Box className="visual-tableData-right-container">
              <Box className="graph-container">
                <Box>
                  <Box className="graph-box-selector" onClick={onCloseGraphs}>
                    {match ? null : <ArrowDropDownIcon className="drop-icon" />}{" "}
                    {match !== null ? (
                      <ArrowBack />
                    ) : (
                      <img
                        className="graph-box-selector-img"
                        src={
                          graphType === "Histrogram"
                            ? HistogramGraphIcons
                            : graphType === "Lines"
                            ? HistogramLines
                            : HistogramGraphIcons
                        }
                        alt="graph icons"
                      />
                    )}
                  </Box>
                  {match === null ? (
                    <VisualizationFilterSidebar
                      changeVisId={(e) => changeVisId(e)}
                      savedCharts={savedCharts}
                    />
                  ) : null}
                </Box>

                {match !== null ? (
                  <>
                    <VisualizationContainer
                      VisualizationGrouptype={VisualizationGrouptype}
                      visualizationdata={visualizationdata}
                      selectParticularGraph={selectParticularGraph}
                    />
                  </>
                ) : (
                  <Box className="draw-graph-and-axis-container">
                    <VisualizationHeader
                      CContainer={CContainer}
                      YContainer={YContainer}
                      XContainer={XContainer}
                      allowDrop={allowDrop}
                      dropY={dropY}
                      dropX={dropX}
                      dropC={dropC}
                      removeItemY={removeItemY}
                      removeItemX={removeItemX}
                      removeItemC={removeItemC}
                      handlePopoverOpen={handlePopoverOpen}
                      onPublish={onPublish}
                    />
                    {/* {children} */}
                    <Outlet />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default memo(VisualizationDashboard);
