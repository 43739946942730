import React from "react";
import { Box, Typography } from "@mui/material";
import { LensBlur, NumbersOutlined } from "@mui/icons-material";
import "./visualizationSidebar.scss";
import ISearchDefault from "../../search/defaultSearch";

export default function VisualizationSidebar({ Columns, onDragStartColumn }) {
  return (
    <Box className="visual-tableData-left-container">
      <Box className="search-sort-button">
        <Typography className={"search-sort-button-columns"}>
          Columns
        </Typography>
        <Typography className={"search-sort-button-other"}>
          Sampling & Engine
        </Typography>
      </Box>
      {/* search box component */}
      <ISearchDefault />

      {/* all columns conatiner */}
      <Box className="all-columns-conatiner">
        {Columns &&
          Columns.map((column, index) => {
            return (
              <div
                key={index}
                className="all-columns-conatiner-item"
                draggable="true"
                onDragStart={(event) => onDragStartColumn(event)}
              >
                {" "}
                {column.inferred_type === "integer" ||
                column.inferred_type === "number" ? (
                  <NumbersOutlined className="item-icon" />
                ) : (
                  <LensBlur className="item-icon-other" />
                )}
                <div>{`${column.name}`}</div>
              </div>
            );
          })}
      </Box>
    </Box>
  );
}
