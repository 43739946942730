import request from "../redux/services/request";

export const getColumnNameandType = (node_id) => {
  return request({
    url: `/node/${node_id}/columns`,
    method: "GET",
  });
};

export const getVisualizationGroupName = (visualization_group_name) => {
  return request({
    url: `visualization_groups/${visualization_group_name}`,
    method: "GET",
  });
};

export const getVisualizationGroups = () => {
  return request({
    url: `visualization_groups/`,
    method: "GET",
  });
};

export const createEmptyVisualizationGraph = (node_id, data) => {
  return request({
    url: `/node/${node_id}/visualization`,
    method: "POST",
    data,
  });
};

export const fetchConfigurationConstraints = (visualization_type) => {
  return request({
    url: `/visualization_type/${visualization_type}`,
    method: "GET",
  });
};

export const updateGraph = (node_id, visualization_id, data) => {
  return request({
    url: `/node/${node_id}/visualization/${visualization_id}`,
    method: "PUT",
    data,
  });
};

export const getChartData = (node_id, visualization_id) => {
  return request({
    url: `/node/${node_id}/visualization/${visualization_id}/data`,
    method: "GET",
  });
};
