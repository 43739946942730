import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import WorkSpaceHeader from "../../themeComponents/header/workSpaceHeader";
import "./statistics.scss";
import SettingsIcon from "@mui/icons-material/Settings";
import { ArrowDropDown } from "@mui/icons-material";
import StatisticsPopup from "../../themeComponents/popups/statisticsPopup";

const headerData = [
  {
    title: "Worksheet",
    icon: false,
    value: "",
  },
  {
    title: "Sampling and filtering",
    icon: false,
    value: "",
  },
  {
    title: "Confidence level",
    icon: false,
    value: "95%",
  },
  {
    title: "",
    icon: SettingsIcon,
    value: "",
  },
];

export default function Statistics() {
  const [showHeaderBtn, setShowHeaderBtn] = useState(false);

  return (
    <Box className={"mainCardContainer"}>
      <Box component="div" className="line">
        <Box component="div" className="line1"></Box>
      </Box>
      <Box component="div" className="dataset-container">
        <WorkSpaceHeader />
        <Box className="statistis-container">
          <Box className="statistics-contaniner-header">
            <Box className="statistics-contaniner-header-content">
              {headerData.map((item, index) => {
                return (
                  <Box
                    className="statistics-contaniner-header-item"
                    key={index}
                  >
                    <Typography className="statistics-contaniner-header-item-title">
                      {item.title}{" "}
                      <span className="statistics-contaniner-header-item-title-value">
                        {item.value ? `${item.value}` : null}
                      </span>{" "}
                      <span> </span>
                    </Typography>
                    {item.icon ? (
                      <item.icon className="statistics-contaniner-header-item-icon" />
                    ) : null}
                    <ArrowDropDown className="statistics-contaniner-header-item-icon" />
                  </Box>
                );
              })}
            </Box>
            {showHeaderBtn ? (
              <StatisticsPopup
                className={"statistics-contaniner-header-btn"}
                onShowHeaderBtn={(e) => setShowHeaderBtn(e)}
              />
            ) : null}
          </Box>
          <Box className="statistics-contaniner-content">
            {!showHeaderBtn && (
              <StatisticsPopup
                className={"statistics-contaniner-btn"}
                onShowHeaderBtn={(e) => setShowHeaderBtn(e)}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
