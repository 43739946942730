import React, { Fragment, useEffect, useState } from "react";
import IButton from "../../button";
import AddIcon from "@mui/icons-material/Add";
import "./statisticsPopup.scss";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { ArrowBack, Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getColumnNameandTypeAction } from "../../../../redux/actions/visualizationAction";
import addIcon from "../../../../assets/icons/addIcon.svg";
import deleteIcon from "../../../../assets/icons/deleteWhite.svg";
import deleteRed from "../../../../assets/icons/deleteRed.svg";
import ISearchDefault from "../../search/defaultSearch";
import { LensBlur, NumbersOutlined, TipsAndUpdates } from "@mui/icons-material";
import IRadio from "../../radio";
import IInput from "../../input";

const statisticsCardData = {
  statistics: [
    {
      key: "UNIVARIATE_ANALYSIS",
      display_name: "Univariate analysis",
      description:
        "Discover your variables and see thei distributions side by side",
    },
    {
      key: "BIVARIATE_ANALYSIS",
      display_name: "Bivariate analysis",
      description:
        "Look into the relationship between pairs of variables simultaneously",
    },
    {
      key: "STATISTICAL_TESTS",
      display_name: "Statistical tests",
      description: "Make quantitative decisons by testing hypotheses.",
    },
    {
      key: "FIT_CURVES_AND_DISTRIBUTIONS",
      display_name: "Fit curves & Distributions",
      description: "Model variable distributions or relationships",
    },
    {
      key: "CORRELATION_MATRIX",
      display_name: "Correlation Matrix",
      description:
        "Examine the relationship of multiple variables at the same time",
    },
    {
      key: "PRINCIPAL_COMPONENT_ANALYSIS",
      display_name: "Principal component analysis",
      description:
        "Reduce a large set of variables into a smaller set of components",
    },
  ],
};

export default function StatisticsPopup({ onShowHeaderBtn, className }) {
  const [open, setOpen] = useState(false);
  const [showPca, setShowPca] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [createCard, setCreateCard] = useState(false);
  const [droppedItems, setDroppedItems] = useState([]);
  const [options, setOptions] = useState([]);
  const params = useParams();
  const dispatch = useDispatch();

  const Columns = useSelector(
    (state) => state.getColumnNameandTypeReducer.columns.columns
  );

  useEffect(() => {
    let node_id = params.nodeId;
    dispatch(getColumnNameandTypeAction(node_id));

    return () => {
      setShowPca(false);
      setCreateCard(false);
      setDroppedItems([]);
    };
  }, [dispatch, params.nodeId]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setShowPca(false);
    setCreateCard(false);
  };
  const onCardClick = (item) => {
    setShowPca(true);
    setPopupTitle(item.display_name);
    let it = item;
    it.options = ["Spearman", "Pearson"];
    setOptions(item);
  };

  const handleCloseSecond = () => {
    setShowPca(false);
    setCreateCard(false);
  };
  const handleCloseThird = () => {
    setCreateCard(false);
  };
  const onGoBackPopup = () => {
    if (showPca && createCard) {
      setCreateCard(false);
    } else if (showPca && !createCard) {
      setShowPca(false);
    }
  };

  const onDragStartColumn = (e) => {
    e.dataTransfer.setData("text", e.target.childNodes[2].innerHTML);
    e.dataTransfer.effectAllowed = "move";
  };
  function allowDrop(ev) {
    ev.preventDefault();
  }

  const dropVariable = (ev) => {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    const arr = [];
    arr.push(data);
    setDroppedItems(droppedItems.concat(arr));
  };

  const deleteVariable = (id) => {
    const filterdItems = droppedItems.filter((item, idx) => {
      let it;
      if (idx !== id) {
        it = item;
      }
      return it;
    });
    setDroppedItems(filterdItems);
  };

  const onApply = () => {
    setCreateCard(true);
  };

  const onCreateCard = () => {
    setOpen(false);
    setShowPca(false);
    setCreateCard(false);
    setDroppedItems([]);
    onShowHeaderBtn(true);
  };

  const onOptionsChange = (e) => {
    console.log(e);
  };

  return (
    <>
      <IButton
        type={"primary"}
        customClass={className}
        handleClick={handleClickOpen}
      >
        <AddIcon /> New card
      </IButton>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={showPca && createCard ? "sm" : "md"}
        classes={{
          paper: "paper-statistics",
          root: "root-statistics",
        }}
      >
        <DialogTitle sx={{ padding: 0 }}>
          <Box className="s-popup-title-container">
            {!showPca ? (
              <>
                <Typography className="s-popup-title">
                  Select a card type
                </Typography>
                <Typography className="s-popup-sub-title">
                  Fill out the details below to add a property.
                </Typography>
              </>
            ) : (
              <Box className="s-popup-pca-container">
                <ArrowBack
                  onClick={onGoBackPopup}
                  className="s-popup-pca-icon"
                />

                <Typography className="s-popup-pca-title">
                  {popupTitle}
                </Typography>
              </Box>
            )}
            <IconButton onClick={handleClose} className={"s-popup-close"}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <Box
            component={"div"}
            className={
              showPca
                ? "s-popup-content-container-two"
                : "s-popup-content-container"
            }
          >
            {!showPca && !createCard ? (
              statisticsCardData &&
              statisticsCardData.statistics &&
              statisticsCardData.statistics.map((item, index) => {
                return (
                  <Box
                    key={item.key}
                    component={"div"}
                    className="s-popup-content-card"
                    onClick={() => onCardClick(item)}
                  >
                    <Typography className="s-popup-content-card-title">
                      {item.display_name}
                    </Typography>
                    <Typography className="s-popup-content-card-content">
                      {item.description}
                    </Typography>
                  </Box>
                );
              })
            ) : (
              <>
                {showPca && !createCard ? (
                  <>
                    <Box className="s-popup-second-content-card">
                      <Typography className="s-popup-second-content-sub-title">
                        1. Select variables
                      </Typography>
                      <Typography className="s-popup-second-content-title">
                        {Columns && Columns.length} available variables
                      </Typography>
                      <Box className="s-popup-second-content-box">
                        <ISearchDefault width={"255px"} />
                        <Box className="all-columns-conatiner">
                          {Columns &&
                            Columns.map((column, index) => {
                              return (
                                <div
                                  key={index}
                                  className="all-columns-conatiner-item"
                                  draggable="true"
                                  onDragStart={(event) =>
                                    onDragStartColumn(event)
                                  }
                                  id={index}
                                >
                                  {" "}
                                  {column.inferred_type === "integer" ||
                                  column.inferred_type === "number" ? (
                                    <NumbersOutlined className="item-icon" />
                                  ) : (
                                    <LensBlur className="item-icon-other" />
                                  )}
                                  <div>{`${column.name}`}</div>
                                </div>
                              );
                            })}
                        </Box>
                        <Box className="s-popup-second-content-box-tips">
                          <TipsAndUpdates className="s-popup-second-content-box-tips-icon" />
                          <Typography className="s-popup-second-content-box-tips-text">
                            Use <b>Shift + Click</b> to select a range of
                            variables.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="s-popup-second-content-card">
                      <Typography className="s-popup-second-content-sub-title">
                        2. Add variables
                      </Typography>

                      <Box className="s-popup-title-icons-container">
                        <div>
                          <Typography className="s-popup-second-content-title">
                            {droppedItems.length} selected variables
                          </Typography>
                        </div>
                        <div className="s-popup-title-icons">
                          <img
                            src={addIcon}
                            alt="add"
                            className="s-popup-title-icon"
                          />
                          <img
                            src={deleteIcon}
                            alt="delete"
                            className="s-popup-title-icon"
                          />
                        </div>
                      </Box>

                      <Box
                        className="s-popup-second-content-box-two"
                        onDrop={(e) => dropVariable(e)}
                        onDragOver={(event) => allowDrop(event)}
                      >
                        {droppedItems.length === 0 ? (
                          <Typography className="s-popup-drag-text">
                            Drag variable(s) here...
                          </Typography>
                        ) : (
                          <Box className="s-popup-drag-items">
                            {droppedItems.map((item, index) => {
                              return (
                                <Box
                                  key={index + item}
                                  className="s-popup-drag-item"
                                >
                                  <Typography className="s-popup-drag-item-title">
                                    {item}
                                  </Typography>
                                  <img
                                    src={deleteIcon}
                                    className="s-popup-drag-item-icon"
                                    alt="delete"
                                    onClick={() => deleteVariable(index)}
                                  />
                                </Box>
                              );
                            })}
                          </Box>
                        )}
                      </Box>
                    </Box>
                    <Box className="s-popup-second-content-card">
                      {droppedItems.length > 0 ? (
                        <>
                          <Typography className="s-popup-second-content-sub-title">
                            3. Select options
                          </Typography>
                          <Typography className="s-popup-second-content-title">
                            Options
                          </Typography>
                          <Box className="s-popup-second-content-box">
                            <FormControl>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={""}
                                name="radio-buttons-group"
                              >
                                {options &&
                                  options.options.map((item, index) => {
                                    return (
                                      <Fragment key={index}>
                                        <FormControlLabel
                                          classes={{
                                            label: "radio-label",
                                          }}
                                          value={item}
                                          control={
                                            <IRadio
                                              onChange={(e) =>
                                                onOptionsChange(e)
                                              }
                                            />
                                          }
                                          label={item}
                                        />
                                      </Fragment>
                                    );
                                  })}
                              </RadioGroup>
                            </FormControl>
                          </Box>
                        </>
                      ) : null}
                    </Box>
                  </>
                ) : (
                  <>
                    {showPca && createCard ? (
                      <Box className="s-popup-third-content-container">
                        <Box className="s-popup-third-content">
                          <Typography className="s-popup-third-content-title">
                            Variable
                          </Typography>
                          <IInput
                            isSelect={true}
                            type={"text"}
                            label={"Turbidity (NTU) Inlet"}
                            disabled={true}
                            styleProps={{
                              width: "290px",
                            }}
                          />
                        </Box>
                        <Box className="s-popup-third-content">
                          <Typography className="s-popup-third-content-title">
                            Distributions
                          </Typography>
                          <IInput
                            isSelect={true}
                            type={"text"}
                            label={"Choose from a list"}
                            disabled={true}
                            styleProps={{
                              width: "290px",
                            }}
                          />
                        </Box>
                        <Box className="s-popup-third-content-delete">
                          <Box className="s-popup-third-content-delete-items">
                            <Typography className="s-popup-third-content-delete-items-title">
                              Normal
                            </Typography>
                            <img
                              src={deleteRed}
                              className="s-popup-third-content-delete-items-icon"
                              alt="delete"
                            />
                          </Box>
                        </Box>
                      </Box>
                    ) : null}
                  </>
                )}
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: 0 }}>
          {showPca ? (
            <Box className={"s-popup-actions"}>
              <IButton
                handleClick={createCard ? handleCloseThird : handleCloseSecond}
                type={"cancel"}
              >
                Cancel
              </IButton>
              <IButton
                type={
                  droppedItems.length === 0 ? "primary-disabled" : "primary"
                }
                handleClick={createCard ? onCreateCard : onApply}
              >
                {createCard ? "Create card" : "Apply  "}
              </IButton>
            </Box>
          ) : null}
        </DialogActions>
      </Dialog>
    </>
  );
}
