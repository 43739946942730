import {
  CloudDownload,
  FileUpload,
  InsertDriveFile,
} from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import IButton from "../../button";
import IInput from "../../input";
import "./uploadFilePopup.scss";
import deleteIcon from "../../../../assets/icons/deleteRed.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getFileURLAction,
  uploadFileAction,
} from "../../../../redux/actions/nodeAction";

export default function FileUploadPopup() {
  const [open, setOpen] = useState(false);
  const [fileData, setFileData] = useState({});
  const [, setFormData] = useState({});
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const FileResponseURL = useSelector(
    (state) => state.getFileURLReducer.fileURL
  );

  const handleClose = () => {
    setOpen(false);
    setFileData({});
  };

  const handleUpload = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    setFileData({ file });
    dispatch(getFileURLAction(file.name));
  };

  const onDragFile = (e) => {
    e.dataTransfer.setData("file", e);
  };

  const onDragOverFile = (e) => {
    e.preventDefault();
    e.target.style.borderColor = "#3c3996";
    e.target.color = "#8b97a2";
  };

  const onDragLeaveFile = (e) => {
    e.preventDefault();
    e.target.style.borderColor = " #8b97a2";
  };

  const onDragEndFile = (e) => {
    e.preventDefault();
    e.target.style.borderColor = "#8b97a2";
  };

  const onDropFile = (ev) => {
    ev.preventDefault();
    ev.target.style.borderColor = "#8b97a2";
    if (ev.dataTransfer.items) {
      for (var i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === "file") {
          var file = ev.dataTransfer.items[i].getAsFile();
          let formData = new FormData();
          formData.append("file", file);
          setFileData({ file });
          setFormData({ formData });
          //make api call
          dispatch(getFileURLAction(file.name));
        }
      }
    }
  };

  const onDeleteFile = () => {
    setFileData({});
  };

  const onSubmitFile = () => {
    let formData1 = new FormData();
    formData1.append("file", fileData);
    if (FileResponseURL && FileResponseURL.url && fileData) {
      dispatch(uploadFileAction(FileResponseURL.url, fileData));
    }
    setOpen(false);
    setFileData({});
  };

  return (
    <>
      <IButton type={"neutral"} handleClick={handleClickOpen}>
        <FileUpload style={{ marginRight: "5px" }} />
        Upload
      </IButton>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: "paper",
          root: "root",
        }}
      >
        <DialogTitle sx={{ padding: 0 }}>
          <Box className="upload-title-container">
            <Typography className="upload-title">
              Please upload a data source file
            </Typography>
            <Typography className="upload-sub-title">
              Supported file types: *.xls, *.xlsx, *.csv
            </Typography>
          </Box>
        </DialogTitle>

        <DialogContent sx={{ padding: 0 }}>
          <Box className="upload-options">
            <Typography className="upload-options-basic">Basic</Typography>
            <Typography className="upload-options-advanced">
              Advanced
            </Typography>
          </Box>
          <Box
            className="upload-dropzone"
            component={"div"}
            onDrag={onDragFile}
            onDragOver={onDragOverFile}
            onDrop={onDropFile}
            onDragLeave={onDragLeaveFile}
            onDragEnd={onDragEndFile}
          >
            {Object.entries(fileData).length > 0 ? (
              <Box className="upload-file-container">
                <InsertDriveFile className="upload-file-icon" />
                <Typography className="upload-file-name" component={"div"}>
                  {fileData.file.name}
                  <img
                    src={deleteIcon}
                    alt="delete"
                    className="upload-file-delete"
                    onClick={onDeleteFile}
                  />
                </Typography>
              </Box>
            ) : (
              <Box className="upload-dropzone-names">
                <CloudDownload
                  sx={{ fill: "#8B97A2", height: "40px", width: "50px" }}
                />
                <Typography className="upload-dropzone-names-title">
                  Drag & drop
                </Typography>
                <Typography className="upload-dropzone-names-sub-title">
                  your file here, or{" "}
                  <input
                    type="file"
                    name="file"
                    id="file_up"
                    onChange={handleUpload}
                  />{" "}
                </Typography>
              </Box>
            )}
          </Box>
          {Object.entries(fileData).length > 0 ? null : (
            <>
              <Box className="upload-or">
                <Typography className="upload-or-text">or</Typography>
              </Box>
              <Box className="upload-input">
                <IInput
                  isSelect={true}
                  type={"text"}
                  label={"Select a file from another canvas"}
                  disabled={true}
                  styleProps={{
                    width: "300px",
                  }}
                />
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ padding: 0 }}>
          <Box className="upload-action-btns">
            <IButton type="cancel" handleClick={handleClose}>
              Cancel
            </IButton>
            <IButton
              type={
                Object.entries(fileData).length > 0
                  ? "primary"
                  : "primary-disabled"
              }
              handleClick={onSubmitFile}
            >
              Submit
            </IButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
