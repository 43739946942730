import React from "react";
import { Stack, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import "./snackbar.scss";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars({
  isOpen,
  message,
  type,
  handleClose,
}) {
  return (
    <Stack spacing={1} sx={{ width: "100%" }}>
      {isOpen ? (
        <Snackbar
          open={isOpen}
          autoHideDuration={4000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          className={"snackbar-container"}
          style={{ top: "90px", color: "white" }}
        >
          <Alert
            icon={false}
            className={"snackbar_alert"}
            action={
              type === "success" ? (
                <CheckIcon style={{ color: "green" }} />
              ) : (
                <PriorityHighIcon style={{ color: "red" }} />
              )
            }
            onClose={handleClose}
            severity={type}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      ) : null}
    </Stack>
  );
}
