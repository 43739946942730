import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SyncIcon from "@mui/icons-material/Sync";
import SearchBox from "../../themeComponents/search";
import "./workflowsidebar.scss";
import { getAllNodesOfGroup } from "../../../api/nodeGroupApi";

const WorkFlowSidebar = ({ className, nodeGroupTypes, apicallFun }) => {
  const [nodes, setNodes] = useState([]);
  const [rotateChevron, setRotateChevron] = useState(false);

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  useEffect(() => {
    const controller = new AbortController();
    if (nodeGroupTypes) {
      let urls = [];
      urls = Object.entries(nodeGroupTypes).map((item) => {
        return getAllNodesOfGroup(`${item[0]}`);
      });
      Promise.all(urls).then(function (values) {
        setNodes(values);
      });
    }
    return () => {
      controller.abort();
    };
  }, [nodeGroupTypes]);

  const syncAction = () => {
    setRotateChevron(!rotateChevron);
    apicallFun();
  };
  const rotate = rotateChevron ? "rotate(360deg)" : "rotate(0deg)";
  return (
    <div className={`workflow_sidebar_wrapper`}>
      <div className="workflow_description_container">
        <div className="workflow_description">Library</div>
        <button className={`workflow_sync_button-`}>
          <SyncIcon
            className={`sync_icon`}
            style={{
              transform: rotate,
              transition: "all 0.2s linear",
              color: "grey",
            }}
            onClick={syncAction}
          />
        </button>
      </div>
      <SearchBox placeholder={"Search actions..."} />
      <div className={`workflow_library_container`}>
        {nodeGroupTypes &&
          Object.entries(nodeGroupTypes).map((group, i) => {
            return (
              <Accordion disableGutters={true} key={i}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ height: "60px" }}
                >
                  <div>
                    <div className="workflow_library_title">
                      {group[1].display_name}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0px" }}>
                  <div className="workflow_node_container" key={i}>
                    {nodes &&
                      nodes[i] &&
                      // nodes[i].data &&
                      Object.entries(nodes[i]).map((node, idx) => {
                        return (
                          <div
                            className="workflow_node"
                            onDragStart={(event) =>
                              onDragStart(
                                event,
                                JSON.stringify({
                                  groupName: group[0],
                                  nodeName: node[0],
                                })
                              )
                            }
                            draggable
                            key={idx}
                          >
                            <div className="node_image">
                              {/* <BiotechRounded
                                fontSize="large"
                                className="node_image"
                                style={{ opacity: "0.8", color: "#8B97A2" }}
                              /> */}
                              <img
                                src={node[1].image_url}
                                alt={node[1].display_name}
                              />
                            </div>
                            <div className="node_title">
                              {node[1].display_name}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </div>
    </div>
  );
};

export default WorkFlowSidebar;
