import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import HistogramGraphIcons from "../../../../assets/HistogramGraphIcons.png";
import HistogramLines from "../../../../assets/LineGraphIcons.png";
import "./visualizationContainer.scss";

function VisualizationContainer({
  VisualizationGrouptype,
  visualizationdata,
  selectParticularGraph,
}) {
  return (
    <Box className="visualization-graph-conatiner">
      <Typography className="visualization-graph-header-title">
        Basics
      </Typography>
      <Box className="visualization-graph-data-container">
        {VisualizationGrouptype &&
          VisualizationGrouptype.map((item, idx) => {
            return (
              <Box key={idx} className="visualization-graph-data">
                <Box className="visualization-groutype-title">
                  {Object.values(item)[0]}
                </Box>
                <Box className="visualization-groutype-body">
                  {visualizationdata &&
                    visualizationdata.length > 0 &&
                    visualizationdata[idx].visualization_types.map(
                      (item, index) => {
                        return (
                          <Box
                            key={index}
                            className="visualization-individual-group"
                            onClick={() =>
                              selectParticularGraph(Object.values(item)[0])
                            }
                          >
                            <Box>
                              <img
                                className="histogram-image-style"
                                src={
                                  Object.values(item)[0] === "Histrogram"
                                    ? HistogramGraphIcons
                                    : Object.values(item)[0] === "Lines"
                                    ? HistogramLines
                                    : HistogramGraphIcons
                                }
                                alt="graph icons"
                              />
                            </Box>
                            <Typography className="histogram-graph-tittle">
                              {Object.values(item)[0]}
                            </Typography>
                          </Box>
                        );
                      }
                    )}
                </Box>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
}

export default memo(VisualizationContainer);
