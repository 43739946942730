import { Box, Typography } from "@mui/material";
import React from "react";
import deleteIcon from "../../../../assets/icons/deleteIcon.svg";
import deleteIconBlue from "../../../../assets/icons/deleteIconblue.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IButton from "../../button";
import { Download, IosShare } from "@mui/icons-material";
import "./visualizationHeader.scss";

export default function VisualizationHeader({
  CContainer,
  YContainer,
  XContainer,
  allowDrop,
  dropY,
  dropX,
  dropC,
  removeItemY,
  removeItemX,
  removeItemC,
  handlePopoverOpen,
  onPublish,
}) {
  return (
    <Box className="draw-graph-axis-main">
      <div className="draw-graph-all-axis-container">
        <div className="draw-graph-yaxis-container">
          <Typography className="draw-graph-yaxis-title">Show</Typography>
          <Box className="draw-graph-yaxis-box">
            <Typography className="draw-graph-yaxis-box-title">
              Y {<ArrowDropDownIcon />}{" "}
            </Typography>
            <Box
              className="yaxis-body-conatiner"
              onDrop={(e) => dropY(e)}
              onDragOver={(event) =>
                CContainer.length === 1
                  ? YContainer.length < 1
                    ? allowDrop(event)
                    : null
                  : CContainer.length === 0
                  ? allowDrop(event)
                  : null
              }
            >
              {YContainer &&
                YContainer.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className="yaxis-child"
                      id={`Y${idx}`}
                      style={{ display: "flex" }}
                    >
                      <ArrowDropDownIcon
                        sx={{
                          display: "block",
                          height: "20px",
                          width: "28px",
                          color: "#5e7081",
                          cursor: "pointer",
                        }}
                        onClick={(e) => handlePopoverOpen(e)}
                      />
                      <div className="yaxis-child-item">{item}</div>
                      <div onClick={removeItemY} className="yaxis-child-close">
                        <img src={deleteIconBlue} alt="delete" />
                      </div>
                    </div>
                  );
                })}
            </Box>
          </Box>
        </div>
        <div className="draw-graph-xaxis-container">
          <Box className="draw-graph-xaxis-container-main">
            <Box className="draw-graph-xaxis">
              <Typography className="draw-graph-xaxis-title">By</Typography>
              <Box className="draw-graph-xaxis-box">
                <Typography className="draw-graph-xaxis-box-title">
                  X {<ArrowDropDownIcon />}{" "}
                </Typography>
                <Box
                  onDrop={(e) => dropX(e)}
                  onDragOver={(event) =>
                    XContainer.length === 0 ? allowDrop(event) : null
                  }
                  className="xaxis-body-conatiner"
                >
                  {XContainer &&
                    XContainer.map((item, idx) => {
                      return (
                        <div key={idx} id={`X${idx}`} className="xaxis-child">
                          <ArrowDropDownIcon
                            sx={{
                              display: "block",
                              height: "20px",
                              width: "28px",
                              color: "#5e7081",
                              cursor: "pointer",
                            }}
                            onClick={(e) => handlePopoverOpen(e)}
                          />
                          <div className="xaxis-child-item">{item}</div>
                          <div
                            onClick={removeItemX}
                            className="xaxis-child-close"
                          >
                            <img src={deleteIcon} alt="delete" />
                          </div>
                        </div>
                      );
                    })}
                </Box>
              </Box>
            </Box>
            <Box className="draw-graph-xaxis-color">
              <Typography className="draw-graph-xaxis-color-title">
                And
              </Typography>
              <Box className="draw-graph-xaxis-color-box">
                <Typography className="draw-graph-xaxis-color-box-title">
                  <input type="color" id="primary_color" />
                  <ArrowDropDownIcon />
                </Typography>
                <Box
                  className="xaxis-body-conatiner"
                  onDrop={(e) => dropC(e)}
                  onDragOver={(event) => allowDrop(event)}
                >
                  {XContainer &&
                    CContainer.map((item, idx) => {
                      return (
                        <div key={idx} className="xaxis-child" id={`C${idx}`}>
                          <ArrowDropDownIcon
                            sx={{
                              display: "block",
                              height: "20px",
                              width: "28px",
                              color: "#5E7081",
                              cursor: "pointer",
                            }}
                            onClick={(e) => handlePopoverOpen(e)}
                          />
                          <div className="xaxis-child-item">{item}</div>
                          <div
                            onClick={removeItemC}
                            className="xaxis-child-close"
                          >
                            <img src={deleteIcon} alt="delete" />
                          </div>
                        </div>
                      );
                    })}
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
      <div className="draw-graph-all-axis-btn-container">
        <IButton
          type={"primary"}
          customClass="visual-btns"
          handleClick={onPublish}
        >
          <IosShare className="visual-btns-icon " />
          Publish
        </IButton>
        <IButton type="neutral" customClass="visual-btns">
          <Download className="visual-btns-icon " />
          Download
        </IButton>
      </div>
    </Box>
  );
}
